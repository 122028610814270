import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { LoginOutlined, UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { nav as LANG } from '../../helpers/lan';
import LanMenuItem from './LanMenuItem';

const SMenu = styled(Menu)`
  @media screen and (max-width: 1050px) {
    line-height: 5.9em !important;
  }
  @media screen and (min-width: 1051px) {
    width: 100%;
    display: flex;
    justify-content:flex-end;
  }
`;

const MenuItem = styled(Menu.Item)`
  line-height: 5.9em;
`;

class AdminLoginMenu extends React.Component {
  render() {
    const LAN = LANG[this.props.lan];
    return (
      <SMenu
        mode="horizontal"
        selectedKeys={[this.props.history.location.pathname]}
        overflowedIndicator={<UnorderedListOutlined style={{ fontSize: '16px', paddingLeft: '8px' }} />}
      >
        <MenuItem key="/admin/sign-in">
          <Link to="/admin/sign-in">
            <LoginOutlined />
&ensp;
            {LAN.adminSignIn}
          </Link>
        </MenuItem>
        <MenuItem key="accubrain">
          <Link to="/sign-in">
            <UserOutlined />
&ensp;
            {LAN.accubrain}
          </Link>
        </MenuItem>
        <MenuItem key="lan"><LanMenuItem /></MenuItem>
      </SMenu>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default withRouter(connect(mapStateToProps)(AdminLoginMenu));
