import React from 'react';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import Root from './pages/Root';
import axios from './helpers/axios';
import { getItem, removeItem } from './helpers/storage';
import { actions } from './redux/index';
import { moduleList } from './helpers/modules';

class App extends React.Component {
  async componentDidMount() {
    const lan = localStorage.getItem('lan') || 'zh';
    const token = getItem('act');
    let username = getItem('username');
    let agentname = getItem('agentname');
    let loginType = getItem('loginType');

    if (token === undefined || token === null) {
      loginType = 'logout';
      username = '-';
      agentname = '-';
    }

    if (loginType === 'user') {
      const res = await axios.get('/auth/initial-props');
      if (res?.data?.message !== 'success') {
        removeItem('act');
        removeItem('username');
        removeItem('loginType');        
        window.location = '/sign-in';
      }
      const { status: userStatus, level: userLevel, unreadNews, expiryDate, module, defaultModuleConfig } = res.data;
      const isUnreadNews = unreadNews.length > 0;    
      const moduleGroups = {};

      for (const md of module) {
        if (moduleGroups[moduleList[md].group] === undefined) {
          moduleGroups[moduleList[md].group] = [];
        }
        moduleGroups[moduleList[md].group].push(md);
      }
      this.props.initial({ username, agentname, loginType, modules: moduleGroups, lan, userStatus, userLevel, isUnreadNews, expiryDate, defaultModuleConfig });  
    } else {
      this.props.initial({ username, agentname, loginType, lan });
    }

    // const userStatus = getItem('userStatus');
    // const userLevel = getItem('userLevel');
    // const isUnreadNews = getItem('isUnreadNews');
    // const expiryDate = getItem('expiryDate');
    // const modulesStr = getItem('modules');
    // const defaultModuleConfig = getItem('defaultModuleConfig');
    // let modules = [];

    // if (modulesStr !== undefined && modulesStr !== null) {
    //   modules = JSON.parse(modulesStr);
    // }
    // this.props.initial({ username, agentname, loginType, modules, lan, userStatus, userLevel, isUnreadNews, expiryDate, defaultModuleConfig });
  }

  render() {
    return (
      <ConfigProvider locale={this.props.lan === 'en' ? enUS : zhCN}>
        <Root />
      </ConfigProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

const mapDispatchToProps = (dispatch) => ({
  initial: (obj) => { dispatch(actions.initial(obj)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
