import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tag } from 'antd';

const SDiv = styled.div`
  border: 1px solid #d9d9d9;
  min-height: 8em;
  padding: 4px 11px;
  border-radius: 2px;
`;

class TagInput extends React.Component {
  handleClose = (tag) => {
    const { value } = this.props;
    const newValue = value.filter((t) => t.username !== tag);
    this.props.onChange(newValue);
  }
  render() {
    return (
      <SDiv>
        <span>
          {this.props.value.map((v) => (
            <Tag
              visible
              closable
              key={`tag-key-${v.username}`}
              onClose={() => this.handleClose(v.username)}
            >
              {v.username}
            </Tag>
          ))}
        </span>
      </SDiv>
    );
  }
}


TagInput.defaultProps = {
  value: [],
};

TagInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default TagInput;
