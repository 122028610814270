import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import styled from 'styled-components';
import { footer as LANG } from '../helpers/lan';
import police from '../../static/police.png';
import copyRight from '../../static/copyright.png';

const LayoutFooter = styled(Layout.Footer)`
  text-align: center;
  flex-shrink: 0;
  background-color: rgb(250,250,250);
  border-top: 1px solid lightgray;
  color: #939393;
  line-height: 1.5em;
`;

const SLink = styled.a`
  text-decoration: none;
  color: #939393;
  &:link, &:hover, &:visited {
    text-decoration: none;
  }
  padding: 0 1em;
`;

const icp = '粤ICP备18031322号';
const policeNum = '粤公网安备 44030402003338号';
const mode = process.env.REACT_APP_ENV === 'production' ? '' : `${process.env.REACT_APP_ENV.toUpperCase()} MODE`;
class Footer extends React.Component {
  render() {
    return (
      <LayoutFooter>
        Copyright &copy; BrainNow&ensp;{new Date().getFullYear()}. All rights reserved. V3.10.2 {mode}
        <SLink target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn">
          <img src={copyRight} alt="查看版权信息" />&ensp;{icp}
        </SLink>
        <SLink target="_blank" rel="noopener noreferrer" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402003338">
          <img src={police} alt="查看备案信息" />&ensp;<span className="policeSpan">{policeNum}</span>
        </SLink>
        <br />
        {LANG[this.props.lan].browserTips}     
      </LayoutFooter>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(Footer);
