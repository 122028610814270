const env = process.env.REACT_APP_ENV;

// production
const config = {
  API_DOMAIN: 'https://www.brainnow.net/api',
};

if (env === 'beta') {
  config.API_DOMAIN = 'http://test.brainnow.net/api';
}

if (env === 'wsl') {
  config.API_DOMAIN = 'http://localhost:3000';
}

if (env === 'development') {
  config.API_DOMAIN = 'http://localhost:3000';
}

module.exports = config;
