import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Input, Form } from 'antd';
import axios from '../../helpers/adminAxios';
import showError from '../../helpers/errorlist';

class AddAgentModal extends React.Component {
  state = {
    confirmLoading: false,
  }

  formRef = React.createRef();

  handleSubmit = async (values) => {
    const { lan } = this.props;
    try {
      this.setState({ confirmLoading: true });
      await axios.post('/admin-auth/agents', values);
      const LAN = LANG[lan];
      Modal.success({
        content: LAN.successMsg,
        onOk: () => {
          this.setState({ confirmLoading: false });
          this.props.onClose();
        },
      });
    } catch (error) {
      showError(error, lan);
      this.setState({ confirmLoading: false });
    }
  }

  handlsOk = async () => {
    this.formRef.current.submit();
  }

  handleCancel = async () => {
    this.formRef.current.resetFields();
    this.props.onClose();
  }
  render() {
    const { lan, visible } = this.props;
    const { confirmLoading } = this.state;
    const LAN = LANG[lan];
    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 8 },
        sm: { span: 12 },
      },
    };
    return (
      <Modal
        title={LAN.modalTitle}
        visible={visible}
        onOk={this.handlsOk}
        okText={LAN.submitBtn}
        cancelText={LAN.cancelBtn}
        confirmLoading={confirmLoading}
        onCancel={this.handleCancel}
      >
        <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef} name="add-agent">
          <Form.Item
            label={LAN.agentname}
            name="agentname"
            rules={[
              { required: true, message: LAN.usernameRequiredMsg },
              { min: 4, message: LAN.usernameMinMsg },
              { pattern: /^[\w]+$/, message: LAN.usernameMatchMsg },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={LAN.phone}
            name="phone"
            rules={[
              { required: true, message: LAN.phoneRequiredMsg },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={LAN.email}
            name="email"
            rules={[
              { required: true, message: LAN.emailRequiredMsg },
              { type: 'email', message: LAN.emailMatchMsg },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={LAN.organization}
            name="organization"
            rules={[
              { required: true, message: LAN.organizationRequiredMsg },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

AddAgentModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

AddAgentModal.defaultProps = {
  visible: false,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(AddAgentModal);

const LANG = {
  zh: {
    modalTitle: '增加代理',
    agentname: '代理用户名',
    usernameMinMsg: '用户名长度不得少于4位',
    usernameRequiredMsg: '请输入用户名',
    usernameMatchMsg: '用户名应由字母和数字组成',
    email: '电子邮箱',
    emailRequiredMsg: '请输入电子邮箱',
    emailMatchMsg: '请输入合法电子邮箱地址',
    phone: '电话',
    phoneRequiredMsg: '请输入电话号码',
    organization: '机构',
    organizationRequiredMsg: '请输入机构名称',
    regTime: '注册时间',
    submitBtn: '提交',
    cancelBtn: '取消',
    successMsg: '代理用户添加完成',
  },
  en: {
    modalTitle: 'Add new agent',
    agentname: 'Agentname',
    usernameMinMsg: 'The length of username must not be less than 4 characters',
    usernameRequiredMsg: 'Please input your username',
    usernameMatchMsg: 'Username should consist of letters and numbers',
    email: 'Email',
    emailRequiredMsg: 'Please input your email address',
    emailMatchMsg: 'Please input valid email address',
    phone: 'Tel',
    phoneRequiredMsg: 'Please input your telephone number',
    organization: 'Organization',
    organizationRequiredMsg: 'Please input your organization name',
    regTime: 'Regtime',
    submitBtn: 'Submit',
    cancelBtn: 'Cancel',
    successMsg: 'Add agent successfully!',
  },
};
