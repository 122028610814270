import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Input, Button } from 'antd';
import { LoginOutlined, ReloadOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { pbkdf2Sync } from 'crypto';
import axios from '../helpers/adminAxios';
import showError from '../helpers/errorlist';
import { actions } from '../redux/index';
import { signIn as LANG } from '../helpers/lan';
import { setItem } from '../helpers/storage';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 35em;
  max-width: 100vw;
`;

class SignInCard extends React.Component {
  formRef = React.createRef();

  handleSubmit = async (values) => {
    const { lan, onLogin, history } = this.props;
    try {
      const { username, password } = values;
      const salt = 'brainnowadminyes';
      values.password = pbkdf2Sync(password, salt, 1000, 32, 'sha512').toString('hex');
      const res = await axios.post('/admin/sessions', values);
      const { token } = res.data;

      setItem('act', token);
      setItem('loginType', 'admin');
      setItem('username', username);
      onLogin({
        username,
        loginType: 'admin',
      });
      history.push('/admin/userlist');
    } catch (error) {
      showError(error, lan);
    }
  }

  handleReset = () => {
    this.formRef.current.resetFields();
  }
  render() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { sm: 8 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { sm: 16 },
      },
    };
    const btnItemLayout = {
      wrapperCol: {
        xs: {
          span: 13,
          offset: 8,
        },
      },
    };
    return (
      <SCard title={LAN.adminCardTitle}>
        <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef} name="admin-sign-in">
          <Form.Item
            label={LAN.username}
            name="username"
            rules={[
              {
                required: true,
                message: LAN.usernameMsg,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={LAN.password}
            name="password"
            rules={[
              {
                required: true,
                message: LAN.passwordMsg,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...btnItemLayout}>
            <Button type="primary" icon={<LoginOutlined />} htmlType="submit">{LAN.login}</Button>
            &emsp;
            <Button type="default" icon={<ReloadOutlined />} htmlType="reset" onClick={this.handleReset}>{LAN.reset}</Button>
          </Form.Item>
        </Form>
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (obj) => { dispatch(actions.signIn(obj)); },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInCard));
