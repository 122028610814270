import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { LogoutOutlined, UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { actions } from '../../redux/index';
import { nav as LANG } from '../../helpers/lan';
import { setItem, removeItem } from '../../helpers/storage';
import LanMenuItem from './LanMenuItem';

const SMenu = styled(Menu)`
  @media screen and (max-width: 1050px) {
    line-height: 5.9em !important;
  }
  @media screen and (min-width: 1051px) {
    width: 100%;
    display: flex;
    justify-content:flex-end;
  }
`;

const MenuItem = styled(Menu.Item)`
  line-height: 5.9em;
`;

const DividerItem = styled(MenuItem)`
  margin: 0 !important;
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

class AdminMenu extends React.Component {
  handleLogout = () => {
    setItem('loginType', 'logout');
    removeItem('username');
    removeItem('act');
    this.props.onLogout();
  }
  render() {
    const LAN = LANG[this.props.lan];
    return (
      <SMenu
        mode="horizontal"
        selectedKeys={[this.props.history.location.pathname]}
        overflowedIndicator={<UnorderedListOutlined style={{ fontSize: '16px', paddingLeft: '8px' }} />}
      >
        <MenuItem key="/admin/userlist">
          <Link to="/admin/userlist">{LAN.userlist}</Link>
        </MenuItem>
        <MenuItem key="/admin/agentlist">
          <Link to="/admin/agentlist">{LAN.agentlist}</Link>
        </MenuItem>
        <MenuItem key="/admin/tasklist">
          <Link to="/admin/tasklist">{LAN.tasklist}</Link>
        </MenuItem>
        <MenuItem key="/admin/add-user">
          <Link to="/admin/add-user">{LAN.addUser}</Link>
        </MenuItem>
        <MenuItem key="/admin/send-email">
          <Link to="/admin/send-email">{LAN.sendEmail}</Link>
        </MenuItem>
        <DividerItem key="divider" disabled>I</DividerItem>
        <MenuItem key="/admin/profile">
          <Link to="/admin/profile">
            <UserOutlined />
&ensp;
            <strong>{this.props.username}</strong>
          </Link>
        </MenuItem>
        <MenuItem key="logout">
          <Link to="/admin/sign-in" onClick={this.handleLogout}>
            <LogoutOutlined />
&ensp;
            {LAN.signOut}
          </Link>
        </MenuItem>
        <MenuItem key="lan"><LanMenuItem /></MenuItem>
      </SMenu>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.username,
  lan: state.lan,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: (obj) => { dispatch(actions.signOut(obj)); },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminMenu));
