import React from 'react';
import PropTypes from 'prop-types';
import UploadFormItem from './UploadFormItem';

const AdFormItems = (props) => (
  <UploadFormItem
    labelIndex="fileT22dLabel"
    isRequired={false}
    field="fileT22d"
    filename="2dt2.zip"
    disabled={props.disabled}
  />
); 

AdFormItems.propTypes = {
  disabled: PropTypes.bool,
};

export default AdFormItems;
