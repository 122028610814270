import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
// import OSS from 'ali-oss';
import { upload as LANG } from '../../helpers/lan';
import { ERROR_LIST } from '../../helpers/errorlist';
import axios from '../../helpers/axios';
import { actions } from '../../redux/index';
import { setItem } from '../../helpers/storage';

class ObsUploader extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return { ...(nextProps.value || {}) };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const value = props.value || {};
    this.state = {
      fileList: value.fileList || [],
    };
  }

  checkZipFormat = (file, fileList) => {
    const LAN = LANG[this.props.lan];
    if (!(file.type === 'application/zip' || file.type === 'application/x-zip-compressed')) {
      message.error(LAN.uploadZipFormatError);
      fileList.splice(0, fileList.length);
      return false;
    }
    return true;
  }

  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange({ ...this.state, ...changedValue });
    }
  }

  handleChange = (info) => {
    const LAN = LANG[this.props.lan];
    let fileList = [...info.fileList];
    if (fileList.length > 1) { fileList = fileList.slice(-1); }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} ${LAN.uploadSuccessMsg}`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} ${LAN.uploadFailedMsg}`);
    }
    if (!('value' in this.props)) {
      this.setState({ fileList });
    }
    this.triggerChange({ fileList });
  }

  // handleUploadDicomByAliSdk = async (option) => {
  //   const { username, obsTail, filename, lan, onChangeUploadStatus } = this.props;
  //   onChangeUploadStatus({ fileType: filename, value: true });
  //   option.username = username;

  //   const fileKey = `${obsTail}/${filename}`;
  //   try {
  //     const stsRes = await axios.get('/auth/sts');
  //     if (stsRes?.data?.isSuccess) {
  //       const {
  //         AccessKeyId: accessKey,
  //         AccessKeySecret: secretKey,
  //         SecurityToken: securityToken,
  //       } = stsRes.data.data;
  //       const client = new OSS({
  //         region: 'oss-cn-hangzhou',
  //         accessKeyId: accessKey,
  //         accessKeySecret: secretKey,
  //         stsToken: securityToken,
  //         bucket: 'accubrain-dev',
  //       });
  //       // const headers = {
  //       //   // 指定该Object被下载时的网页缓存行为。
  //       //   'Cache-Control': 'no-cache',
  //       //   // 指定该Object被下载时的名称。
  //       //   'Content-Disposition': 'example.txt',
  //       //   // 指定该Object被下载时的内容编码格式。
  //       //   'Content-Encoding': 'utf-8',
  //       //   // 指定过期时间，单位为毫秒。
  //       //   Expires: '1000',
  //       //   // 指定Object的存储类型。
  //       //   'x-oss-storage-class': 'Standard',
  //       //   // 指定Object标签，可同时设置多个标签。
  //       //   'x-oss-tagging': 'Tag1=1&Tag2=2',
  //       //   // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
  //       //   'x-oss-forbid-overwrite': 'true',
  //       // };
  //       const uploadOptions = {
  //         // 获取分片上传进度、断点和返回值。
  //         progress: (p, cpt, res) => {
  //           console.log(p * 100);
  //           option.onProgress({ percent: p * 100 });
  //         },
  //         // 设置并发上传的分片数量。
  //         parallel: 4,
  //         // 设置分片大小。默认值为1 MB，最小值为100 KB。
  //         partSize: 1024 * 1024 * 10,
  //         // headers,
  //         mime: 'text/plain',
  //       };
  //       const res = await client.multipartUpload(fileKey, option.file, {
  //         ...uploadOptions,
  //       });
  //       if (res?.res?.status === 200) {
  //         option.onSuccess(option.file);
  //         setItem('obsTail', obsTail);
  //         onChangeUploadStatus({ fileType: filename, value: false });
  //       }
  //     }
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.error(`Error-->${error}`);
  //     option.onError(error.message);
  //     onChangeUploadStatus({ fileType: filename, value: false });
  //   }
  // }

  handleUploadDicom = async (option) => {
    const { username, obsTail, filename, lan, onChangeUploadStatus } = this.props;
    onChangeUploadStatus({ fileType: filename, value: true });
    option.username = username;
    const fileKey = `${obsTail}/${filename}`;

    try {
      // create multipart upload and get uploadId----------------------
      const createResultTp = await axios.get(`/auth/create-multipart-upload-params?fileKey=${fileKey}`);
      if (createResultTp.status !== 200) {
        const ww = await createResultTp.text();
        option.onError(ERROR_LIST[ww] ? ERROR_LIST[ww][lan] : ERROR_LIST[0][lan]);
        return;
      }
      const { headers: createHeaders, url: createUrl } = createResultTp?.data?.data;

      const initialResult = await fetch(createUrl, {
        method: 'post',
        headers: { ...createHeaders, Date: new Date().toUTCString() },
      }).then((res) => res.text());

      const uploadId = getXmlValue(initialResult, 'UploadId');

      // upload part-----------------------
      const partSize = 1024 * 1024 * 10; // 10MB
      const partAmount = Math.ceil(option.file.size / partSize);
      const requests = [];
      const promises = [];

      // get urls
      for (let i = 1; i <= partAmount; i += 1) {
        requests.push(axios.get(`/auth/upload-part-params?fileKey=${fileKey}&uploadId=${uploadId}&partNumber=${i}`)); 
      }
      const uploadPartRequestResults = (await Promise.all(requests)).map((req) => req?.data?.data);

      // get promises
      let processNumber = 0;
      for (let i = 0; i < partAmount; i += 1) {
        const content = option.file.slice(i * partSize, i * partSize + parseInt(partSize, 10), option.file.type);
        promises.push(fetch(uploadPartRequestResults[i].url, {
          method: 'put',
          headers: uploadPartRequestResults[i].headers,
          withCredentials: false,
          body: content,
        }).then(
          // eslint-disable-next-line
          res => {
            processNumber += 100 / partAmount;
            option.onProgress({ percent: processNumber });
            return Promise.resolve(res.headers.get('ETag'));
          },
        ));
      }
      const etags = await Promise.all(promises);

      // merge-----------------------------------
      const mergeUrlResult = (await axios.get(`/auth/complete-multipart-upload-params?fileKey=${fileKey}&uploadId=${uploadId}`)).data;
      // console.log(mergeUrlResult);
      let etagsContent = '<CompleteMultipartUpload>';
      for (let i = 0; i < partAmount; i += 1) {
        etagsContent += `<Part><PartNumber>${i + 1}</PartNumber><ETag>${etags[i]}</ETag></Part>`;
      }
      etagsContent += '</CompleteMultipartUpload>';
      await fetch(mergeUrlResult.data.url, {
        method: 'post',
        headers: mergeUrlResult.data.headers || {},
        withCredentials: false,
        body: etagsContent,
      }).then((res) => res.text());
      option.onSuccess(option.file);
      setItem('obsTail', obsTail);
      onChangeUploadStatus({ fileType: filename, value: false });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error-->${error}`);
      option.onError(error.message);
      onChangeUploadStatus({ fileType: filename, value: false });
    }
  }

  render() {
    const uploadProps = {
      onChange: this.handleChange,
      accept: '.zip',
      beforeUpload: this.checkZipFormat,
      customRequest: this.handleUploadDicom,
    };
    const LAN = LANG[this.props.lan];
    return (
      <Upload {...uploadProps} fileList={this.state.fileList}>
        <Button icon={<UploadOutlined />} disabled={this.props.disabled}>{LAN.uploadBtn}</Button>
      </Upload>
    );
  }
}

ObsUploader.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  obsTail: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChangeUploadStatus: PropTypes.func.isRequired,
};

ObsUploader.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
  username: state.username,
  obsTail: state.obsTail,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeUploadStatus: (obj) => {
    dispatch(actions.changeUploadStatus(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ObsUploader);

function getXmlValue(xmlText, label) {
  const index1 = xmlText.indexOf(`<${label}>`);
  if (index1 < 0) { return null; }
  const index2 = xmlText.indexOf(`</${label}>`, index1);
  return xmlText.substr(index1 + label.length + 2, index2 - index1 - label.length - 2);
}
