import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from 'antd';
import logo from '../../../static/logo.svg';

const SNavBar = styled(Layout.Header)`
  background-color: white;
  height: 6em;
  border-bottom: 1px solid #ebebee;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;
`;

const LogoArea = styled.div`
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
  @media screen and (min-width: 1051px) {
    width: 200px;
  }
`;

const SImg = styled.img`
  height: 4em;
`;

const NavBar = (props) => (
  <SNavBar>
    <LogoArea>
      <Link to="/"><SImg src={logo} alt="BrainNow" /></Link>
    </LogoArea>
    {props.children}
  </SNavBar>
);

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(NavBar);
