import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Radio, Form } from 'antd';
import UploadFormItem from './UploadFormItem';
import { upload as LANG } from '../../helpers/lan';
import MslSelect from './MslSelect';

const FormItem = Form.Item;

const NewUploadForms = () => (
  <>
    <UploadFormItem
      labelIndex="fileT1w_bLabel"
      isRequired
      field="fileT1w_b"
      filename="t1w_b.zip"
      key="t1wb"
    />
    <UploadFormItem
      labelIndex="fileT23d_bLabel"
      isRequired
      field="fileT23d_b"
      filename="3dt2_b.zip"
      key="t23db"
    />
  </>
);

class MslFormItems extends React.Component {
  state = {
    hasBaselineData: '1',
  }

  handleRadioChange = (event) => {
    this.setState({
      hasBaselineData: event.target.value,
    });
  }
  render() {
    const LAN = LANG[this.props.lan];
    const { module } = this.props;
    const BaseDataArea = this.state.hasBaselineData === '0' ? <NewUploadForms /> : <MslSelect module={module} />;
    return (
      <>
        <UploadFormItem
          labelIndex="fileT23dLabel"
          isRequired
          field="fileT23d"
          filename="3dt2.zip"
          key="t23d"
          disabled={this.props.disabled}
        />
        <FormItem
          label={LAN.msRadioLabel}
          name="hasBaselineData"
          key="msr"
        >
          <Radio.Group buttonStyle="solid" onChange={this.handleRadioChange}>
            <Radio.Button value="1" key="msr1">{LAN.msRadioYesOption}</Radio.Button>
            <Radio.Button value="0" key="msr0">{LAN.msRadioNoOption}</Radio.Button>
          </Radio.Group>
        </FormItem>
        {BaseDataArea}
      </>
    );
  }
}

MslFormItems.propTypes = {
  disabled: PropTypes.bool,
  module: PropTypes.number,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(MslFormItems);
