import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HomeContent from '../components/Home/HomeContent';

class Home extends React.Component {
  render() {
    const { loginType } = this.props;
    switch (loginType) {
      case 'user': return <Redirect to="/task" />;
      case 'admin': return <Redirect to="/admin/userlist" />;
      default: return <HomeContent />;
    }
  }
}

const mapStateToProps = (state) => ({
  loginType: state.loginType,
});

export default connect(mapStateToProps)(Home);
