import React from 'react';
import { connect } from 'react-redux';
import { Card, Table, Badge, Tooltip, Input } from 'antd';
import styled from 'styled-components';
import axios from '../helpers/axios';
import showError, { ERROR_LIST } from '../helpers/errorlist';
import { task as LANG } from '../helpers/lan';
import { moduleList, categoryList } from '../helpers/modules';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 120em;
  /* min-width: 105em; */
  max-width: 100vw;
`;

const { Search } = Input;

class TaskCard extends React.Component {
  state = {
    taskList: [],
  }

  componentDidMount() {
    this.loadList();
    if (this.timer) clearInterval(this.timer);
    this.loadList();
    this.timer = setInterval(() => {
      this.loadList();
    }, 10000);
    this.retryCount = 3;
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  loadList = async () => {
    let api = '/auth/tasks';
    const { searchValue } = this;
    if (searchValue) {
      api = `${api}?searchValue=${searchValue}`;
    }
    try {
      const res = await axios.get(api);
      const { taskList } = res.data;
      this.setState({ taskList });
      this.retryCount = 3;
    } catch (error) {
      this.retryCount -= 1;
      if (this.timer && this.retryCount === 0) {
        showError(new Error('10010'), this.props.lan);
        clearInterval(this.timer);
      }
    }
  }

  handleSearch = (value) => {
    this.searchValue = value;
    this.loadList();
  }

  showShortName = (value) => {
    if (!value) return;
    const pureName = value.substr(0, value.length - 4);
    if (pureName.length > 29) {
      return <Tooltip title={pureName}>{`${pureName.substr(0, 25)}...`}</Tooltip>;
    }
    return pureName;
  }

  render() {
    const { lan, modules } = this.props;
    const LAN = LANG[lan];
    const columns = [
      {
        title: LAN.numberTitle,
        dataIndex: 'number',
        key: 'number',
        width: 150,
        sorter: (a, b) => (a.number >= b.number ? 1 : -1),
        // sortOrder: sortedInfo.columnKey === 'Number' && sortedInfo.order,
      }, {
        title: LAN.categoryTitle,
        dataIndex: 'category',
        key: 'category',
        filters: Object.keys(categoryList).map((key) => ({
          text: categoryList[key][lan],
          value: key,
        })),
        onFilter: (value, record) => record.category.toString() === value,
        render: (value) => categoryList[value][lan],
      }, {
        title: LAN.moduleTitle,
        dataIndex: 'module',
        key: 'module',
        width: 200,
        filters: Object.keys(moduleList).map((key) => ({
          text: moduleList[key][lan],
          value: key,
        })),
        onFilter: (value, record) => record.module.toString() === value,
        render: (value) => moduleList[value][lan],
      }, {
        title: LAN.statusTitle,
        dataIndex: 'status',
        key: 'status',
        width: 130,
        filters: [
          {
            text: LAN.waitingStatus,
            value: '0',
          }, {
            text: LAN.runningStatus,
            value: '1',
          }, {
            text: LAN.successStatus,
            value: '2',
          }, {
            text: LAN.failedStatus,
            value: '3',
          }, {
            text: LAN.expiredStatus,
            value: '4',
          }, {
            text: LAN.deletedStatus,
            value: '5',
          }, {
            text: LAN.previewingStatus,
            value: '6',
          }, {
            text: LAN.modifyingStatus,
            value: '7',
          }, {
            text: LAN.previewedStatus,
            value: '8',
          },
        ],
        onFilter: (value, record) => record.status.toString() === value,
        render: (value) => {
          switch (value) {
            case 0: return <Badge status="warning" text={LAN.waitingStatus} />;
            case 1: return <Badge status="processing" text={LAN.runningStatus} />;
            case 2: return <Badge status="success" text={LAN.successStatus} />;
            case 3: return <Badge status="error" text={LAN.failedStatus} />;
            case 4: return <Badge color="orange" text={LAN.expiredStatus} />;
            case 5: return <Badge color="magenta" text={LAN.deletedStatus} />;
            case 6: return <Badge color="cyan" text={LAN.previewingStatus} />;
            case 7: return <Badge color="geekblue" text={LAN.modifyingStatus} />;
            case 8: return <Badge color="steelblue" text={LAN.previewedStatus} />;
            default: return '';
          }
        },
      }, {
        title: LAN.attributeTitle,
        dataIndex: 'isResearchData',
        key: 'isResearchData',
        filters: [{
          text: LAN.researchDataText,
          value: true,
        }],
        onFilter: (value, record) => record.isResearchData === value,
        render: (value) => value && <Badge color="skyblue" text={LAN.researchDataText} />,
      }, {
        title: LAN.commentTitle,
        dataIndex: 'comment',
        key: 'comment',
        ellipsis: true,
      }, {
        title: LAN.reportVersion,
        dataIndex: 'reportVersion',
        key: 'reportVersion',
        sorter: (a, b) => (a.reportVersion >= b.reportVersion ? 1 : -1),
        ellipsis: true,      
      }, {
        title: LAN.filename,
        dataIndex: 'filename',
        key: 'filename',
        render: (value) => this.showShortName(value),
        ellipsis: true,
      }, {
        title: LAN.startTimeTitle,
        dataIndex: 'startTime',
        key: 'startTime',
        sorter: (a, b) => (a.startTime > b.startTime ? 1 : -1),
        // sortOrder: sortedInfo.columnKey === 'StartTime' && sortedInfo.order,
      }, {
        title: LAN.errorMsg,
        dataIndex: 'errorCode',
        key: 'errorCode',
        render: (value) => (ERROR_LIST[value] ? ERROR_LIST[value][this.props.lan] : ''),
        ellipsis: true,
      }];
    
      // 为部分模块优化
    const categories = Object.keys(modules || {});
    if (categories.length === 1 && categories[0] === '600') {
      columns.splice(4, 3);
    } else if (categories.every((v) => v !== '200')) {
      columns.splice(4, 1);
    }

    // 一龄特殊需求
    if (modules && modules[600] && modules[600] && modules[600].includes(606)) {
      columns.splice(4, 0, {
        title: LAN.pNameTitle,
        dataIndex: 'patientName',
        key: 'patientName',
      });
    }

    return (
      <SCard
        title={LAN.cardTitle}
        extra={(
          <Search
            placeholder={LAN.taskSearchPlaceholder}
            onSearch={this.handleSearch}
            enterButton
            allowClear
          />
        )}
      >
        <Table
          columns={columns}
          dataSource={this.state.taskList}
          pagination={{ pageSize: 20 }}
          scroll={{ x: 1050 }}
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
  modules: state.modules,
});

export default connect(mapStateToProps)(TaskCard);
