/* eslint-disable import/prefer-default-export */
export const nav = {
  zh: {
    home: '首页',
    aboutUs: '关于博脑',
    product: 'AccuBrain介绍',
    contactUs: '联系我们',
    upload: '上传数据',
    task: '任务进度',
    report: '查看报告',
    agreement: '用户协议',
    news: '最新动态',
    profile: '编辑个人信息',
    addUser: '添加新用户',
    sendEmail: '群发邮件',
    signOut: '注销',
    signIn: '登录',
    language: 'ENG',
    loginError: '请先登录',
    cfdaPage: '临床版主页',
    shellPage: '科研版主页',
    userlist: '用户列表',
    agentlist: '代理列表',
    tasklist: '任务列表',
    adminSignIn: '管理员登录',
    accubrain: 'AccuBrain用户登录',
  },
  en: {
    home: 'HOME',
    aboutUs: 'ABOUT BRAINNOW',
    product: 'ABOUT ACCUBRAIN',
    contactUs: 'CONTACT US',
    upload: 'UPLOAD',
    task: 'TASK',
    report: 'REPORT',
    agreement: 'AGREEMENT',
    news: 'NEWS',
    profile: 'PROFILE',
    addUser: 'ADD USER',
    sendEmail: 'SEND EMAIL',
    signOut: 'SIGN OUT',
    signIn: 'SIGN IN',
    language: '简中',
    loginError: 'Please sign in first!',
    cfdaPage: 'CLINICAL PAGE',
    shellPage: 'RESEARCH PAGE',
    userlist: 'USERLIST',
    agentlist: 'AGENTLIST',
    tasklist: 'TASKLIST',
    adminSignIn: 'ADMIN SIGN IN',
    accubrain: 'ACCUBRAIN SIGN IN',
  },
};

export const signIn = {
  zh: {
    username: '用户名',
    password: '密码',
    usernameMsg: '请输入用户名',
    passwordMsg: '请输入密码!',
    login: '登录',
    reset: '重置',
    loginTitle: '登录',
    signUpLink: '注册新用户',
    adminCardTitle: '管理员登录',
    forgotLink: '忘记密码',
    forgotModalTitle: '忘记密码?',
    forgotModalLabel: '请输入注册用户的电子邮箱地址,我们将发送一封邮件帮助您重置密码',
    submitBtn: '提交',
    successMsg: '请求成功',
    resetPwdCardTitle: '重置密码',
    newPasswordLabel: '新密码',
    newPasswordRequiredMsg: '请输入新密码',
    newPasswordMinMsg: '密码长度不得少于6位',
    passwordMatchMsg: '密码必须包含字母和数字',
    checkPasswordLabel: '重复新密码',
    checkPasswordRequiredMsg: '请再次输入新密码',
    checkPasswordValidatorMsg: '两次输入的密码不一致',
    ie10Warning: '尊敬的用户，您正在使用老版本IE浏览器访问本站点，本站采用基于现代浏览器的前端技术开发，不支持IE10及以下版本浏览器，建议使用Chrome、Firefox，Edge等现代浏览器访问本站点，IE11浏览器可能会遇到一些排版异常，敬请谅解！',
    ie11Warning: '尊敬的用户，您正在使用IE11浏览器，可能会遇到一些排版异常，建议使用Chrome、Firefox，Edge等现代浏览器访问本站点',
    agreementModalTitle: '欢迎使用博脑',
    agreementModalContent: '尊敬的博脑用户，使用Brainnow AccuBrain服务之前，请先阅读并同意用户协议。点击OK按钮访问',
  },
  en: {
    username: 'Username',
    password: 'Password',
    usernameMsg: 'Please input username',
    passwordMsg: 'Please input password!',
    login: 'Sign In',
    reset: 'Reset',
    loginTitle: 'Sign In',
    signUpLink: 'Sign Up',
    adminCardTitle: 'Admin Sign In',
    forgotLink: 'Forgot Password',
    forgotModalTitle: 'Forgot your password?',
    forgotModalLabel: 'Please input the email of your account. We will send you an email to help you reset your password.',
    submitBtn: 'Submit',
    successMsg: 'Success',
    resetPwdCardTitle: 'Reset Password',
    newPasswordLabel: 'New Password',
    newPasswordRequiredMsg: 'Please input new password',
    newPasswordMinMsg: 'The length of password must not be less than 6 characters',
    passwordMatchMsg: 'Password must consist of letters and numbers',
    checkPasswordLabel: 'Confirm New Password',
    checkPasswordRequiredMsg: 'Please input new password again',
    checkPasswordValidatorMsg: 'The password is not the same one as you entered last time!',
    ie10Warning: 'Dear user. You are using old version IE browser which not support modern web technology we use to design our website. We recommend you to visit our website with modern browsers like Chrome, Firefox and Edge. IE11 browser might also cause some UI issues.',
    ie11Warning: 'Dear user. You are using IE11 browser which might cause some UI issues. We recommend you to visit our website with modern browsers like Chrome, Firefox and Edge.',
    agreementModalTitle: 'Welcome',
    agreementModalContent: 'Dear user, before using Brainnow AccuBrain services, please read and agree the user agreement. Click OK button to visit',
  },
};

export const addUser = {
  zh: {
    cardTitle: '添加新用户',
    normalTab: '常规用户',
    cfdaTab: 'CFDA用户',
    username: '用户名',
    usernameMinMsg: '用户名长度不得少于4位',
    usernameRequiredMsg: '请输入用户名',
    usernameMatchMsg: '用户名应由字母和数字组成',
    email: '电子邮箱',
    emailRequiredMsg: '请输入电子邮箱',
    emailMatchMsg: '请输入合法电子邮箱地址',
    phone: '电话',
    phoneMatchMsg: '电话号码应包含不少于7位数字',
    organization: '机构',
    organizationRequiredMsg: '请输入机构名称',
    level: '用户级别',
    expiryDate: '过期时间',
    maxTasks: '任务上限',
    module: '开通功能模块',
    modulePlaceholder: '点击选择功能模块',
    moduleRequiredMsg: '请选择功能模块',
    agent: '代理',
    region: '用户所在区域',
    southchina: '华南及港澳地区',
    northchina: '中国大陆其他地区',
    overseas: '中国台湾以及海外地区',
    dev: '开发区',
    beta: '测试区',
    signUpBtn: '注册',
    resetBtn: '重置',
    successMsg: '用户添加完成',
  },
  en: {
    cardTitle: 'Add User',
    normalTab: 'Normal User',
    cfdaTab: 'CFDA User',
    username: 'Username',
    usernameMinMsg: 'The length of username must not be less than 4 characters',
    usernameRequiredMsg: 'Please input your username',
    usernameMatchMsg: 'Username should consist of letters and figures',
    email: 'Email',
    emailRequiredMsg: 'Please input your email address',
    emailMatchMsg: 'Please input valid email address',
    phone: 'Tel',
    phoneMatchMsg: 'Tel should consist of 7 or more figures',
    organization: 'Organization',
    organizationRequiredMsg: 'Please input your organization name',
    level: 'User Level',
    expiryDate: 'Expiry Date',
    maxTasks: 'Max Tasks',
    module: 'Enabled Modules',
    modulePlaceholder: 'Click to select enabled modules',
    moduleRequiredMsg: 'Please select enabled modules',
    agent: 'Agent',
    region: 'Region',
    southchina: 'South China, HKG and MO',
    northchina: 'Other Area of China Mainland',
    overseas: 'Chinese Taiwan and Overseas Area',
    dev: 'dev',
    beta: 'beta',
    signUpBtn: 'Sign Up',
    resetBtn: 'Reset',
    successMsg: 'Add user successfully!',
  },
};

export const agreement = {
  zh: {
    cardTitle: '用户协议',
    agreeBtn: '我理解并同意此协议',
    agreedBtn: '已同意',
  },
  en: {
    cardTitle: 'User Agreement',
    agreeBtn: 'I understand and agree',
    agreedBtn: 'Agreed',
  },
};

export const profile = {
  zh: {
    cardTitle: '编辑个人信息',
    basicTab: '编辑基本信息',
    changePasswordTab: '修改密码',
    usernameLabel: '用户名',
    emailLabel: '电子邮箱',
    emailRequiredMsg: '请输入电子邮箱',
    emailMatchMsg: '请输入合法电子邮箱地址',
    organizationLabel: '机构',
    organizationRequiredMsg: '请输入机构名称',
    phoneLabel: '电话',
    phoneRequiredMsg: '请输入电话',
    subscribeLabel: '任务状态邮件提醒',
    submitBtn: '提交修改',
    resetBtn: '重置',
    currentPasswordLabel: '当前密码',
    currentPasswordRequiredMsg: '请输入当前密码',
    newPasswordLabel: '新密码',
    newPasswordRequiredMsg: '请输入新密码',
    newPasswordMinMsg: '密码长度不得少于6位',
    passwordMatchMsg: '密码必须包含字母和数字',
    checkPasswordLabel: '重复新密码',
    checkPasswordRequiredMsg: '请再次输入新密码',
    checkPasswordValidatorMsg: '两次输入的密码不一致',
    successMsg: '更新成功',
  },
  en: {
    cardTitle: 'Profile',
    basicTab: 'Edit Basic Info',
    changePasswordTab: 'Change Password',
    usernameLabel: 'Username',
    emailLabel: 'Email',
    emailRequiredMsg: 'Please input your email address',
    emailMatchMsg: 'Please input valid email address',
    organizationLabel: 'Organization',
    organizationRequiredMsg: 'Please input your organization name',
    phoneLabel: 'Tel',
    phoneRequiredMsg: 'Please input your telephone number',
    subscribeLabel: 'Task Status Email Notice',
    submitBtn: 'Submit',
    resetBtn: 'Reset',
    currentPasswordLabel: 'Current Password',
    currentPasswordRequiredMsg: 'Please input current password',
    newPasswordLabel: 'New Password',
    newPasswordRequiredMsg: 'Please input new password',
    newPasswordMinMsg: 'The length of password must not be less than 6 characters',
    passwordMatchMsg: 'Password must consist of letters and numbers',
    checkPasswordLabel: 'Confirm New Password',
    checkPasswordRequiredMsg: 'Please input new password again',
    checkPasswordValidatorMsg: 'The password is not the same one as you entered last time!',
    successMsg: 'Update Successfully',
  },
};

export const upload = {
  zh: {
    cardTitle: '上传数据',
    cardBatchTitle: '批量上传数据',
    submitBtn: '提交任务',
    remainderLabel: '剩余可用次数',
    expiryDateLabel: '账户过期时间',
    fileT1WLabel: '上传T1W文件',
    fileBatchLabel: '上传批量压缩文件',
    fileRequiredMsg: '请先上传文件',
    uploadBtn: '点击上传文件（.zip格式)',
    uploadZipFormatError: '请上传zip格式文件',
    uploadSuccessMsg: '上传成功',
    uploadFailedMsg: '上传失败',
    successMsg: '任务提交成功',
    uploadingMsg: '文件上传中，请稍后再试',
    moduleSelect: '功能模块',
    hasClinicalLabel: '生成临床版报告',
    reportVersionLabel: '生成报告版本',
    AboutVersion: '关于版本...',
    AboutVersionContent: '本网站现提供AccuBrain IV2.0 和 IV3.0升级版本供您使用。相比AccuBrain IV1.2，AccuBrain IV2.0 和 IV3.0采用了更新的算法，单个实例分析时间可缩短50%，分割结果准确度和疾病分类准确度也都进一步提升。您可以根据需求，自行选择AccuBrain IV1.2或AccuBrain IV2.0或AccuBrain IV3.0进行数据分析。同一个体的纵向研究或同一个研究项目的横向研究请保持同一版本。AccuBrain IV3.0版本是AccuBrain IV2.0报告样式升级，算法与AccuBrain IV2.0版本保持一致',
    isResearchDataLabel: '科研数据',
    aboutResearchData: '关于科研数据...',
    aboutResearchDataContent: '如选择为科研数据，后台数据将保留不删除',
    commentLabel: '医师备注',
    fileCbfLabel: '上传CBF文件',
    fileT22dLabel: '上传T2-FLAIR (Axial)文件',
    fileT23dLabel: '上传3D T2 FLAIR文件',
    fileT1w_bLabel: '上传上次扫描T1W文件',
    fileT23d_bLabel: '上传上次扫描3D T2 FLAIR文件',
    fileDwiLabel: '上传DWI文件',
    fileSwiLabel: '上传SWI文件',
    fileDataFLabel: '上传DICOM文件',
    msRadioLabel: '纵向研究对比数据来源',
    msRadioYesOption: '历史任务数据',
    msRadioNoOption: '上传数据',
    mslSearchLabel: '搜索历史任务',
    mslExistingPlaceholder: '输入任务编号或病人姓名',
    mslRequiredMsg: '请先选择一个历史任务',
    agreementMsg: '请先同意用户协议后再使用上传服务',
    expireMsg: '账户试用已到期，上传服务无法使用，请联系管理员续期',
  },
  en: {
    cardTitle: 'Upload',
    cardBatchTitle: 'Batch',
    submitBtn: 'Submit',
    remainderLabel: 'Remaining Balance',
    expiryDateLabel: 'Account Expiry Date',
    fileT1WLabel: 'Upload T1W File',
    fileBatchLabel: 'Upload batch File',
    fileRequiredMsg: 'Please upload file first',
    uploadBtn: 'Click to upload file (.zip format)',
    uploadZipFormatError: 'Please upload zip format file',
    uploadSuccessMsg: 'Upload successfully',
    uploadFailedMsg: 'Upload failed',
    successMsg: 'Submit Successfully',
    uploadingMsg: 'A file is uploading. Please try later.',
    moduleSelect: 'Module',
    hasClinicalLabel: 'Create clinical reports',
    reportVersionLabel: 'Report Version',
    AboutVersion: 'About Version...',
    AboutVersionContent: 'Upgraded versions of AccuBrain IV2.0 and IV3.0 are now available for your use on our website. Compared to AccuBrain IV1.2, AccuBrain IV2.0 and IV3.0 use an updated algorithm that reduces the analysis time for one subject by around 50% and further improves the segmentation results and disease diagnosis accuracy.You can choose AccuBrain IV1.2, AccuBrain IV2.0 or AccuBrain IV3.0 depending on your needs. Please stay the same version for longitudinal study of the same subject or within the cross-sessional study. IV 3.0 is an upgrade of the report style, and the algorithm is consistent with IV 2.0',
    isResearchDataLabel: 'Research Data',
    aboutResearchData: 'About Research Data...',
    aboutResearchDataContent: 'If checked, the data you upload and the result files produced in Accubrain processing will NOT be removed.',
    commentLabel: 'Comment',
    fileCbfLabel: 'Upload CBF File',
    fileT22dLabel: 'Upload T2 FLAIR (Axial) File',
    fileT23dLabel: 'Upload 3D T2 FLAIR File',
    fileT1w_bLabel: 'Upload T1W File (Baseline Scan)',
    fileT23d_bLabel: 'Upload 3D T2 FLAIR File (Baseline Scan)',
    fileDwiLabel: 'Upload DWI File',
    fileSwiLabel: 'Upload SWI File',
    fileDataFLabel: 'Upload DICOM File',
    msRadioLabel: 'Baseline Scan',
    msRadioYesOption: 'Yes',
    msRadioNoOption: 'No',
    mslSearchLabel: 'Search Existing Task',
    mslExistingPlaceholder: 'Input task number or patient name',
    mslRequiredMsg: 'Please select an existing task',
    agreementMsg: 'Please read and agree the user agreement before using our services',
    expireMsg: 'This trial account has expired. The upload service is out of use. Please contact the administrator to renew this account.',
  },
};

export const task = {
  zh: {
    cardTitle: '任务列表',
    reportCardTitle: '报告列表',
    numberTitle: '任务编号',
    categoryTitle: '分类',
    moduleTitle: '模块',
    typeTitle: '任务类型',
    typeValueText: ['单任务', '批处理', '批处理初始化'],
    statusTitle: '任务状态',
    waitingStatus: '等待中',
    runningStatus: '运行中',
    successStatus: '完成',
    failedStatus: '失败',
    expiredStatus: '超时',
    deletedStatus: '已删除',
    previewingStatus: '报告预览',
    modifyingStatus: '报告修订中',
    previewedStatus: '预览完毕',
    attributeTitle: '任务属性',
    researchDataText: '科研数据',
    commentTitle: '医师备注',
    creationTimeTitle: '任务生成时间',
    startTimeTitle: '任务开始时间',
    endTimeTitle: '完成时间',
    reportTimeTitle: '报告生成时间',
    taskSearchPlaceholder: '编号/文件名搜索',
    reportSearchPlaceholder: '编号/病人ID/姓名搜索',
    pIdTitle: '病人ID',
    pNameTitle: '病人姓名',
    pAgeTitle: '年龄',
    pGenderTitle: '性别',
    reportNameTitle: '报告文件名',
    scanTimeTitle: '扫描时间',
    reportLink: '下载报告',
    remoteLink: '查看结果',
    actionTitle: '操作',
    previewLink: '预览报告',
    modifyingLink: '报告修订中',
    previewedLink: '审核报告',
    editLink: '重新编辑',
    deleteBtn: '删除记录',
    deleteContent: '确定要删除记录',
    successMsg: '删除成功',
    failedMsg: '删除失败',
    filename: '文件名',
    reportVersion: '报告版本',
    errorMsg: '错误信息',
  },
  en: {
    cardTitle: 'Task',
    reportCardTitle: 'Report',
    numberTitle: 'Task Number',
    categoryTitle: 'Category',
    moduleTitle: 'Module',
    typeTitle: 'Type',
    typeValueText: ['Single', 'Batch', 'PreBatch'],
    statusTitle: 'Status',
    waitingStatus: 'Waiting',
    runningStatus: 'Running',
    successStatus: 'Success',
    failedStatus: 'Fail',
    expiredStatus: 'Expired',
    deletedStatus: 'Deleted',
    previewingStatus: 'Previewing',
    modifyingStatus: 'Modifying',
    previewedStatus: 'Previewed',
    attributeTitle: 'Attribute',
    researchDataText: 'Research Data',
    commentTitle: 'Comment',
    creationTimeTitle: 'Creation Time',
    startTimeTitle: 'Start Time',
    endTimeTitle: 'End Time',
    reportTimeTitle: 'Report Creation Time',
    taskSearchPlaceholder: 'Task number/Filename',
    reportSearchPlaceholder: 'Task number/ID/Name',
    pIdTitle: 'Patient ID',
    pNameTitle: 'Patient Name',
    pAgeTitle: 'Age',
    pGenderTitle: 'Gender',
    reportNameTitle: 'Report Name',
    scanTimeTitle: 'Scan Time',
    reportLink: 'Download Report',
    remoteLink: 'View',
    actionTitle: 'Action',
    previewLink: 'Preview',
    modifyingLink: 'Modifying',
    previewedLink: 'Verify',
    editLink: 'Edit',
    deleteBtn: 'Delete Case',
    deleteContent: 'Are you sure to delete case',
    successMsg: 'Success',
    failedMsg: 'Fail',
    filename: 'Filename',
    reportVersion: 'Report Version',
    errorMsg: 'Error Message',
  },
};

export const admin = {
  zh: {
    cardTitle: '用户列表',
    username: '用户名',
    status: '用户状态',
    status_0: '失效',
    status_1: '正常',
    status_2: '未认证',
    level: '用户级别',
    phone: '电话',
    email: '电子邮箱',
    organization: '机构',
    agent: '代理',
    region: '用户所在区域',
    southchina: '华南及港澳地区',
    northchina: '中国大陆其他地区',
    overseas: '中国台湾以及海外地区',
    dev: '开发区',
    beta: '测试区',
    expiryDate: '过期时间',
    regTime: '注册时间',
    recentTime: '最近活动时间',
    taskAmt: '完成任务数',
    quota: '任务上限',
    module: '功能模块',
    action: '操作',
    detailLinkTitle: '点击查看用户详情',
    editLinkTitle: '点击编辑用户',
    certificateLinkTitle: '认证CFDA用户',
    personalTitle: '用户信息',
    taskTitle: '任务信息',
    resTotal: '已完成总任务数',
    res7: '近7天',
    res30: '近30天',
    res365: '近一年',
    submitBtn: '提交修改',
    resetBtn: '重置',
    successMsg: '更新成功',
    editFormTitle: '设置用户任务配额',
    editLevelTitle: '设置用户级别',
    editAgent: '设置代理',
    editExpiryDate: '设置过期时间',
    selectUser: '选择用户',
    mailBoxTitle: '群发邮件',
    toLabel: '收件人',
    subjectLabel: '主题',
    contentLabel: '邮件正文',
    sendBtn: '发送邮件',
    sendSuccessMsg: '发送成功',
  },
  en: {
    cardTitle: 'Userlist',
    username: 'Username',
    status: 'Status',
    level: 'Rank',
    status_0: 'Invalid',
    status_1: 'Valid',
    status_2: 'Uncertificated',
    phone: 'Telephone',
    email: 'Email',
    organization: 'Organization',
    agent: 'Agent',
    region: 'Region',
    southchina: 'South China, HKG and MO',
    northchina: 'Other Area of China Mainland',
    overseas: 'Chinese Taiwan and Overseas Area',
    dev: 'dev',
    beta: 'beta',
    expiryDate: 'Expiry Date',
    regTime: 'Regtime',
    recentTime: 'Recent Active Time',
    taskAmt: 'Finished Tasks',
    quota: 'Task Quota',
    module: 'Modules',
    action: 'Action',
    detailLinkTitle: 'Click to check detail',
    editLinkTitle: 'Click to edit',
    certificateLinkTitle: 'Click to certificate CFDA user',
    personalTitle: 'Personal',
    taskTitle: 'Task',
    resTotal: 'Completed Task Count',
    res7: 'Recent 7 Days',
    res30: 'Recent 30 Days',
    res365: 'Recent 1 Year',
    submitBtn: 'Submit',
    resetBtn: 'Reset',
    successMsg: 'Update Successfully',
    editFormTitle: 'Edit User Task Quota',
    editLevelTitle: 'Edit User Rank',
    editAgent: 'Select Agent',
    editExpiryDate: 'Edit Expiry Date',
    selectUser: 'Select User',
    mailBoxTitle: 'Send Email',
    toLabel: 'To',
    subjectLabel: 'Subject',
    contentLabel: 'Content',
    sendBtn: 'Send Email',
    sendSuccessMsg: 'Send Successfully',
  },
};

export const homeContent = {
  zh: {
    slider1H1: '博脑云端医疗',
    slider1H4: '为广大脑部疾病患者提供帮助',
    slider2H1: '博脑愿景',
    slider2H4: '做全球华人脑影像技术的领导者',
    slider3H1: '博脑使命',
    slider3H4: '成为守护华人脑健康的核心力量',
    aboutH1: '关于博脑',
    companyH4: '博脑公司',
    companyPara: '博脑医疗科技是一家致力于神经医学影像领域的创新型公司，核心是依托先进的计算机医学影像分析技术，开发和推广适合临床应用计算机云计算工具，用于神经性疾病的辅助诊断。对神经影像进行智能化识别和分析，在临床上为神经退行性疾病的早期筛查、鉴别诊断提供客观证据，提高临床诊疗的精准度和效率。',
    productH4: '博脑产品',
    productPara: '博脑医疗的产品和服务涉及影像定量、计算分析等领域，可广泛应用于医院、体检中心、远程诊断云平台、医学教研机构和患者等用户群体。',
    groupH4: '博脑团队',
    groupPara: '博脑医疗拥有顶尖专家团队，在神经医学影像、计算机影像分析技术、人工智能深度学习技术和大数据运算平台等领域有丰厚经验，近年来也在国内国际等多个科技创新大赛上获得最高奖。博脑医疗总部位于深圳，现于深圳、香港设有两个研发中心。',
    accuBrainPara: 'AccuBrain®是首个云端在线的全自动神经影像判读系统。基于最新影像计算技术，实现快速的脑影像量化分析，协助医生进行临床诊断，提供了一个客观而标准化的诊断依据 。',
    brainAnalysisH4: '脑影像定量分析',
    brainAnalysisPara: '目前认知障碍症的筛查大都是基于认知评估和医生对磁共振脑影像（MRI）的视觉评估。阅读磁共振脑影像需要医生具有相当的神经学科知识与经验，诊断结果也存在一定的主观性，影响诊断准确率。作为医学影像计算的专业团队，我们创新性开发的AccuBrain®神经影像云计算定量工具，可快速精确地完成头部磁共振影像的脑组织分割，脑病变的自动定量，筛查不正常的脑组织和病变，一站式解决神经放射科医生脑影像无法定量的难题。通过该技术，可提高神经性脑疾病的临床诊断准确度，并且可以协助医生开展相关疾病的临床试验，以及支持疗法的评估。',
    serviceH1: 'AccuBrain®: 云端智能脑影像定量分析系统——',
    serviceH4_1: '神经退行性疾病影像辅助诊断工具',
    serviceH4: '让放射科医生，神经科医生，老人科医生以及精神科医生准确了解大脑的重要工具',
    partnerH1: '合作伙伴',
    contactH1: '联系我们',
    phonePara: '+86 0755-23486047（深圳办公室）  +852 36221760 （香港办公室）',
    szPara: '深圳：深圳市龙华区龙华街道富康社区东环二路110号中执时代广场B栋3K-1',
    hkPara: '香港：香港新界白石角科学园科技大道西1号核心大楼2座5楼535A室',
    wechatPara: '微信扫码关注我们',
  },
  en: {
    slider1H1: 'BrainNow Cloud-based Medical',
    slider1H4: 'To support the people with neurological diseases',
    slider2H1: 'BrainNow Vision',
    slider2H4: 'To be the global leader in Chinese neuroimaging technology',
    slider3H1: 'BrainNow Mission',
    slider3H4: 'To become the core force in safeguarding brain health of the Chinese people',
    aboutH1: 'About BrainNow',
    companyH4: 'The company',
    companyPara: 'BrainNow Medical Technology is an innovative company dedicated to the field of neuromedical imaging. Through advanced medical imaging analysis techniques, the mission of the company is to develop and promote cloud-based computing systems for clinical use, in particular, applications on aiding the diagnoses of neurological diseases. This technology offers an objective and intelligent way on obtaining clinical information from medical images with high accuracy and reliability. Most notably, it can identify early signs of neurological disease and provide information on differential diagnosis of neurodegenerative diseases, both of which are extremely valuable to effective management of neurodegenerative disease. ',
    productH4: 'The products',
    productPara: 'BrainNow\'s products and services involve medical imaging quantitative and computational analysis, which can be widely used in hospitals, physical examination centers, remote diagnostic cloud platforms, medical teaching institutions and other patient medical groups. ',
    groupH4: 'The team',
    groupPara: 'BrainNow has a team of top experts who have extensive experience in neuromedical imaging, computer image analysis technology, artificial intelligence deep learning technology, and big data computing platforms. In recent years, they have also won top awards in numerous domestic and international technology innovation contests. BrainNow Medical is headquartered in Shenzhen and currently has two R&D centers in Shenzhen and Hong Kong.',
    accuBrainPara: 'AccuBrain® is the first cloud-based, fully automatic neuroimaging anaylsis system. Based on the latest image computing technology, it can achieve rapid quantitative brain image analysis and assist doctors in clinical diagnosis, providing an objective and standardized diagnostic basis.',
    brainAnalysisH4: 'Brain imaging quantitative analysis',
    brainAnalysisPara: 'The current screening for cognitive disorders is mostly based on cognitive assessments and doctors\' visual assessment on magnetic resonance brain images (MRI). Reading a magnetic resonance brain image requires the doctor to have considerable neurological knowledge and experience, and the interpretation are also subjective which may affect diagnostic accuracy. As a professional team for medical imaging quantification, our innovative AccuBrain neural image cloud computing quantitative tool can quickly and accurately complete brain tissue segmentation of head MR images, automatic quantification of brain lesions, and screening for abnormal brain tissue and lesions, all of which are crucial information to obtain and not readily accessible by neuroradiologists. This technology not only will improve the accuracy of clinical diagnosis of neurological brain diseases, in terms of research, it can also assist doctors in conducting neurodegenerative disease trials of interest, and can serve as evaluation tool of a new therapy.',
    // serviceH1: 'AccuBrain® : Automated intelligent Cloud-based brain MRI analytic system for neurodegenerative diseases',
    // serviceH4_1: '',
    // serviceH4: 'for Radiologists, Neurologists, Geriatricians and Psychiatrists to precisely understand the brain',
    serviceH1: 'AccuBrain®: Automated Intelligent Cloud-based Brain MRI Analytic System — ',
    serviceH4_1: 'An Image-aided Diagnostic Tool for Neurodegenerative Diseases',
    serviceH4: 'For Radiologists, Neurologists, Geriatricians and Psychiatrists to Precisely Understand the Brain',
    partnerH1: 'Partners',
    contactH1: 'Contact Us',
    phonePara: '+86 0755-23486047（Shenzhen Office）  +852 36221760 （Hong Kong Office）',
    szPara: 'Shen Zhen:  3K-1, Block B, Zhongzhi Times Square, No. 110, Donghuan 2 Road, Longhua District, Shenzhen.',
    hkPara: 'Hong Kong: UNIT 535A, 5/F, Core Building 2, No. 1 Science Park West Avenue, Hong Kong Science Park, Pak Shek Kok, N.T., Hong Kong.',
    wechatPara: 'Please scan the QR code below to follow us by wechat',
  },
};

export const unsubscribe = {
  zh: {
    cardTitle: '退订邮件',
    content: '尊敬的用户，点击退订按钮可以退订任务完成的通知邮件，退订后您如果需要恢复任务完成通知邮件功能，请访问我们网站个人信息页面修改相应设置。',
    buttonTitle: '退订',
    successMsg: '退订成功',
  },
  en: {
    cardTitle: 'Unsubscribe',
    content: 'Dear user, click the Unsubscribe button to unsubscribe the notice email of task status. If you want to restore the notice email service after you unsubscribe, please visit our website and modify the settings on the profile page.',
    buttonTitle: 'Unsubscribe',
    successMsg: 'Unsubscribe successfully',
  },
};

export const news = {
  zh: {
    cardTitle: '最新动态',
    title: '标题',
    time: '发布时间',
  },
  en: {
    cardTitle: 'News',
    title: 'Title',
    time: 'Pubdate',
  },
};

export const footer = {
  zh: {
    browserTips: '推荐使用Chrome, Firefox, Edge等现代浏览器访问本网站，IE11或Safari浏览器可能会遇到排版异常',
  },
  en: {
    browserTips: 'We recommend you to visit our website with modern browsers like Chrome, Firefox and Edge. Using IE11 or Safari might cause some UI issues.',
  },
};
