import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { connect } from 'react-redux';
import axios from '../../helpers/axios';
import { upload as LANG } from '../../helpers/lan';
import { moduleEnum } from '../../helpers/modules';

const { Option } = Select;
const FormItem = Form.Item;
let timeout;

function fetch(mod, value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  async function getData() {    
    const res = await axios.get(`/auth/${mod === moduleEnum.SBMSL ? 'sb' : ''}msl-tasks?key=${value}`);
    const { taskList } = res.data;
    const data = taskList.map((tk) => ({
      value: tk.number,
      text: `${tk.number} - ${tk.patientName} - ${tk.scanTime}`,
    }));
    callback(data);
  }

  timeout = setTimeout(getData, 300);
}

class MslSelect extends React.Component {
  state = {
    data: [],
    value: undefined,
  };

  handleSearch = (value) => {
    const { module } = this.props;
    if (value) {
      fetch(module, value, (data) => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  render() {
    const LAN = LANG[this.props.lan];    
    const options = this.state.data.map((d) => <Option key={d.value}>{d.text}</Option>);
    return (
      <FormItem
        label={LAN.mslSearchLabel}
        name="mslSearchKey"
        key="mslSearchKey"
        rules={[{ required: true, message: LAN.mslRequiredMsg }]}
      >
        <Select
          allowClear
          showSearch
          value={this.state.value}
          placeholder={LAN.mslExistingPlaceholder}
          style={{ width: '100%' }}
          defaultActiveFirstOption={false}
          // showArrow={false}
          filterOption={false}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          notFoundContent={null}
        >
          {options}
        </Select>
      </FormItem>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

MslSelect.propTypes = {
  module: PropTypes.number,
};

export default connect(mapStateToProps)(MslSelect);
