import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Badge } from 'antd';
import { LogoutOutlined, UserOutlined, WarningOutlined, UnorderedListOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { actions } from '../../redux/index';
import { nav as LANG } from '../../helpers/lan';
import { setItem, removeItem } from '../../helpers/storage';
import LanMenuItem from './LanMenuItem';

const SMenu = styled(Menu)`
  @media screen and (max-width: 1050px) {
    line-height: 5.9em !important;
  }
  @media screen and (min-width: 1051px) {
    width: 100%;
    display: flex;
    justify-content:flex-end;
  }
`;

const MenuItem = styled(Menu.Item)`
  line-height: 5.9em;  
`;

const DividerItem = styled(MenuItem)`
  margin: 0 !important;
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

const WarningIcon = styled(WarningOutlined)`
  color: red;
`;

class WorkMenu extends React.Component {
  handleLogout = () => {
    setItem('loginType', 'logout');
    removeItem('username');
    removeItem('act');
    removeItem('userStatus');
    removeItem('userLevel');
    removeItem('modules');
    removeItem('defaultModuleConfig');
    removeItem('isUnreadNews');
    this.props.onLogout();
  }
  render() {
    const LAN = LANG[this.props.lan];
    const { userLevel, isUnreadNews } = this.props;
    const { pathname } = this.props.history.location;
    const selectedKey = pathname === '/news-content' ? '/news' : pathname;
    return (
      <SMenu
        mode="horizontal"
        selectedKeys={[selectedKey]}
        overflowedIndicator={<UnorderedListOutlined style={{ fontSize: '16px', paddingLeft: '8px' }} />}
      >
        <MenuItem key="/task">
          <Link to="/task">{LAN.task}</Link>
        </MenuItem>
        <MenuItem key="/upload">
          <Link to="/upload">{LAN.upload}</Link>
        </MenuItem>
        <MenuItem key="/report">
          <Link to="/report">{LAN.report}</Link>
        </MenuItem>
        <MenuItem key="/agreement">
          <Link to="/agreement">
            {this.props.userStatus !== 1 ? <WarningIcon /> : null}
            {LAN.agreement}
          </Link>
        </MenuItem>
        {
          userLevel === 0 ? null : (
            <MenuItem key="/news">
              <Link to="/news"><Badge dot={isUnreadNews}>{LAN.news}</Badge></Link>
            </MenuItem>
          )
        }
        <DividerItem key="divider" disabled>I</DividerItem>
        <MenuItem key="/profile">
          <Link to="/profile">
            <UserOutlined />
&ensp;
            <strong>{this.props.username}</strong>
          </Link>
        </MenuItem>
        <MenuItem key="logout">
          <Link to="/sign-in" onClick={this.handleLogout}>
            <LogoutOutlined />
&ensp;
            {LAN.signOut}
          </Link>
        </MenuItem>
        <MenuItem key="lan"><LanMenuItem /></MenuItem>
      </SMenu>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.username,
  lan: state.lan,
  userStatus: state.userStatus,
  userLevel: state.userLevel,
  isUnreadNews: state.isUnreadNews,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: (obj) => { dispatch(actions.signOut(obj)); },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkMenu));
