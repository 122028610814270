import React from 'react';
import styled from 'styled-components';
import img2 from '../../static/aboutAccubrainImgs/image002.jpg';
import img3 from '../../static/aboutAccubrainImgs/image003.jpg';
import img4 from '../../static/aboutAccubrainImgs/image004.jpg';
import img5 from '../../static/aboutAccubrainImgs/image005.jpg';
import img6 from '../../static/aboutAccubrainImgs/image006.jpg';
import img7 from '../../static/aboutAccubrainImgs/image007.jpg';
import img8 from '../../static/aboutAccubrainImgs/image008.jpg';
import img9 from '../../static/aboutAccubrainImgs/image009.jpg';
import img10 from '../../static/aboutAccubrainImgs/image010.jpg';

const Article = styled.article`
  width: 80em;
  margin: 2em auto;
  p {
    font-family: "Open Sans",Helvetica,Arial,sans-serif;
    font-size: 1em;    
  }
`;

const Header1 = styled.h2`
  color: #0f0f0f;
  font-weight: 600;
  font-size: 2em;
  font-family: OpenSans-Semibold,Helvetica,Arial,sans-serif;
`;

const Header2 = styled.h3`
  color: #0f0f0f;
  font-weight: 600;
  line-height: 1.1;
  font-size: 1.5em;
  font-family: "OpenSans-Semibold", Helvetica,Arial,sans-serif;
`;

const Header3 = styled.h4`
  color: #0f0f0f;   
  font-weight: 500; 
  font-size: 1.2em;
  font-family: "OpenSans-Semibold",Helvetica,Arial,sans-serif;
  font-style: italic;
`;

const Img = ({ src, alt }) => (
  <div align="center">
    <img src={src} alt={alt} style={{ maxWidth: '100%', textAlign: 'center' }} />
  </div>
);

const AboutAccubrain = () => (
  <Article>
    <Header1>AccuBrain<sup>&reg;</sup></Header1>
    <p>Cloud-based, fully automatic neuroimaging analysis system based on the latest image computing technology</p>
    <Img src={img2} alt="AccuBrain STATE of the ART" />
    <Header2>Overview</Header2>
    <Header3>Accurate, efficient, and proven for neuroimage analysis</Header3>
    <p>As the main product of BrainNow company, AccuBrain<sup>&reg;</sup> can achieve rapid quantitative brain image analysis and assist doctors in early detection and differential diagnosis of neurodegenerative diseases by providing objective and quantitative evidence.</p>
    <p>AccuBrain<sup>&reg;</sup> has been cleared with FDA (510(k) K202847) and CE mark and shown high accuracy, efficiency and inter-scanner reproducibility (Abrigo J, et al. Acta Radiologica, 2019).</p>
    <Header3>Broad clinical applications with research validations</Header3>
    <p>The brain volumetric measures derived from AccuBrain<sup>&reg;</sup> have been used to identify brain structural changes in different neurological diseases (e.g., stroke, small vessel disease and temporal lobe epilepsy [TLE]), to evaluate postoperative outcomes (e.g., Cushing's disease and TLE with hippocampal sclerosis), and to differentiate different dementias (e.g., vascular dementia from Alzheimer's disease [AD] and frontotemporal dementia from AD).</p>
    <br />
    <Header2>Functionality of AccuBrain<sup>&reg;</sup></Header2>
    <li>Quantification of brain atrophy</li>
    <li>Quantification of MRI manifestations of cerebral small vessel disease (e.g., white matter hyperintensity, cerebral microbleed)</li>
    <li>Innovative imaging biomarkers invented and patented by BrainNow for differential diagnosis, such as Alzheimer's Disease Resemblance Atrophy Index (AD-RAI) for AD, Frontotemporal Dementia Index (FTD-RAI) for FTD, etc.</li>
    <br />
    <Header2>News</Header2>
    <Header3>AccuBrain<sup>&reg;</sup> IV2.0 now available</Header3>
    <p>Recently, AccuBrain<sup>&reg;</sup> underwent a substantial revision update (version 2.0 - AccuBrain<sup>&reg;</sup> IV2.0), including an update in segmentation algorithms. According to the preliminary analyses, AccuBrain<sup>&reg;</sup> IV 2.0 presented even better segmentation accuracy of hippocampus and differential performance of AD (with AD-RAI) than the older version (IV1.2).</p>
    <Header2>How to access and use AccuBrain<sup>&reg;</sup></Header2>
    <p>Please contact our account manager for product quotation and user manual by email or phone</p>
    <p>Email: info@brainnow.cn</p>
    <p>Tel: +852 36221760 (Hong Kong Office)  +86 0755-23486047 (Shenzhen Office)</p>
    <br />
    <Header2>Validations of AccuBrain<sup>&reg;</sup> for accuracy and reproducibility</Header2>
    <Header3>Accuracy of AccuBrain<sup>&reg;</sup> in automated brain volumetry</Header3>
    <p>Hippocampal segmentations of AccuBrain<sup>&reg;</sup> presented high consistency with manual segmentation, with Dice similarity coefficients (DSC) of 0.89/0.89/0.89, intraclass correlation coefficient (ICC) of 0.94/0.96/0.95 and Pearson coefficients of 0.95/0.96/0.95 for left/right/total hippocampus (Abrigo J, et al. Acta Radiologica, 2019).</p>
    <Header3>Reproducibility of AccuBrain<sup>&reg;</sup> in automated brain volumetry</Header3>
    <p>AccuBrain<sup>&reg;</sup> generally presented lower coefficients of variance (CV) than the major research software for the quantification of different brain structures among different MRI scanners in clinical settings with no harmonization of imaging parameters (AccuBrain<sup>&reg;</sup>: 2.546% [1.348~8.800%], Research software 1: 9.577% [6.946~12.29%], Research software 2: 12.60% [7.245~20.98%]). Furthermore, this superiority of AccuBrain<sup>&reg;</sup> showed statistical significance (p≤0.001) (Liu S, et al. BMC Neuroscience, 2020).</p>
    <Header3>Accuracy of AccuBrain<sup>&reg;</sup> in quantification of white matter hyperintensity (WMH)</Header3>
    <p>AccuBrain<sup>&reg;</sup> has high consistency with manual segmentation and visual rating with either 2D FLAIR or 3D FLAIR, and these consistency measures were comparable between the results from 2D and 3D FLAIR (Table 1). These findings indicate that (1) 2D FLAIR is also acceptable for AccuBrain<sup>&reg;</sup> in WMH segmentation, and that (2) AccuBrain<sup>&reg;</sup> is a feasible option for clinical practice with routine MRI (Abrigo J, et al. VasCog 2016).</p>
    <p align="center">Table 1. Comparison of AccuBrain<sup>&reg;</sup> with manual segmentation and visual rating of WMH</p>
    <Img src={img3} alt="Table 1. Comparison of AccuBrain<sup>&reg;</sup> with manual segmentation and visual rating of WMH" />
    <Header3>Reproducibility of AccuBrain<sup>&reg;</sup> in WMH quantification</Header3>
    <p>Compared with the conventional research WMH quantification tool, automated WMH segmentation of AccuBrain<sup>&reg;</sup> shows smaller inter-scanner variability (Coefficient of variance for WMH volume: research software [23.6% ± 11.2%] vs. AccuBrain<sup>&reg;</sup> [14.6% ± 8.4%]) (Figure 1). This finding indicates the good reproducibility and reliability of AccuBrain<sup>&reg;</sup> in WMH segmentation on different MR scanners as well as the feasibility of AccuBrain<sup>&reg;</sup> for multi-center studies and longitudinal studies (Guo C, et al. RSNA 2018; Guo C, et al. Frontiers in Neuroscience, 2019).</p>
    <Img src={img4} alt="Figure 1" />
    <p align="center">Figure 1. WMH volume quantification of research software in comparison for WMH quantification and AccuBrain<sup>&reg;</sup> for multiple MR scans of same subjects. MR1~MR5 indicate the repetitive scans (2D FLAIR) on five MR scanners of each participant, and MR_3D indicates the 3D FLAIR scan on a specific scanner; NMO1~NMO5 indicates the five subjects that participated in the study for scanning within 24 hours.</p>
    <br />
    <Header2>Applications of AccuBrain<sup>&reg;</sup> in different neurological diseases</Header2>
    <Img src={img5} alt="" />
    <p align="center">Selected neurological disorders where AccuBrain<sup>&reg;</sup> has been validated for applications</p>
    <Header3>Differentiation of early stages of Alzheimer's disease</Header3>
    <p>AccuBrain<sup>&reg;</sup> identified gradient volume increase of hippocampus, amygdala and left temporal lobe from amnestic mild cognitive impairment (aMCI) to subject cognitive decline (SCD) and then to normal control (NC). The machine learning models derived from the volumetric measures from AccuBrain<sup>&reg;</sup> achieved good performance for the classifications (aMCI vs. NC and SCD vs. NC), no matter individual feature or multiple features are used as the predictors, indicating that the automated brain volumetry of AccuBrain<sup>&reg;</sup> has the potential for early diagnosis of preclinical AD (Zhao W, et al. Journal of Geriatric Psychiatry and Neurology, 2019).</p>
    <Header3>AD diagnosis with biological validation</Header3>
    <p>In differentiating AD and normal control (NC) from clinical diagnosis, AD-RAI presented an accuracy of 91%, sensitivity of 88%, specificity of 96% and AUC of 92%. Regarding the differentiation of AD from NC with biological validations, AD-RAI achieved an accuracy of 100%. In addition, AD-RAI achieved better performance than the volumetric measures of individual brain structures in both differentiation tasks (Mai Y, et al. Journal of Alzheimer's Disease, 2021).</p>
    <Header3>Early detection and risk estimation of AD</Header3>
    <p>AD-RAI can also be used to predict the clinical progression of NC and mild cognitive impairment (MCI) in 2 years. As shown in Figure 2, AD-RAI performed better than volumetric measures of individual brain structures: AUC=0.740 for predicting NC-to-MCI conversion; AUC=0.771 for predicting MCI-to-AD conversion (Zhao L, et al. Aging, 2019).</p>
    <Img src={img6} alt="" />
    <p align="center">Figure 2. ROC curve of prediction of conversion to MCI in NC subjects (A) and conversion to AD in MCI subjects (B) from logistic regression.</p>
    <p>In addition, AD-RAI even shows potential in detecting preclinical and prodromal AD. Compared with conventional volumetric measures of individual brain structures, AD-RAI achieved the best performance in detecting A+T+ in MCI patients (accuracy=86%, sensitivity=92%, specificity=81%) (Table 2A). Regarding the detection of A+T+ in NC subjects (Table 2B), AD-RAI also achieved the best specificity (95%) and accuracy (85.9%) (Liu W, et al. Aging, 2021). </p>
    <p align="center">Table 2A. Performance metrics of AD-RAI, HV, HF and MTA among MCI subjects in the detection of A+T+</p>
    <Img src={img7} alt="" />
    <br />
    <p align="center">Table 2B. Performance metrics of AD-RAI, HV, HF and MTA among CU subjects in the detection of A+T+</p>
    <Img src={img8} alt="" />

    <Header3>Differentiation of AD and vascular dementia (VaD)</Header3>
    <p>Volumetric measures from different brain regions were calculated by AccuBrain<sup>&reg;</sup> and used to construct a feature set to classify VaD vs. AD with machine learning. The derived model achieved good performance for differentiating VaD from AD (ACC=84.35%, AUC=0.861) (Zheng Y, et al. Frontiers in Neurology, 2019).</p>
    <Header3>Differentiation of AD and frontotemporal dementia (FTD)</Header3>
    <p>The brain volumetric measures of AccuBrain<sup>&reg;</sup> can be used to generate an MRI-based index (FTDI) that captures the difference of brain atrophy pattern between AD and FTD, which can be used together with AD-RAI for a comprehensive differentiation among normal control (NC)/AD/FTD. With AD-RAI>0.75, NC was well differentiated from AD or FTD (AUC=0.88). In the testing set, FTDI achieved high performance for differentiating AD from FTD: sensitivity=96%, specificity=70% (AUC=0.93) (Yu Q, et al. Alzheimer's Research & Therapy, 2021).</p>
    <Header3>Differentiation of AD and dementia with Lewy bodies (DLB)</Header3>
    <p>An MRI-based DLB resemblance atrophy index (DLB-RAI) was derived from volumetric measures of AccuBrain<sup>&reg;</sup>. The optimal cutoff of DLB-RAI in the differentiation of mild DLB from NC and AD was 0.5. The accuracy of DLB-RAI (≥0.5) in distinguishing DLB from NC was 83.0% (AUC=0.87) with an optimal sensitivity of 0.83 and specificity of 0.86, while the accuracy in differentiating between DLB and AD was 75.10% (AUC=0.77) (Liu W, et al. AAIC 2021)</p>
    <Header3>Differential diagnosis of Parkinson-plus syndromes</Header3>
    <p>Decision tree was derived from brain volumetry of AccuBrain<sup>&reg;</sup> for classifications of idiopathic Parkinson's disease (IPD)/Progressive supranuclear palsy (PSP)/multiple system atrophy (MSA-P), which achieved an average accuracy of 81.9% for IPD/PSP/MSA-P classifications, while the average accuracy of visual inspection was only 54.2% (Table 3). These findings indicate that brain volumetry of AccuBrain<sup>&reg;</sup> presents higher accuracy than visual inspection for differential diagnosis of IPD vs. MSA-P vs. PSP and may be applied in clinical practice (You H, et al. MDS 2019).</p>

    <p align="center">Table 3. Visual rating and brain volumetry of AccuBrain<sup>&reg;</sup> for differentiating PD subtypes</p>
    <Img src={img9} alt="" />
    <Header3>Brain structural alterations in multiple sclerosis (MS)</Header3>
    <p>Although neuromyelitis optica spectrum disorders (NMOSD) and MS generally presented similar brain atrophy pattern, we still found that they differed significantly in the thalamus, lateral ventricle and third ventricle. This finding indicates that AccuBrain<sup>&reg;</sup> can sensitively detect structural differences between MS and NMOSD and thus it should be feasible for the relevant cross-sectional studies (Guo C, et al. RSNA 2018).</p>
    <Header3>Automated detection of hippocampal sclerosis (HS)</Header3>
    <p>An MRI-based HS index (HSI) that integrates hippocampal volume and FLAIR signal to measure the severity of HS was derived by AccuBrain<sup>&reg;</sup>, which was consistent with visual rating scales from epileptologists and sensitive to HS severity (Figure 3) (Dou W, et al. BMC Medical Imaging, 2020).</p>
    <Img src={img10} alt="" />
    <p align="center">Figure 3. Boxplots of HSI for different visual rating-based categories</p>
    <p>In a study that included temporal lobe epilepsy (TLE) patients with pathologically confirmed HS, HSI performed even better than hippocampal volume (HV) (AUC=0.956 vs. 0.934, p=0.038) in detecting HS. In addition, HV dissymmetry was also significantly associated with the Engel classifications (postoperative outcomes of TLE patients) for the latest visit with >1 year follow-up (r=-0.374, p=0.003) while controlling for disease duration and follow-up duration. In this regard, the ipsilateral HSI and the magnitude of HV dissymmetry, when used together, may facilitate the clinical management of TLE patients with HS prior to surgical intervention (Zhao L, et al. Epilepsy Research, 2021).</p>
  </Article>
);

export default AboutAccubrain;
