import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Radio, Select, message } from 'antd';
import styled from 'styled-components';
import { UserOutlined, ReloadOutlined } from '@ant-design/icons';
import EditFormItem from './EditFormItem';
import DatePicker from '../UI/DatePicker';
import { moduleList } from '../../helpers/modules';
import { admin as LANG } from '../../helpers/lan';
import axios from '../../helpers/adminAxios';
import showError from '../../helpers/errorlist';
import dayjs from 'dayjs';

const { Option } = Select;

const TitleP = styled.p`
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
`;

const moduleListKdyWithOutCfda = Object.keys(moduleList);
moduleListKdyWithOutCfda.splice(0, 1);

class EditForm extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      agentlist: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      if (!this.state.username) { return; }
      const res = await axios.get(`/admin-auth/user-profiles/${this.state.username}`);
      const { module, quota, level, agent, expiryDate } = res.data.user;
      const fieldValues = { level: level.toString(), agent, expiryDate: expiryDate?dayjs(expiryDate):null };
      moduleListKdyWithOutCfda.forEach((v) => {
        fieldValues[v] = {
          checked: module.includes(Number(v)),
          quota: quota && quota[v],
        };
      });
      const { agentlist } = (await axios.get('/admin-auth/agent-digests')).data;
      this.setState({ agentlist });
      this.formRef.current.setFieldsValue({ ...fieldValues });
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  handleEdit = async (values) => {
    try {
      const module = [];
      const quota = {};
      const { level, agent, expiryDate } = values;
      delete values.level;
      delete values.agent;
      delete values.expiryDate;
      for (const mod of Object.keys(values)) {
        if (values[mod].checked) { module.push(Number(mod)); }
        quota[mod] = values[mod].quota;
      }

      await axios.patch(`/admin-auth/user-profiles/${this.state.username}`, { module, quota, agent, level: Number(level), expiryDate: expiryDate?.endOf('day').format() });
      const LAN = LANG[this.props.lan];
      message.success(LAN.successMsg);
      this.props.onReload();
      this.props.onClose();
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  handleReset = async () => {
    this.formRef.current.resetFields();
    this.loadData();
  }

  render() {
    const { lan } = this.props;
    const { agentlist } = this.state;
    const LAN = LANG[lan];

    return (
      <Form onFinish={this.handleEdit} ref={this.formRef} name="edit-user">
        {moduleListKdyWithOutCfda.map((v) => (
          <Form.Item
            label={moduleList[v][lan]}
            name={v}
            key={v}
          >
            <EditFormItem />
          </Form.Item>
        ))}
        <TitleP>{LAN.editLevelTitle}</TitleP>
        <Form.Item
          label={LAN.editLevelTitle}
          name="level"
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="0" key="lv0">Basic</Radio.Button>
            <Radio.Button value="1" key="lv1">Pro</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <TitleP>{LAN.editAgent}</TitleP>
        <Form.Item
          label={LAN.agent}
          name="agent"
        >
          <Select allowClear>
            {agentlist.map((v) => (
              <Option value={v.agentname} key={v.key}>{v.organization}</Option>
            ))}
          </Select>
        </Form.Item>
        <TitleP>{LAN.editExpiryDate}</TitleP>
        <Form.Item label={LAN.expiryDate} name="expiryDate">
          <DatePicker />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<UserOutlined />}>
            {LAN.submitBtn}
          </Button>
            &ensp;
          <Button type="default" htmlType="reset" icon={<ReloadOutlined />} onClick={this.handleReset}>
            {LAN.resetBtn}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

EditForm.propTypes = {
  onReload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  username: PropTypes.string,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default withRouter(connect(mapStateToProps)(EditForm));
