import React from 'react';
import { connect } from 'react-redux';
import { Table, Badge, Card, Input, Button, Modal, message } from 'antd';
import { ToolFilled } from '@ant-design/icons';
import styled from 'styled-components';
import axios from '../helpers/adminAxios';
import showError, { ERROR_LIST } from '../helpers/errorlist';
import { moduleList, categoryList } from '../helpers/modules';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 125em;
  max-width: 100vw;
`;

const { Search } = Input;

class Tasklist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasklist: [],
      pagination: {
        current: 1,
        pageSize: 30,
      },
      loading: false,
    };
    this.filters = {};
  }

  componentDidMount() {
    const { pagination } = this.state;
    if (this.timer) clearInterval(this.timer);
    this.loadTaskList({ pagination });
    this.timer = setInterval(() => {
      this.loadTaskList({ ...this.filters, pagination: this.state.pagination });
    }, 10000);
    this.retryCount = 3;
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  loadTaskList = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const res = await axios.get('/admin-auth/tasks', { params });
      const { tasklist, totalCount } = res.data;
      this.setState({
        tasklist,
        loading: false,
        pagination: { ...params.pagination, total: totalCount },
      });
      this.retryCount = 3;
    } catch (error) {
      this.retryCount -= 1;
      if (this.timer && this.retryCount === 0) {
        showError(new Error('10010'), this.props.lan);
        clearInterval(this.timer);
      }
    }
  }

  handleChange = (pagination, filters, sorter) => {
    if (this.filters && this.filters.username) {
      this.filters = { ...filters, username: this.filters.username };
    } else {
      this.filters = filters;
    }
    this.loadTaskList({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...this.filters,
    });
  }

  handleSearch = (value) => {
    this.filters.username = value;
    const pagination = { ...this.state.pagination, current: 1 };
    this.loadTaskList({ ...this.filters, pagination });
  }

  handleTaskRecover = async (record) => {
    const { number, username, _id, serverName } = record;
    Modal.confirm({
      title: '重置报告',
      content: <div>确定要重置这个任务的结果和报告吗？<br />用户名: {username}<br />任务编号: {number}</div>,
      onOk: async () => {
        const res = await axios.post('/admin-auth/task-recover-tasks', { _id, serverName });
        if (res?.data?.message === 'success') {
          message.success('重置报告请求已提交');
          const { pagination } = this.state;
          this.loadTaskList({ ...this.filters, pagination });
        } else {
          message.error(res?.data?.error || '提交失败，请重试');
        }
      },
    });
  }

  render() {
    const { lan } = this.props;
    const { tasklist, pagination, loading } = this.state;
    const LAN = LANG[lan];
    const columns = [
      {
        title: LAN.usernameTitle,
        dataIndex: 'username',
        key: 'username',
        width: 150,
        sorter: true,
      },
      {
        title: LAN.numberTitle,
        dataIndex: 'number',
        key: 'number',
        width: 150,
      }, {
        title: LAN.categoryTitle,
        dataIndex: 'category',
        key: 'category',
        filters: Object.keys(categoryList).map((key) => ({
          text: categoryList[key][lan],
          value: key,
        })),
        // onFilter: (value, record) => record.category.toString() === value,
        render: (value) => categoryList[value][lan],
      }, {
        title: LAN.moduleTitle,
        dataIndex: 'module',
        key: 'module',
        width: 200,
        filters: Object.keys(moduleList).map((key) => ({
          text: moduleList[key][lan],
          value: key,
        })),
        onFilter: (value, record) => record.module.toString() === value,
        render: (value) => {
          if (moduleList[value]) { return moduleList[value][lan]; }
          return value;
        },
      }, {
        title: LAN.statusTitle,
        dataIndex: 'status',
        key: 'status',
        width: 130,
        filters: [
          {
            text: LAN.waitingStatus,
            value: '0',
          }, {
            text: LAN.runningStatus,
            value: '1',
          }, {
            text: LAN.successStatus,
            value: '2',
          }, {
            text: LAN.failedStatus,
            value: '3',
          }, {
            text: LAN.expiredStatus,
            value: '4',
          }, {
            text: LAN.deletedStatus,
            value: '5',
          }, {
            text: LAN.previewingStatus,
            value: '6',
          }, {
            text: LAN.modifyingStatus,
            value: '7',
          }, {
            text: LAN.previewedStatus,
            value: '8',
          },
        ],
        onFilter: (value, record) => record.status.toString() === value,
        render: (value) => {
          switch (value) {
            case 0: return <Badge status="warning" text={LAN.waitingStatus} />;
            case 1: return <Badge status="processing" text={LAN.runningStatus} />;
            case 2: return <Badge status="success" text={LAN.successStatus} />;
            case 3: return <Badge status="error" text={LAN.failedStatus} />;
            case 4: return <Badge color="orange" text={LAN.expiredStatus} />;
            case 5: return <Badge color="magenta" text={LAN.deletedStatus} />;
            case 6: return <Badge color="cyan" text={LAN.previewingStatus} />;
            case 7: return <Badge color="geekblue" text={LAN.modifyingStatus} />;
            case 8: return <Badge color="steelblue" text={LAN.previewedStatus} />;
            default: return '';
          }
        },
      }, {
        title: LAN.reportVersion,
        dataIndex: 'reportVersion',
        key: 'reportVersion',
      }, {
        title: LAN.attributeTitle,
        dataIndex: 'isResearchData',
        key: 'isResearchData',
        filters: [{
          text: LAN.researchDataText,
          value: true,
        }],
        onFilter: (value, record) => record.isResearchData === value,
        render: (value) => value && <Badge color="skyblue" text={LAN.researchDataText} />,
      }, {
        title: LAN.startTimeTitle,
        dataIndex: 'startTime',
        key: 'startTime',
        sorter: true,
        // sortOrder: sortedInfo.columnKey === 'StartTime' && sortedInfo.order,
      }, {
        title: LAN.endTimeTitle,
        dataIndex: 'endTime',
        key: 'endTime',
        sorter: true,
        // sortOrder: sortedInfo.columnKey === 'StartTime' && sortedInfo.order,
      }, {
        title: LAN.serverName,
        dataIndex: 'serverName',
        key: 'serverName',      
        ellipsis: true,  
      }, {
        title: LAN.errorMsg,
        dataIndex: 'errorCode',
        key: 'errorCode',
        render: (value) => (ERROR_LIST[value] ? ERROR_LIST[value][this.props.lan] : ''),
        ellipsis: true,
      }, {
        title: LAN.action,
        key: 'action',
        width: 50,
        render: (value, record) => (
          <Button
            type="link"
            disabled={record.status === 0 || record.status === 1 || record.status === 5 || record.status === 7}
            icon={<ToolFilled />}
            onClick={() => { this.handleTaskRecover(record); }}
            title={LAN.recoverLink}
        />
        ),
      }];
    return (
      <SCard
        title={LAN.cardTitle}
        extra={(
          <Search
            placeholder={LAN.searchPlaceholder}
            onSearch={this.handleSearch}
            enterButton
            allowClear
          />
        )}
      >
        <Table
          columns={columns}
          size="middle"
          dataSource={tasklist}
          onChange={this.handleChange}
          pagination={pagination}
          loading={loading}
          scroll={{ x: 1050 }}
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(Tasklist);

const LANG = {
  zh: {
    cardTitle: '任务列表',
    usernameTitle: '用户名',
    numberTitle: '任务编号',
    categoryTitle: '分类',
    moduleTitle: '模块',
    statusTitle: '任务状态',
    waitingStatus: '等待中',
    runningStatus: '运行中',
    successStatus: '完成',
    failedStatus: '失败',
    expiredStatus: '超时',
    deletedStatus: '已删除',
    previewingStatus: '报告预览',
    modifyingStatus: '报告修订中',
    previewedStatus: '预览完毕',
    reportVersion: '报告版本',
    creationTimeTitle: '任务生成时间',
    startTimeTitle: '任务开始时间',
    endTimeTitle: '完成时间',
    serverName: '服务器',
    errorMsg: '错误信息',
    searchPlaceholder: '搜索用户',
    attributeTitle: '任务属性',
    researchDataText: '科研数据',
    action: '操作',  
    recoverLink: '重置报告',  
  },
  en: {
    cardTitle: 'Task',
    usernameTitle: 'Username',
    numberTitle: 'Task Number',
    categoryTitle: 'Category',
    moduleTitle: 'Module',
    statusTitle: 'Status',
    waitingStatus: 'Waiting',
    runningStatus: 'Running',
    successStatus: 'Success',
    failedStatus: 'Failed',
    expiredStatus: 'Expired',
    deletedStatus: 'Deleted',
    previewingStatus: 'Previewing',
    modifyingStatus: 'Modifying',
    previewedStatus: 'Previewed',
    reportVersion: 'Report Version',
    startTimeTitle: 'Start Time',
    endTimeTitle: 'End Time',
    serverName: 'Server',
    errorMsg: 'Error Message',
    searchPlaceholder: 'Search User',
    attributeTitle: 'Attribute',
    researchDataText: 'Research Data',
    action: 'Action',
    recoverLink: 'Recover Report',  
  },
};
