import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Form, Input, Button, message } from 'antd';
import { pbkdf2Sync } from 'crypto';
import { signIn as LANG } from '../../helpers/lan';
import showError from '../../helpers/errorlist';
import axios from '../../helpers/axios';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 40em;
  max-width: 100vw;
`;

class ResetPasswordCard extends Component {
  formRef = React.createRef();

  handleSubmit = async (values) => {
    try {
      const { newPassword } = values;
      const { lan, history } = this.props;
      const salt = 'brainnowyes';
      const searchParams = new URLSearchParams(history.location.search.substr(1));
      const agentname = searchParams.get('agentname');
      const rtoken = searchParams.get('rtoken');
      const data = {
        password: pbkdf2Sync(newPassword, salt, 1000, 32, 'sha512').toString('hex'),
        rtoken,
      };
      await axios.patch(`/agent/passwords/${agentname}`, data);
      message.success(LANG[lan].successMsg);
      history.push('/agent/sign-in');
    } catch (error) {
      showError(error, this.props.lan);
    }
    this.handleReset();
  }

  handleReset = () => {
    this.formRef.current.resetFields();
  }

  render() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    const searchParams = new URLSearchParams(this.props.history.location.search.substr(1));
    const agentname = searchParams.get('agentname');
    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { sm: 8 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { sm: 16 },
      },
    };
    const btnItemLayout = {
      wrapperCol: {
        xs: {
          span: 18,
          offset: 8,
        },
      },
    };
    return (
      <SCard title={LAN.resetPwdCardTitle}>
        <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef} name="reset-pwd">
          <Form.Item
            label={LAN.username}
            name="agentname"
          >
            <span>{agentname}</span>
          </Form.Item>
          <Form.Item
            label={LAN.newPasswordLabel}
            name="newPassword"
            rules={[
              { required: true, message: LAN.newPasswordRequiredMsg },
              { min: 6, message: LAN.newPasswordMinMsg },
              { pattern: /(\d+.*[a-zA-Z]+|[a-zA-Z]+.*\d+)/, message: LAN.passwordMatchMsg },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={LAN.checkPasswordLabel}
            name="rePassword"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: LAN.checkPasswordRequiredMsg },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(LAN.checkPasswordValidatorMsg);
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...btnItemLayout}>
            <Button type="primary" htmlType="submit">{LAN.submitBtn}</Button>
            &emsp;
            <Button type="default" htmlType="reset" onClick={this.handleReset}>{LAN.reset}</Button>
          </Form.Item>
        </Form>
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default withRouter(connect(mapStateToProps)(ResetPasswordCard));
