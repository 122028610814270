import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Table } from 'antd';
import styled from 'styled-components';
import { actions } from '../../redux/index';
import { news as LANG } from '../../helpers/lan';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';
import { setItem } from '../../helpers/storage';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 60em;
  max-width: 100vw;
`;

const STable = styled(Table)`
  td {
    cursor: pointer;
  }
`;

const SSpan = styled.span`
  .title {
    font-weight: ${(props) => (props.isStrong ? 'bold' : 'normal')};
    color: black;
  }
  .digest {
    color: gray;
    font-size: 0.9em;
  }
`;

const NewTitle = ({ digest, title, isStrong }) => {
  // const showDigest = digest.length > 100 ? `${digest.substr(0, 97)}...` : digest;
  return (
    <SSpan isStrong={isStrong}>
      <span className="title">{title}</span><br />
      <span className="digest">{digest}</span>
    </SSpan>
  );
};

NewTitle.propTypes = {
  digest: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isStrong: PropTypes.bool.isRequired,
};

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsList: [],
      unreadNews: [],
    };
  }

  componentDidMount = async () => {
    const { lan } = this.props;
    try {
      const res = await axios.get('/news-lists');
      const { newsList } = res.data;
      const res2 = await axios.get('/auth/unread-news');
      const { unreadNews } = res2.data;
      this.setState({ newsList, unreadNews });
    } catch (error) {
      showError(error, lan);
    }
  }

  handleChangeEmailList = (newsList) => {
    this.setState({ newsList });
  }

  render() {
    const { lan, history, onRemoveRedDot } = this.props;
    const LAN = LANG[lan];
    const unreadNews = this.state.unreadNews || [];
    const newsList = this.state.newsList || [];
    const columns = [
      {
        title: LAN.title,
        dataIndex: 'title',
        key: 'title',
        render: (text, value) => (
          <NewTitle title={text} digest={value.digest} isStrong={unreadNews.includes(value._id)} />
        ),
      },
      {
        title: LAN.time,
        dataIndex: 'time',
        key: 'time',
        width: 160,
        render: (text, value) => (
          unreadNews.includes(value._id) ? <strong>{text}</strong> : text
        ),
      },
    ];
    return (
      <SCard
        title={LAN.cardTitle}
      >
        <STable
          size="small"
          columns={columns}
          dataSource={newsList}
          pagination={{ pageSize: 20 }}
          onRow={(record) => ({
            onClick: async () => {
              if (unreadNews.includes(record._id)) {
                try {
                  await axios.patch('/auth/unread-news', { id: record._id });
                  if (unreadNews.length === 1) {
                    // setItem('isUnreadNews', false);
                    onRemoveRedDot();
                  }
                } catch (error) {
                  showError(error, lan);
                }
              }
              history.push(`/news-content?id=${record._id}`);
            },
          })}
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

const mapDispatchToProps = (dispatch) => ({
  onRemoveRedDot: () => { dispatch(actions.changeIsUnreadNews(false)); },
});

News.propTypes = {
  onRemoveRedDot: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News));
