import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Input, Button, message } from 'antd';
import { LoginOutlined, ReloadOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { pbkdf2Sync } from 'crypto';
import axios from '../../helpers/agentAxios';
import showError from '../../helpers/errorlist';
import { actions } from '../../redux/index';
import { setItem } from '../../helpers/storage';
import ForgotModal from '../UI/ForgotModal';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 42em;
  max-width: 100vw;
`;

// Agent Sign In Card
class SignInCard extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    if (window.ActiveXObject) {
      // eslint-disable-next-line no-alert
      alert(LAN.ie10Warning);
      return;
    }
    if ('ActiveXObject' in window) {
      message.warning(LAN.ie11Warning, 10);
    }
  }

  handleSubmit = async (values) => {
    const { lan, onLogin, history } = this.props;
    try {
      const { password } = values;
      const salt = 'brainnowyes';
      values.password = pbkdf2Sync(password, salt, 1000, 32, 'sha512').toString('hex');
      const res = await axios.post('/agent/sessions', values);
      const { agentname, token } = res.data;
      setItem('act', token);
      setItem('loginType', 'agent');
      setItem('agentname', agentname);
      onLogin({
        agentname,
        loginType: 'agent',
      });
      history.push('/agent/userlist');
    } catch (error) {
      showError(error, lan);
    }
  }

  handleReset = () => {
    this.formRef.current.resetFields();
  }

  handleShowModal = () => {
    this.setState({ visible: true });
  }

  handleCloseModal = () => {
    this.setState({ visible: false });
  }

  render() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
        sm: { sm: 6 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { sm: 16 },
      },
    };
    const btnItemLayout = {
      wrapperCol: {
        xs: {
          span: 18,
          offset: 6,
        },
      },
    };
    return (
      <SCard title={LAN.loginTitle}>
        <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef} name="agent-sign-in">
          <Form.Item
            label={LAN.username}
            name="agentname"
            rules={[
              {
                required: true,
                message: LAN.usernameMsg,
              },
            ]}
          >
            <Input placeholder="Agent ID/Email" />
          </Form.Item>
          <Form.Item
            label={LAN.password}
            name="password"
            rules={[
              {
                required: true,
                message: LAN.passwordMsg,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...btnItemLayout}>
            <Button type="primary" icon={<LoginOutlined />} htmlType="submit">{LAN.login}</Button>
            &emsp;
            <Button type="default" icon={<ReloadOutlined />} htmlType="reset" onClick={this.handleReset}>{LAN.reset}</Button>
            &emsp;
            <Button type="link" onClick={this.handleShowModal}>{LAN.forgotLink}</Button>
          </Form.Item>
        </Form>
        <ForgotModal
          visible={this.state.visible}
          onClose={this.handleCloseModal}
          url="/agent/forgot-password-emails"
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (obj) => { dispatch(actions.agentSignIn(obj)); },  
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInCard));

const LANG = {
  zh: {
    username: '用户名',
    password: '密码',
    usernameMsg: '请输入用户名',
    passwordMsg: '请输入密码!',
    login: '登录',
    reset: '重置',
    loginTitle: '代理登录',
    signUpLink: '注册新用户',
    forgotLink: '忘记密码',
    forgotModalTitle: '忘记密码?',
    forgotModalLabel: '请输入注册用户的电子邮箱地址,我们将发送一封邮件帮助您重置密码',
    submitBtn: '提交',
    successMsg: '请求成功',
    resetPwdCardTitle: '重置密码',
    newPasswordLabel: '新密码',
    newPasswordRequiredMsg: '请输入新密码',
    newPasswordMinMsg: '密码长度不得少于6位',
    passwordMatchMsg: '密码必须包含字母和数字',
    checkPasswordLabel: '重复新密码',
    checkPasswordRequiredMsg: '请再次输入新密码',
    checkPasswordValidatorMsg: '两次输入的密码不一致',
    ie10Warning: '尊敬的用户，您正在使用老版本IE浏览器访问本站点，本站采用基于现代浏览器的前端技术开发，不支持IE10及以下版本浏览器，建议使用Chrome、Firefox，Edge等现代浏览器访问本站点，IE11浏览器可能会遇到一些排版异常，敬请谅解！',
    ie11Warning: '尊敬的用户，您正在使用IE11浏览器，可能会遇到一些排版异常，建议使用Chrome、Firefox，Edge等现代浏览器访问本站点',
  },
  en: {
    username: 'Username',
    password: 'Password',
    usernameMsg: 'Please input username',
    passwordMsg: 'Please input password!',
    login: 'Agent Sign In',
    reset: 'Reset',
    loginTitle: 'Sign In',
    signUpLink: 'Sign Up',
    forgotLink: 'Forgot Password',
    forgotModalTitle: 'Forgot your password?',
    forgotModalLabel: 'Please input the email of your account. We will send you an email to help you reset your password.',
    submitBtn: 'Submit',
    successMsg: 'Success',
    resetPwdCardTitle: 'Reset Password',
    newPasswordLabel: 'New Password',
    newPasswordRequiredMsg: 'Please input new password',
    newPasswordMinMsg: 'The length of password must not be less than 6 characters',
    passwordMatchMsg: 'Password must consist of letters and numbers',
    checkPasswordLabel: 'Confirm New Password',
    checkPasswordRequiredMsg: 'Please input new password again',
    checkPasswordValidatorMsg: 'The password is not the same one as you entered last time!',
    ie10Warning: 'Dear user. You are using old version IE browser which not support modern web technology we use to design our website. We recommend you to visit our website with modern browsers like Chrome, Firefox and Edge. IE11 browser might also cause some UI issues.',
    ie11Warning: 'Dear user. You are using IE11 browser which might cause some UI issues. We recommend you to visit our website with modern browsers like Chrome, Firefox and Edge.',
  },
};
