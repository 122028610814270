import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { TranslationOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { nav as LANG } from '../../helpers/lan';
import { actions } from '../../redux/index';

const SSpan = styled(Button)` 
  @media screen and (max-width: 1050px) {
    padding: 0;
  }

  padding-top: 30px;
  padding-bottom: 50px;
  color: rgba(0, 0, 0, 0.85);
  &:focus {
    color: rgba(0, 0, 0, 0.85);
  }
  &:hover {
    color: #1890ff;
  }
`;

const LanMenuItem = (props) => {
  const handleChangeLan = () => {
    if (props.lan === 'zh') {
      props.setLan('en');
      localStorage.setItem('lan', 'en');
    } else {
      props.setLan('zh');
      localStorage.setItem('lan', 'zh');
    }
  };
  const LAN = LANG[props.lan];

  return (
    <SSpan
      onClick={handleChangeLan}
      type="link"
      icon={<TranslationOutlined style={{ fontSize: '1.2em', marginRight: 0 }} />}
    >
      {LAN.language}
    </SSpan>
  );
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

const mapDispatchToProps = (dispatch) => ({
  setLan: (lan) => { dispatch(actions.setLan(lan)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(LanMenuItem);
