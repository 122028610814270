import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, message } from 'antd';

class EditPwdModal extends React.Component {
  state = {
    pwd: '',
  }

  handleClose = () => {
    this.setState({ pwd: '' });
    this.props.onClose();
  }

  handleOk = () => {
    if (this.state.pwd === '9988') {
      this.props.onOpenEditSummaryDrawer();
      this.handleClose();
    } else {
      message.error('密码错误');
    }
  }

  render() {
    const { pwd } = this.state;
    const { visible } = this.props;
    return (
      <Modal
        visible={visible}
        title="请输入审核员密码"
        onCancel={this.handleClose}
        onOk={this.handleOk}
        destroyOnClose
      >
        <Input 
          type="password" 
          value={pwd} 
          placeholder="请输入审核员密码" 
          onChange={(e) => { this.setState({ pwd: e.target.value }); }} 
        />
      </Modal>
    );
  }
}

EditPwdModal.propTypes = {
  visible: PropTypes.bool,  
  onClose: PropTypes.func.isRequired,
  onOpenEditSummaryDrawer: PropTypes.func.isRequired,
};

EditPwdModal.defaultProps = {
  visible: false,
};

export default EditPwdModal;
