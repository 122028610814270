module.exports = [
  {
    title: '双侧基底节区陈旧腔隙性脑梗塞',
    imageview: `双侧大脑半球对称，双侧基底节区见点片状T1WI低信号、T2WI高信号、T2-FLAIR高信号，DWI等信号，边缘清楚，各脑室、脑池大小形态正常，中线结构居中，幕下小脑、脑干无异常信号。蝶鞍内见类圆形脑脊液信号影，垂体腺受压变扁、贴于鞍底。双侧筛窦、上颌窦黏膜增厚。

DWI：双侧脑实质未见弥散受限异常信号。
SWI：双侧脑实质幅度图及相位图未见异常信号。      
    `,
    diagnosis: `1、双侧基底节区陈旧腔隙性脑梗塞。
2、空泡蝶鞍。
3、双侧慢性筛窦、上颌窦炎。
    `,
  },
  {
    title: '双侧额叶皮层下小缺血灶/基底节区微出血灶',
    imageview: `双侧大脑半球对称，双侧基底节、放射冠区见点状、线条样T1WI低信号、T2WI高信号、T2-FLAIR低信号，与脑脊液信号一致，伴随血管分布走行，边缘清晰，双侧额叶皮层下见点片状TWI稍低信号、T2WI高信号、T2-FLAIR稍高信号，边缘模糊，中线结构居中，幕下小脑、脑干无异常信号。

DWI：双侧脑实质未见弥散受限异常信号。
SWI：幅度图双侧基底节区见多发圆点状低信号，相位图呈圆点状低信号，边缘清楚。幅度图双侧基底节苍白球见对称性斑片状低信号、相位图部位病灶呈斑片状高信号。        
    `,
    diagnosis: `1、双侧基底节、放射冠区周围血管间隙扩大。
2、双侧额叶皮层下小缺血灶。
3、双侧基底节区多发微出血灶。
4、双侧基底区苍白球铁钙质沉积。
    `,
  },
  {
    title: '颅脑磁共振成像未见异常',
    imageview: `双侧大脑半球对称，双侧脑实质信号均匀，各脑室、脑池大小形态正常，脑沟未见增宽增深，中线结构居中，幕下小脑、脑干无异常信号。

DWI：双侧脑实质未见弥散受限异常信号。
SWI：双侧脑实质幅度图及相位图未见异常信号。
    `,
    diagnosis: '颅脑磁共振成像未见异常。',
  },
  {
    title: '左/右侧基底节区海绵状血管瘤/血管周围间隙扩大',
    imageview: `双侧大脑半球对称，双侧基底节、放射冠及半卵圆中心见多发点片状T1WI低信号、T2WI高信号、T2-FLAIR稍高信号、部分病灶DWI低信号，边缘清楚。双侧基底节、放射冠区见点状、线条样T1WI低信号、T2WI高信号、T2-FLAIR低信号，与脑脊液信号一致，伴随血管分布走行，边缘清晰，脑室系统无明显扩张，中线结构居中，幕下小脑、脑干无异常信号。
        
DWI：双侧脑实质未见弥散受限异常信号。
SWI：幅度图示左侧/右侧基底节区见圆点状低信号，相位图呈圆点状低信号，边缘清楚，直径约5mm。幅度图双侧基底节苍白球见对称性斑片状低信号、相位图部位病灶呈斑片状高信号。
    `,
    diagnosis: `1、双侧基底节、放射冠及半卵圆中心多发陈旧腔隙性脑梗塞。
2、双侧基底节、放射冠区周围血管间隙扩大。
3、左/右侧基底节区微小海绵状血管瘤。
4、双侧基底区苍白球铁钙质沉积。
    `,
  },
  {
    title: '双侧基底节区陈旧腔隙性脑梗塞/右侧小脑静脉发育畸形',
    imageview: `双侧大脑半球对称，双侧基底节、放射冠及半卵圆中心见多发点片状T1WI低信号、T2WI高信号、T2-FLAIR稍高信号、部分病灶DWI低信号，边缘清楚。双侧基底节、放射冠区见点状、线条样T1WI低信号、T2WI高信号、T2-FLAIR低信号，与脑脊液信号一致，伴随血管分布走行，边缘清晰，脑室系统无明显扩张，中线结构居中，幕下小脑、脑干无异常信号。
        
DWI：双侧脑实质未见弥散受限异常信号。
SWI：右侧小脑半球见髓静脉迂曲、扩张，呈“海蛇头”样改变。幅度图双侧基底节苍白球见对称性斑片状低信号、相位图部位病灶呈斑片状高信号。
    `,
    diagnosis: `1、双侧基底节、放射冠及半卵圆中心多发陈旧腔隙性脑梗塞。
2、双侧基底节、放射冠区周围血管间隙扩大。
3、右侧小脑半球静脉发育畸形。
4、双侧基底区苍白球铁钙质沉积。
    `,
  },
];
