import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import p1 from '../../../static/functionImgs/1-auto.png';
import p2 from '../../../static/functionImgs/2-brain.png';
import p3 from '../../../static/functionImgs/3-secure.png';
import p4 from '../../../static/functionImgs/4-tracking.png';
import p5 from '../../../static/functionImgs/5-diagnose.png';
import p6 from '../../../static/functionImgs/6-research.png';
import DisplayCard from './DisplayCard';

const SSection = styled.section`
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;    
    align-items: center;

  h1 {
    text-align: center;
    color: #3C658B;
    font-weight: bolder;
  }

  .cardDiv{
    max-width: 60em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const lanObj = {
  zh: {
    h1: '产品功能',
    titles: [
      '全自动云端影像分析',
      '个体化3D脑组织容积直观、量化数据',
      '自动数据脱敏、加密',
      '跟踪病程发展',
      '多种脑部疾病检测辅助诊断',
      '全脑定量辅助科研',
    ],
  },
  en: {
    h1: 'Features',
    titles: [
      'Fully Automated Cloud-based Image Analysis',
      '3D Brain Visualization & Quantification',
      'Data Anonymization & Encryption',
      'Progression Monitoring',
      'Disease-specific Assessment for Diagnosis',
      'Whole Brain Volumetry for Research',
    ],
  },
};

const imgs = [p1, p2, p3, p4, p5, p6];

const FunctionSection = (props) => (
  <SSection>
    <h1>{lanObj[props.lan].h1}</h1>
    <div className="cardDiv">
      {imgs.map((v, i) => <DisplayCard img={v} title={lanObj[props.lan].titles[i]} key={v} />)}
    </div>

  </SSection>
);

FunctionSection.propTypes = {
  lan: PropTypes.string,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(FunctionSection);
