import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { LoginOutlined, UnorderedListOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { nav as LANG } from '../../helpers/lan';
import LanMenuItem from './LanMenuItem';

const SMenu = styled(Menu)`
  @media screen and (max-width: 1050px) {
    line-height: 5.9em !important;
  }
  @media screen and (min-width: 1051px) {
    width: 100%;
    display: flex;
    justify-content:flex-end;
  }
`;

const MenuItem = styled(Menu.Item)`
  line-height: 5.9em;
`;

class LoginMenu extends React.Component {
  handleJumpAnchor = (anchorName) => {
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) { anchorElement.scrollIntoView(); }
    }
  }

  render() {
    const LAN = LANG[this.props.lan];
    return (
      <SMenu
        mode="horizontal"
        selectedKeys={[this.props.history.location.pathname]}
        overflowedIndicator={<UnorderedListOutlined style={{ fontSize: '16px', paddingLeft: '8px' }} />}
      >
        <MenuItem key="/"><Link to="/">{LAN.home}</Link></MenuItem>
        <MenuItem key="aboutUs"><Link to="/" onClick={() => this.handleJumpAnchor('aboutUs')}>{LAN.aboutUs}</Link></MenuItem>
        <MenuItem key="/about-accubrain"><Link to="/about-accubrain">{LAN.product}</Link></MenuItem>
        <MenuItem key="contactUs"><Link to="/" onClick={() => this.handleJumpAnchor('contactUs')}>{LAN.contactUs}</Link></MenuItem>
        <MenuItem key="/sign-in" icon={<LoginOutlined />}><Link to="/sign-in">{LAN.accubrain}</Link></MenuItem>
        <MenuItem key="lan"><LanMenuItem /></MenuItem>
      </SMenu>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default withRouter(connect(mapStateToProps)(LoginMenu));
