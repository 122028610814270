import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Input } from 'antd';
import { signIn as LANG } from '../../helpers/lan';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';

class ForgotModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      isSubmitting: false,
    };
  }

  handleChange = (e) => {
    this.setState({ email: e.target.value });
  }

  handleClose = () => {
    this.setState({ email: null, isSubmitting: false });
    this.props.onClose();
  }

  handleSubmit = async () => {
    const { lan, url } = this.props;
    const LAN = LANG[lan];
    const { email } = this.state;
    try {
      this.setState({ isSubmitting: true });
      await axios.post(url, { email: email?.toLowerCase() });
      Modal.success({
        content: LAN.successMsg,
        onOk: this.handleClose,
      });

      this.handleClose();
    } catch (error) {
      showError(error, lan);
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const { lan, visible } = this.props;
    const LAN = LANG[lan];
    return (
      <Modal
        title={LAN.forgotModalTitle}
        visible={visible}
        onCancel={this.handleClose}
        okText={LAN.submitBtn}
        onOk={this.handleSubmit}
        confirmLoading={this.state.isSubmitting}
      >
        <p>{LAN.forgotModalLabel}</p>
        <Input
          type="email"
          placeholder="Email"
          value={this.state.email}
          onChange={this.handleChange}
          onPressEnter={this.handleSubmit}
        />
      </Modal>
    );
  }
}

ForgotModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(ForgotModal);
