import React from 'react';
import { connect } from 'react-redux';
import { Card, Table, Button, Input, Modal, Badge, message } from 'antd';
import { CloudDownloadOutlined, DeleteOutlined, MonitorOutlined, LoadingOutlined, SafetyCertificateOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import dayjs from 'dayjs';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';
import { task as LANG } from '../../helpers/lan';
import { moduleList, categoryEnum, moduleEnum } from '../../helpers/modules';
import EditPwdModal from './EditPwdModal';
import EditSummaryDrawer from './EditSummaryDrawer';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 125em;
  /* min-width: 105em; */
  max-width: 100vw;
`;

const DownloadIcon = styled(CloudDownloadOutlined)`
  font-size: larger;
  color: ${(props) => (props.lan === 'tr' ? 'palevioletred' : '#1890ff')};
`;

const PreviewIcon = styled(MonitorOutlined)`
  color: palevioletred;
`;

const CertificateIcon = styled(SafetyCertificateOutlined)`
  color: indigo;
`;

const { Search } = Input;

class ReportCard extends React.Component {
  state = {
    reportList: [],
    editModalVisible: false,    
    editRecord: {},
    editDrawerVisible: false,
  }

  componentDidMount() {
    if (this.timer) clearInterval(this.timer);
    this.loadList();
    this.timer = setInterval(() => {
      this.loadList();
    }, 10000);
    this.retryCount = 3;
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  loadList = async () => {
    let api = '/auth/reports';
    const { searchValue } = this;
    if (searchValue) {
      api = `${api}?searchValue=${searchValue}`;
    }
    try {
      const res = await axios.get(api);
      const { reportList } = res.data;
      this.setState({ reportList });
      this.retryCount = 3;
    } catch (error) {
      this.retryCount -= 1;
      if (this.timer && this.retryCount === 0) {
        showError(new Error('10010'), this.props.lan);
        clearInterval(this.timer);
      }
    }
  }

  handleSearch = (value) => {
    this.searchValue = value;
    this.loadList();
  }

  onDownload = async (record, category, lan) => {
    let requestLan = lan;
    // ONLY ZH
    if (category === categoryEnum.MC || record.module === moduleEnum.BH || record.module === moduleEnum.GP || category === categoryEnum.GE) {
      requestLan = 'zh';
    } 
    const suffix = category === categoryEnum.CFDA ? '_cfda' : '';
    const res = await axios.get(`/auth/downloadurls?lan=${requestLan}&number=${record.number}&reportName=${record.reportName}${suffix}`);
    const { downloadUrl: url } = res.data;

    if ('ActiveXObject' in window) {
      document.write(`<a id='LINK${record.number}' href='${url}' style='display:none'>a</a>`);
      document.getElementById(`LINK${record.number}`).click();
    } else {
      window.open(url);
    }
  }

  onPreview = async (record) => {
    const { history } = this.props;
    history.push(`/preview?number=${record.number}&reportName=${record.reportName}&status=${record.status}`);
  }

  onDelete = async (record) => {
    const { lan } = this.props;
    const LAN = LANG[lan];
    Modal.confirm({
      title: LAN.deleteBtn,
      content: `${LAN.deleteContent} ${record.number}?`,
      onOk: async () => {
        const res = await axios.patch('/auth/tasks', {
          id: record._id,
        });
        if (res?.data?.message === 'success') {
          message.success(LAN.successMsg);
          this.loadList();
        } else {
          message.error(LAN.failedMsg);
        }
      },
    });
  }

  onEdit = async (record) => {
    this.setState({ editModalVisible: true, editRecord: record });
  }

  render() {
    const { lan, modules } = this.props;
    const LAN = LANG[lan];
    const { editModalVisible, reportList, editRecord, editDrawerVisible } = this.state;
    const columns = [
      {
        title: LAN.numberTitle,
        dataIndex: 'number',
        key: 'number',
        width: 150,
        sorter: (a, b) => (a.number >= b.number ? 1 : -1),
        // sortOrder: sortedInfo.columnKey === 'Number' && sortedInfo.order,
      }, {
        title: LAN.statusTitle,
        dataIndex: 'status',
        key: 'status',
        width: 130,
        filters: [
          {
            text: LAN.waitingStatus,
            value: '0',
          }, {
            text: LAN.runningStatus,
            value: '1',
          }, {
            text: LAN.successStatus,
            value: '2',
          }, {
            text: LAN.failedStatus,
            value: '3',
          }, {
            text: LAN.expiredStatus,
            value: '4',
          }, {
            text: LAN.deletedStatus,
            value: '5',
          }, {
            text: LAN.previewingStatus,
            value: '6',
          }, {
            text: LAN.modifyingStatus,
            value: '7',
          }, {
            text: LAN.previewedStatus,
            value: '8',
          },
        ],
        onFilter: (value, record) => record.status.toString() === value,
        render: (value) => {
          switch (value) {
            case 0: return <Badge status="warning" text={LAN.waitingStatus} />;
            case 1: return <Badge status="processing" text={LAN.runningStatus} />;
            case 2: return <Badge status="success" text={LAN.successStatus} />;
            case 3: return <Badge status="error" text={LAN.failedStatus} />;
            case 4: return <Badge color="orange" text={LAN.expiredStatus} />;
            case 5: return <Badge color="magenta" text={LAN.deletedStatus} />;
            case 6: return <Badge color="cyan" text={LAN.previewingStatus} />;
            case 7: return <Badge color="geekblue" text={LAN.modifyingStatus} />;
            case 8: return <Badge color="steelblue" text={LAN.previewedStatus} />;
            default: return '';
          }
        },
      }, {
        title: LAN.moduleTitle,
        dataIndex: 'module',
        key: 'module',
        // width: 200,
        filters: Object.keys(moduleList).map((key) => ({
          text: moduleList[key][lan],
          value: key,
        })),
        onFilter: (value, record) => record.module.toString() === value,
        render: (value) => moduleList[value][lan],
      }, {
        title: LAN.reportVersion,
        dataIndex: 'reportVersion',
        key: 'reportVersion',
        sorter: (a, b) => (a.reportVersion >= b.reportVersion ? 1 : -1),
        ellipsis: true,
      }, {
        title: LAN.attributeTitle,
        dataIndex: 'isResearchData',
        key: 'isResearchData',
        filters: [{
          text: LAN.researchDataText,
          value: true,
        }],
        onFilter: (value, record) => record.isResearchData === value,
        render: (value) => value && <Badge color="skyblue" text={LAN.researchDataText} />,
      }, {
        title: LAN.commentTitle,
        dataIndex: 'comment',
        key: 'comment',
        ellipsis: true,
      }, {
        title: LAN.pIdTitle,
        dataIndex: 'patientId',
        key: 'patientId',
        ellipsis: true,
      }, {
        title: LAN.pNameTitle,
        dataIndex: 'patientName',
        key: 'patientName',
        ellipsis: true,
      }, {
        title: LAN.pGenderTitle,
        dataIndex: 'patientGender',
        key: 'patientGender',
        width: 80,
      }, {
        title: LAN.pAgeTitle,
        dataIndex: 'patientAge',
        key: 'patientAge',
        width: 60,
      }, {
        title: LAN.scanTimeTitle,
        dataIndex: 'scanTime',
        key: 'scanTime',
        // width: 120,
        render: (value) => dayjs(value).format('YYYY-MM-DD'),
        // sorter: (a, b) => (a.startTime > b.startTime ? 1 : -1),
        // sortOrder: sortedInfo.columnKey === 'StartTime' && sortedInfo.order,
        // }, {
        //   title: LAN.reportTimeTitle,
        //   dataIndex: 'endTime',
        //   key: 'endTime',
        //   width: 170,
        //   sorter: (a, b) => (a.endTime > b.endTime ? 1 : -1),
        //   // sortOrder: sortedInfo.columnKey === 'CreationTime' && sortedInfo.order,
      }, {
        title: LAN.actionTitle,
        key: 'action',
        width: 140,
        render: (value, record) => {
          if (record.module === moduleEnum.GEYL) {
            // success
            if (record.status === 2) {
              return (
                <span>
                  <Button
                    type="link"
                    icon={<DownloadIcon lan={lan} />}
                    onClick={() => { this.onDownload(record, record.category, lan); }}
                    title={LAN.reportLink}
                  />
                  <Button 
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => { this.onEdit(record); }}
                    title={LAN.editLink}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    title={LAN.deleteBtn}
                    onClick={() => { this.onDelete(record); }}
                  />
                </span>
              );
            } 
            // previewing
            if (record.status === 6) {
              return (
                <span>
                  <Button
                    type="link"
                    icon={<PreviewIcon />}
                    onClick={() => { this.onPreview(record); }}
                    title={LAN.previewLink}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    title={LAN.deleteBtn}
                    onClick={() => { this.onDelete(record); }}
                  />
                </span>
              );
            }
            // previewed
            if (record.status === 8) {
              return (
                <span>
                  <Button
                    type="link"
                    icon={<CertificateIcon />}
                    onClick={() => { this.onPreview(record); }}
                    title={LAN.previewedLink}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    title={LAN.deleteBtn}
                    onClick={() => { this.onDelete(record); }}
                  />
                </span>
              );
            }
            // modifying
            if (record.status === 7) {
              return (                
                <Button
                  type="link"
                  disabled
                  icon={<LoadingOutlined />}                  
                  title={LAN.modifyingLink}
              />
              );
            }
          }
          if ((record.module === moduleEnum.ICB || record.module === moduleEnum.ICP || record.module === moduleEnum.SRE) && lan === 'zh') {
            return (
              <span>
                <Button
                  type="link"
                  icon={<DownloadIcon lan="zh" />}
                  onClick={() => { this.onDownload(record, record.category, lan); }}
                  title="下载简体中文版报告"
                />
                <Button
                  type="link"
                  icon={<DownloadIcon lan="tr" />}
                  onClick={() => { this.onDownload(record, record.category, 'tr'); }}
                  title="下載繁體中文版報告"
                />
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  title={LAN.deleteBtn}
                  onClick={() => { this.onDelete(record); }}
            />
              </span>
            );
          }
          return (
            <span>
              <Button
                type="link"
                icon={<DownloadIcon lan={lan} />}
                onClick={() => { this.onDownload(record, record.category, lan); }}
                title={LAN.reportLink}
            />
              <Button
                type="link"
                icon={<DeleteOutlined />}
                title={LAN.deleteBtn}
                onClick={() => { this.onDelete(record); }}
            />
            </span>
          );
        },
      },
    ];

    // 为部分模块优化
    const categories = Object.keys(modules || {});
    if (categories.length === 1 && categories[0] === '600') {
      columns.splice(3, 3);
    } else if (categories.every((v) => v !== '200')) {
      columns.splice(4, 1);
    }
    
    return (
      <SCard
        title={LAN.reportCardTitle}
        extra={(
          <Search
            placeholder={LAN.reportSearchPlaceholder}
            onSearch={this.handleSearch}
            enterButton
            allowClear
          />
        )}
      >
        <Table
          columns={columns}
          dataSource={reportList}
          pagination={{ pageSize: 20 }}
          scroll={{ x: 1050 }}
        />
        <EditPwdModal 
          visible={editModalVisible} 
          onClose={() => { this.setState({ editModalVisible: false }); }} 
          onOpenEditSummaryDrawer={() => { this.setState({ editDrawerVisible: true }); }} 
        />
        <EditSummaryDrawer 
          visible={editDrawerVisible}
          onClose={() => { this.setState({ editDrawerVisible: false }); }} 
          editRecord={editRecord}
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
  username: state.username,
  modules: state.modules,
});

export default connect(mapStateToProps)(ReportCard);
