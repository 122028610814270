import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Tag, Card, DatePicker, Space } from 'antd';
import styled from 'styled-components';
import axios from '../../../helpers/agentAxios';
import showError from '../../../helpers/errorlist';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 105em;
  max-width: 100vw;
`;

const STableFooter = styled.div`
  text-align: center;
`;

class Userlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userlist: [],
      totalCount: 0,
      queryCount: 0,
    };
  }

  componentDidMount() {
    this.loadUserList();
  }

  loadUserList = async () => {
    try {
      const { queryStart, queryEnd } = this.state;
      const res = await axios.get(`/agent-auth/users?queryStart=${queryStart}&queryEnd=${queryEnd}`);
      const { userlist, totalCount, queryCount } = res.data;
      this.setState({ userlist, totalCount, queryCount });
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  handleDatePickerChange = (time, timestring) => {
    if (timestring && timestring[0] !== '' && timestring[1] !== '') {
      this.setState({ queryStart: timestring[0], queryEnd: timestring[1] });
    } else {
      this.setState({ queryStart: undefined, queryEnd: undefined });
    }
  }

  render() {
    const LAN = LANG[this.props.lan];
    const columns = [
      {
        title: LAN.username,
        dataIndex: 'username',
        key: 'username',
        sorter: (a, b) => (a.username.toUpperCase() >= b.username.toUpperCase() ? 1 : -1),
      },
      {
        title: LAN.status,
        dataIndex: 'status',
        key: 'status',
        filters: [
          { text: LAN.status_0, value: '0' },
          { text: LAN.status_1, value: '1' },
        ],
        onFilter: (value, record) => record.status.toString() === value,
        render: (value) => {
          switch (value) {
            case 0: return <Tag color="volcano" key="status0">{LAN.status_0}</Tag>;
            case 1: return <Tag color="green" key="status1">{LAN.status_1}</Tag>;
            default: return <Tag color="purple" key="status-1">Error</Tag>;
          }
        },
      },
      {
        title: LAN.phone,
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: LAN.email,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: LAN.organization,
        dataIndex: 'organization',
        key: 'organization',
        sorter: (a, b) => (a.organization.toUpperCase() >= b.organization.toUpperCase() ? 1 : -1),
      },
      {
        title: LAN.regTime,
        dataIndex: 'regTime',
        key: 'regTime',
        sorter: (a, b) => (a.regTime > b.regTime ? 1 : -1),
      },
      {
        title: LAN.recentTime,
        dataIndex: 'recentTime',
        key: 'recentTime',
        sorter: (a, b) => {
          if (a.recentTime && b.recentTime) return a.recentTime > b.recentTime ? 1 : -1;
          return a.recentTime ? 1 : -1;
        },
      },
      {
        title: LAN.taskAmt,
        dataIndex: 'taskAmt',
        key: 'taskAmt',
        sorter: (a, b) => {
          if (a.taskAmt && b.taskAmt) return a.taskAmt - b.taskAmt;
          return a.taskAmt ? 1 : -1;
        },
        render: (value) => value || 0,
      },
      {
        title: LAN.queryColumn,
        dataIndex: 'queryAmt',
        key: 'queryAmt',
        sorter: (a, b) => {
          if (a.queryAmt && b.queryAmt) return a.queryAmt - b.queryAmt;
          return a.queryAmt ? 1 : -1;
        },
        render: (value) => value || 0,
      },
      {
        title: LAN.module,
        dataIndex: 'module',
        key: 'module',
        render: (value) => {
          let t2 = false;
          let t3 = false;
          let t4 = false;
          let t5 = false;
          for (const mod of value) {
            if (mod > 500) {
              t5 = true;
            } else if (mod > 400) {
              t4 = true;
            } else if (mod > 300) {
              t3 = true;
            } else if (mod > 200) {
              t2 = true;
            }
          }
          return (
            <span>
              <Tag color="#f50" visible={t2}>DG</Tag>
              <Tag color="#87d068" visible={t3}>MC</Tag>
              <Tag color="#108ee9" visible={t4}>IC</Tag>
              <Tag color="#8A2BE2" visible={t5}>MS</Tag>
            </span>
          );
        },
      },
      // {
      //   title: LAN.action,
      //   dataIndex: 'action',
      //   key: 'action',
      //   render: (value, record) => (
      //     <span>
      //       <IconBtn icon={<ProfileOutlined />} title={LAN.detailLinkTitle} onClick={this.handleCheckProfile.bind(this, record.username)} />
      //       <IconBtn icon={<EditOutlined />} title={LAN.editLinkTitle} onClick={this.handleEditProfile.bind(this, record.username)} />
      //     </span>
      //   ),
      // },
    ];
    const { userlist, queryCount, totalCount } = this.state;
    return (
      <SCard
        title={LAN.cardTitle}
        extra={(
          <Space>
            <DatePicker.RangePicker onChange={this.handleDatePickerChange} />
            <Button type="primary" onClick={this.loadUserList}>{LAN.queryBtn}</Button>
          </Space>
        )}
      >
        <Table
          columns={columns}
          size="middle"
          dataSource={userlist}
          onChange={this.handleChange}
          pagination={{ pageSize: 30 }}
          scroll={{ x: 1050 }}
          footer={() => (
            <STableFooter>
              <span>{LAN.totalCount}： {totalCount}</span>&emsp;&emsp;&emsp;
              <span>{LAN.queryCount}： {queryCount}</span>
            </STableFooter>
          )}
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(Userlist);

const LANG = {
  zh: {
    cardTitle: '用户列表',
    username: '用户名',
    status: '用户状态',
    status_0: '失效',
    status_1: '正常',
    level: '用户级别',
    phone: '电话',
    email: '电子邮箱',
    organization: '机构',
    agent: '代理',
    regTime: '注册时间',
    recentTime: '最近活动时间',
    taskAmt: '总完成任务数',
    module: '功能模块',
    totalCount: '累计总完成任务数',
    queryCount: '累计查询周期完成任务数',
    queryBtn: '查询',
    queryColumn: '查询周期任务数',    
  },
  en: {
    cardTitle: 'Userlist',
    username: 'Username',
    status: 'Status',
    level: 'Rank',
    status_0: 'Invalid',
    status_1: 'Valid',
    phone: 'Telephone',
    email: 'Email',
    organization: 'Organization',
    agent: 'Agent',
    regTime: 'Regtime',
    recentTime: 'Recent Active Time',
    taskAmt: 'Total Tasks',
    module: 'Modules',
    totalCount: 'Total Finished Tasks',
    queryCount: 'Total Query Tasks',
    queryBtn: 'Query',
    queryColumn: 'Query Tasks',
  },
};
