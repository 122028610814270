import React from 'react';
import PropTypes from 'prop-types';
import UploadFormItem from './UploadFormItem';

const SvdFormItems = (props) => (
  [
    <UploadFormItem
      labelIndex="fileT22dLabel"
      isRequired
      field="fileT22d"
      filename="2dt2.zip"
      key="t22d"
      disabled={props.disabled}
    />,
    <UploadFormItem
      labelIndex="fileSwiLabel"
      isRequired
      field="fileSwi"
      filename="swi.zip"
      key="swi"
      disabled={props.disabled}
    />,
    <UploadFormItem
      labelIndex="fileDwiLabel"
      field="fileDwi"
      filename="dwi.zip"
      key="dwi"
      disabled={props.disabled}
    />,
  ]

);

SvdFormItems.propTypes = {
  disabled: PropTypes.bool,
};

export default SvdFormItems;
