import React from 'react';
import { connect } from 'react-redux';
import { Descriptions, Tree, Tag } from 'antd';
import { admin as LANG } from '../../helpers/lan';
import axios from '../../helpers/adminAxios';
import { moduleList, categoryList } from '../../helpers/modules';
import showError from '../../helpers/errorlist';

class PersonalDescriptions extends React.Component {
  state = {
    username: this.props.username,
    status: null,
    level: null,
    phone: null,
    email: null,
    organization: null,
    regTime: null,
    module: [],
  }

  componentDidMount = async () => {
    this.loadData();
  }

  loadData = async () => {
    try {
      if (!this.state.username) { return; }
      const res = await axios.get(`/admin-auth/user-profiles/${this.state.username}`);
      const { user } = res.data;
      this.setState({ ...user });
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  render() {
    const { lan } = this.props;
    const { status, level, phone, email, organization, regTime, module } = this.state;
    const LAN = LANG[lan];

    const moduleObj = {};
    for (const mod of module) {
      if (moduleObj[moduleList[mod].group] === undefined) { moduleObj[moduleList[mod].group] = []; }
      moduleObj[moduleList[mod].group].push(mod);
    }

    const mData = Object.keys(moduleObj).map((v) => ({
      title: categoryList[v][lan],
      key: v,
      children: moduleObj[v].map((m) => ({
        title: moduleList[m][lan],
        key: m,
      })),
    }));
    const treeData = [{
      title: 'AccuBrain',
      key: '0',
      children: mData,
    }];

    let StatusTag; 
    switch (status) {
      case 0: StatusTag = <Tag color="volcano">{LAN.status_0}</Tag>; break;
      case 1: StatusTag = <Tag color="green">{LAN.status_1}</Tag>; break;
      default: StatusTag = <Tag color="purple">ERROR</Tag>;
    }
    let LevelTag;
    switch (level) {
      case 0: LevelTag = <Tag color="gold">Basic</Tag>; break;
      case 1: LevelTag = <Tag color="magenta">Pro</Tag>; break;
      default: LevelTag = <Tag color="purple">ERROR</Tag>;
    }

    return (
      <Descriptions title={LAN.personalTitle} column={2}>
        <Descriptions.Item label={LAN.status}>{StatusTag}</Descriptions.Item>
        <Descriptions.Item label={LAN.level}>{LevelTag}</Descriptions.Item>
        <Descriptions.Item label={LAN.regTime}>{regTime}</Descriptions.Item>
        <Descriptions.Item label={LAN.phone}>{phone}</Descriptions.Item>
        <Descriptions.Item label={LAN.email}>{email}</Descriptions.Item>
        <Descriptions.Item label={LAN.organization} span={2}>{organization}</Descriptions.Item>
        <Descriptions.Item label={LAN.module}>
          <Tree
            showLine
            expandedKeys={['0', '200', '300', '400', '500', '600', '700']}
            treeData={treeData}
          />
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(PersonalDescriptions);
