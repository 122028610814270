/* eslint-disable react/no-danger */
import React from 'react';
import { PageHeader } from 'antd';
import styled from 'styled-components';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';
import { getUrlParams } from '../../helpers/tool';

const PdfViewer = React.lazy(() => import('../UI/PdfViewer'));
const SDiv = styled.div`
  background-color: white;
  margin: 5em auto;
  width: 80em;
  max-width: 100vw;
`;

class NewsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      content: '',
      time: '',
    };
  }

  componentDidMount = async () => {
    const { lan } = this.props;
    try {
      const { search } = this.props.history.location;
      const id = getUrlParams(search, 'id');
      const res = await axios.get(`/news-contents/${id}`);
      const { title, content, time } = res.data;
      this.setState({ title, content, time });
    } catch (error) {
      showError(error, lan);
    }
  }

  render() {
    const { title, content, time } = this.state;
    return (
      <SDiv>
        <PageHeader
          onBack={() => window.history.back()}
          title={title}
          extra={time}
        >
          <React.Suspense fallback={<div>Loading...</div>}>
            <PdfViewer url={content} />
          </React.Suspense>
        </PageHeader>
      </SDiv>
    );
  }
}

export default NewsContent;
