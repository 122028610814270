import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SDiv = styled.div`
  width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  padding: 1em;
  img {
    width: 10em;
  }

  h4 {
    margin-top: 1.5em;
    font-size: 1.4em;
    font-weight: bolder;
    text-shadow: 1px 1px 1px gray;    
    text-align: center;
  }

  span {
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    color: DimGray;
  }
`;

const DisplayCard = ({ img, title, content }) => (
  <SDiv>
    <img src={img} alt={title} />
    <h4>{title}</h4>
    <span>{content}</span>
  </SDiv>
);

DisplayCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node,
};

export default DisplayCard;
