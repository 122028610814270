import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Input, Button, message, Modal } from 'antd';
import { LoginOutlined, ReloadOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { pbkdf2Sync } from 'crypto';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';
import { actions } from '../../redux/index';
import { signIn as LANG } from '../../helpers/lan';
import { setItem } from '../../helpers/storage';
import { moduleList } from '../../helpers/modules';
import ForgotModal from '../UI/ForgotModal';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 38em;
  max-width: 100vw;
`;

class SignInCard extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    if (window.ActiveXObject) {
      // eslint-disable-next-line no-alert
      alert(LAN.ie10Warning);
      return;
    }
    if ('ActiveXObject' in window) {
      message.warning(LAN.ie11Warning, 10);
    }
  }

  handleSubmit = async (values) => {
    const { lan, onLogin, history } = this.props;
    const LAN = LANG[lan];
    try {
      const { password } = values;
      const salt = 'brainnowyes';
      values.password = pbkdf2Sync(password, salt, 1000, 32, 'sha512').toString('hex');
      const res = await axios.post('/sessions', values);
      const { username, module, defaultModuleConfig, token, status, level, isUnreadNews, expiryDate } = res.data;
      const moduleGroups = {};

      for (const md of module) {
        if (moduleGroups[moduleList[md].group] === undefined) {
          moduleGroups[moduleList[md].group] = [];
        }
        moduleGroups[moduleList[md].group].push(md);
      }
      setItem('act', token);
      setItem('loginType', 'user');
      setItem('username', username);
      // setItem('userStatus', status);
      // setItem('modules', JSON.stringify(moduleGroups));
      // setItem('defaultModuleConfig', defaultModuleConfig);
      // setItem('userLevel', level);
      // setItem('isUnreadNews', isUnreadNews);
      // setItem('expiryDate', expiryDate);
      onLogin({
        username,
        loginType: 'user',
        userStatus: status,
        userLevel: level,
        isUnreadNews,
        expiryDate,
        modules: moduleGroups,
        defaultModuleConfig,        
      });
      if (status === 1) {
        history.push('/task');
      } else {
        Modal.success({
          title: LAN.agreementModalTitle,
          content: LAN.agreementModalContent,
          onOk: () => { history.push('/agreement'); },
        });
      }
    } catch (error) {
      showError(error, lan);
    }
  }

  handleReset = () => {
    this.formRef.current.resetFields();
  }

  handleShowModal = () => {
    this.setState({ visible: true });
  }

  handleCloseModal = () => {
    this.setState({ visible: false });
  }

  render() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
        sm: { sm: 6 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { sm: 16 },
      },
    };
    const btnItemLayout = {
      wrapperCol: {
        xs: {
          span: 18,
          offset: 6,
        },
      },
    };
    return (
      <SCard title={LAN.loginTitle}>
        <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef} name="sign-in">
          <Form.Item
            label={LAN.username}
            name="uid"
            rules={[
              {
                required: true,
                message: LAN.usernameMsg,
              },
            ]}
          >
            <Input placeholder="UID/Email" />
          </Form.Item>
          <Form.Item
            label={LAN.password}
            name="password"
            rules={[
              {
                required: true,
                message: LAN.passwordMsg,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...btnItemLayout}>
            <Button type="primary" icon={<LoginOutlined />} htmlType="submit">{LAN.login}</Button>
            &emsp;
            <Button type="default" icon={<ReloadOutlined />} htmlType="reset" onClick={this.handleReset}>{LAN.reset}</Button>
            &emsp;
            <Button type="link" onClick={this.handleShowModal}>{LAN.forgotLink}</Button>
          </Form.Item>
        </Form>
        <ForgotModal
          visible={this.state.visible}
          onClose={this.handleCloseModal}
          url="/forgot-password-emails"
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (obj) => { dispatch(actions.signIn(obj)); },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInCard));
