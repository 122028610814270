import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import styled from 'styled-components';
import LanMenuItem from './LanMenuItem';

const MenuItem = styled(Menu.Item)`
  line-height: 5.9em;
`;

class AgentLoginMenu extends React.Component {
  render() {
    return (
      <Menu
        mode="horizontal"
        selectedKeys={[this.props.history.location.pathname]}        
      >
        <MenuItem key="lan"><LanMenuItem /></MenuItem>
      </Menu>
    );
  }
}

export default withRouter(AgentLoginMenu);
