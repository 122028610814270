import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import p1 from '../../../static/advantageImgs/1-quick.png';
import p2 from '../../../static/advantageImgs/2-accurate.png';
import p3 from '../../../static/advantageImgs/3-reliable.png';
import p4 from '../../../static/advantageImgs/4-risk.png';
import p5 from '../../../static/advantageImgs/5-database.png';
import p6 from '../../../static/advantageImgs/6-qualification.png';
import DisplayCard from './DisplayCard';
import Publications from './Publications';

const SSection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;    
    align-items: center;

  h1 {
    text-align: center;
    color: #3C658B;
    font-weight: bolder;
  }

  .cardDiv{
    max-width: 60em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const lanObj = {
  zh: {
    h1: '产品优势',
    titles: [
      '快速',
      '准确',
      '可靠',
      '疾病风险量化评估',
      '大规模华人脑影像资料库',
      '临床验证及资质',
    ],
    contents: [
      '单个数据处理时间快达10分钟',
      <>优异的脑分割<sup>1-2</sup>与鉴别诊断<sup>3-9</sup>准确度</>,
      <>高度可重复性<sup>10,11</sup></>,
      <>经科研验证的各种脑部疾病风险量化评估<sup>5-9</sup></>,
      '正常同年龄人群对照辅助参照',
      '获得中国、美国、欧盟医疗器械认证；获得高新技术企业认定；专利脑叶萎缩指数',
    ],
  },
  en: {
    h1: 'Advantages',
    titles: [
      'Quick',
      'Accurate',
      'Reliable',
      'Disease Risk Index',
      'Large-Scale Chinese Database',
      'Validation & Qualification',
    ],
    contents: [
      'As quick as 10 minutes per data',
      <>Excellent segmentation<sup>1-2</sup> and diagnostic<sup>3-9</sup> accuracy</>,
      <>High intrascanner and interscanner reproducibility<sup>10,11</sup></>,
      <>Proven risk indices of different neurodegenerative diseases<sup>5-9</sup></>,
      'Age-specific normal control as reference',
      'Obtained certification of medical devices in China, the US and the EU. Certified as National High-tech Enterprise. Patented brain atrophy quantification',
    ],
  },
};

const imgs = [p1, p2, p3, p4, p5, p6];

const AdvantageSection = ({ lan }) => (
  <SSection>
    <h1>{lanObj[lan].h1}</h1>
    <div className="cardDiv">
      {imgs.map((v, i) => <DisplayCard img={v} title={lanObj[lan].titles[i]} content={lanObj[lan].contents[i]} key={v} />)}
    </div>
    <Publications />
  </SSection>
);

AdvantageSection.propTypes = {
  lan: PropTypes.string,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(AdvantageSection);
