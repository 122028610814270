import React from 'react';
import PropTypes from 'prop-types';
import UploadFormItem from './UploadFormItem';

const GpFormItems = (props) => (
  <UploadFormItem
    labelIndex="fileT22dLabel"
    isRequired
    field="fileT22d"
    filename="2dt2.zip"
    disabled={props.disabled}
  />
); 

GpFormItems.propTypes = {
  disabled: PropTypes.bool,
};

export default GpFormItems;
