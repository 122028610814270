import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Tag, Card, Modal, message } from 'antd';
import { SyncOutlined, ProfileOutlined, EditOutlined, CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ProfileDrawer from './ProfileDrawer';
import EditDrawer from './EditDrawer';
import { admin as LANG } from '../../helpers/lan';
import axios from '../../helpers/adminAxios';
import showError from '../../helpers/errorlist';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 140em;
  max-width: 100vw;
`;

const IconBtn = styled(Button).attrs({ type: 'link' })`
  width: 1em;
  margin-right: 1em;
`;

class Userlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userlist: [],
      currentUserForProfile: null,
      currentUserForEdit: null,
      profileVisible: false,
      editVisible: false,
    };
  }

  componentDidMount() {
    this.loadUserList();
  }

  loadUserList = async () => {
    try {
      const res = await axios.get('/admin-auth/users');
      const { userlist } = res.data;
      this.setState({ userlist });
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  handleCheckProfile = (user) => {
    this.setState({ profileVisible: true, currentUserForProfile: user });
  }

  handleCloseProfile = () => {
    this.setState({ profileVisible: false, currentUserForProfile: null });
  }

  handleEditProfile = (user) => {
    this.setState({ editVisible: true, currentUserForEdit: user });
  }

  handleCertificate = async (username) => {
    Modal.confirm({
      content: LANG[this.props.lan].certificateLinkTitle,
      onOk: async () => {
        try {
          const res = await axios.patch(`/admin-auth/users/${username}`);
          if (res?.data?.message === 'success') {
            message.success(LANG[this.props.lan].successMsg);
            this.loadUserList();
          }
        } catch (error) {
          showError(error, this.props.lan);
        }
      },
    });
  }  

  handleCloseEditProfile = () => {
    this.setState({ editVisible: false, currentUserForEdit: null });
  }

  render() {
    const LAN = LANG[this.props.lan];
    const columns = [
      {
        title: LAN.username,
        dataIndex: 'username',
        key: 'username',
        sorter: (a, b) => (a.username.toUpperCase() >= b.username.toUpperCase() ? 1 : -1),
      },
      {
        title: LAN.status,
        dataIndex: 'status',
        key: 'status',
        filters: [
          { text: LAN.status_0, value: '0' },
          { text: LAN.status_1, value: '1' },
        ],
        onFilter: (value, record) => record.status.toString() === value,
        render: (value) => {
          switch (value) {
            case 0: return <Tag color="volcano" key="status0">{LAN.status_0}</Tag>;
            case 1: return <Tag color="green" key="status1">{LAN.status_1}</Tag>;
            case 2: return <Tag color="gray" key="status2">{LAN.status_2}</Tag>;
            default: return <Tag color="purple" key="status-1">Error</Tag>;
          }
        },
      },
      {
        title: LAN.level,
        dataIndex: 'level',
        key: 'level',
        filters: [
          { text: 'Basic', value: '0' },
          { text: 'Pro', value: '1' },
        ],
        onFilter: (value, record) => record.level.toString() === value,
        render: (value) => {
          switch (value) {
            case 0: return <Tag color="gold" key="level0">Basic</Tag>;
            case 1: return <Tag color="magenta" key="level1">Pro</Tag>;
            default: return <Tag color="purple" key="level-1">Error</Tag>;
          }
        },
      },
      {
        title: LAN.phone,
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: LAN.email,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: LAN.organization,
        dataIndex: 'organization',
        key: 'organization',
        sorter: (a, b) => (a.organization.toUpperCase() >= b.organization.toUpperCase() ? 1 : -1),
      },
      {
        title: LAN.agent,
        dataIndex: 'agent',
        key: 'agent',
        sorter: (a, b) => {
          if (a.agent && b.agent) return a.agent.toUpperCase() > b.agent.toUpperCase() ? 1 : -1;
          return a.agent ? 1 : -1;
        },
      },
      {
        title: LAN.region,
        dataIndex: 'region',
        key: 'region',
        filters: [
          { text: LAN.southchina, value: 'southchina' },
          { text: LAN.northchina, value: 'northchina' },
          { text: LAN.overseas, value: 'overseas' },
          { text: LAN.dev, value: 'dev' },
          { text: LAN.beta, value: 'beta' },
        ],
        onFilter: (value, record) => record.region.toString() === value,
        render: (value) => LAN[value],
      },
      {
        title: LAN.regTime,
        dataIndex: 'regTime',
        key: 'regTime',
        sorter: (a, b) => (a.regTime > b.regTime ? 1 : -1),
      },
      {
        title: LAN.expiryDate,
        dataIndex: 'expiryDate',
        key: 'expiryDate',
        sorter: (a, b) => (a.expiryDate > b.expiryDate ? 1 : -1),
      },
      {
        title: LAN.module,
        dataIndex: 'module',
        key: 'module',
        render: (value) => {
          let t2 = false;
          let t3 = false;
          let t4 = false;
          let t5 = false;
          let t6 = false;
          let t7 = false;
          for (const mod of value) {
            if (mod > 700) {
              t7 = true;
            } else if (mod > 600) {
              t6 = true;
            } else if (mod > 500) {
              t5 = true;
            } else if (mod > 400) {
              t4 = true;
            } else if (mod > 300) {
              t3 = true;
            } else if (mod > 200) {
              t2 = true;
            }
          }
          return (
            <span>
              <Tag color="#f50" visible={t2}>DG</Tag>
              <Tag color="#87d068" visible={t3}>MC</Tag>
              <Tag color="#108ee9" visible={t4}>IC</Tag>
              <Tag color="#8A2BE2" visible={t5}>MS</Tag>
              <Tag color="#e100ff" visible={t6}>GE</Tag>
              <Tag color="#792e16" visible={t7}>SB</Tag>
            </span>
          );
        },
      },
      {
        title: LAN.action,
        dataIndex: 'action',
        key: 'action',
        render: (value, record) => (
          <span>
            <IconBtn icon={<ProfileOutlined />} title={LAN.detailLinkTitle} onClick={this.handleCheckProfile.bind(this, record.username)} />
            <IconBtn icon={<EditOutlined />} title={LAN.editLinkTitle} onClick={this.handleEditProfile.bind(this, record.username)} />
            <IconBtn icon={<CheckCircleOutlined />} title={LAN.certificateLinkTitle} disabled={record.status !== 2} onClick={this.handleCertificate.bind(this, record.username)} />
          </span>
        ),
      },
    ];
    return (
      <SCard
        title={LAN.cardTitle}
        extra={
          <Button icon={<SyncOutlined />} onClick={() => { this.loadUserList(); }} />
        }
      >
        <Table
          columns={columns}
          size="middle"
          dataSource={this.state.userlist}
          onChange={this.handleChange}
          pagination={{ pageSize: 30 }}
          scroll={{ x: 1050 }}
        />
        <ProfileDrawer
          visible={this.state.profileVisible}
          onClose={this.handleCloseProfile}
          username={this.state.currentUserForProfile}
        />
        <EditDrawer
          visible={this.state.editVisible}
          onClose={this.handleCloseEditProfile}
          username={this.state.currentUserForEdit}
          onReload={this.loadUserList}
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(Userlist);
