import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Drawer, PageHeader } from 'antd';
import styled from 'styled-components';
import EditForm from './EditForm';
import { admin as LANG } from '../../helpers/lan';
import header from '../../../static/avatar.jpeg';

const TitleP = styled.p`
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
`;

class EditDrawer extends React.Component {
  render() {
    const { visible, username, onClose, onReload, lan } = this.props;
    const LAN = LANG[lan];
    return (
      <Drawer
        width={600}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        <PageHeader
          title={username}
          avatar={{ src: header }}
          onBack={onClose}
        >
          <br />
          <TitleP>{LAN.editFormTitle}</TitleP>
          <EditForm username={username} key={`ed-${username}`} onReload={onReload} onClose={onClose} />
        </PageHeader>
      </Drawer>
    );
  }
}

EditDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(EditDrawer);
