import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import WorkMenu from '../components/NavBar/WorkMenu';
import LoginMenu from '../components/NavBar/LoginMenu';
import AdminMenu from '../components/NavBar/AdminMenu';
import AdminLoginMenu from '../components/NavBar/AdminLoginMenu';
import AddUserCard from '../components/AdminAddUser/AddUserCard';
import SignInCard from '../components/SignIn/SignInCard';
import AboutAccubrain from '../components/AboutAccubrain';
import Profile from '../components/Profile/Profile';
import UploadCard from '../components/UploadCard/UploadCard';
import TaskCard from '../components/TaskCard';
import ReportCard from '../components/ReportCard/ReportCard';
import Home from './Home';
import AdminSignInCard from '../components/AdminSignInCard';
import AdminProfileCard from '../components/AdminProfile/ProfileCard';
import AdminUserlistCard from '../components/AdminUserlist/AdminUserlistCard';
import ResetPasswordCard from '../components/SignIn/ResetPasswordCard';
import UnsubscribeCard from '../components/UnsubscribeCard';
import AdminSendEmailPage from '../components/AdminSendEmail/AdminSendEmailPage';
import Agreement from '../components/Agreement';
import News from '../components/News/News';
import NewsContent from '../components/News/NewsContent';
import AdminTasklistCard from '../components/AdminTasklistCard';
import AdminAgentlistCard from '../components/AdminAgentlist/AdminAgentlistCard';
import AgentSignInCard from '../components/Agent/SignInCard';
import AgentLoginMenu from '../components/NavBar/AgentLoginMenu';
import AgentMenu from '../components/NavBar/AgentMenu';
import AgentProfile from '../components/Agent/Profile/ProfileCard';
import AgentUserlistCard from '../components/Agent/Userlist/UserlistCard';
import AgentUnsubscribeCard from '../components/Agent/UnsubscribeCard';
import AgentResetPasswordCard from '../components/Agent/ResetPasswordCard';
import Page404 from '../components/UI/Page404';
import Preview from '../components/Preview/Preview';

const SubRouteComp = ({ routes }) => (
  <Switch>
    {routes.map((route) => (
      <Route key={route.path} {...route} />
    ))}
  </Switch>
);

SubRouteComp.propTypes = {
  routes: PropTypes.array,
};

export const navRouters = [
  {
    path: '/',
    exact: true,
    component: LoginMenu,
  },
  {
    path: '/about-accubrain',
    exact: true,
    component: LoginMenu,
  },
  {
    path: '/sign-in',
    exact: true,
    component: LoginMenu,
  },
  {
    path: '/profile',
    exact: true,
    component: WorkMenu,
  },
  {
    path: '/upload',
    exact: true,
    component: WorkMenu,
  },
  {
    path: '/task',
    exact: true,
    component: WorkMenu,
  },
  {
    path: '/report',
    exact: true,
    component: WorkMenu,
  },
  {
    path: '/preview',
    exact: true,
    component: WorkMenu,
  },
  {
    path: '/agreement',
    exact: true,
    component: WorkMenu,
  },
  {
    path: '/news',
    exact: true,
    component: WorkMenu,
  },
  {
    path: '/news-content',
    exact: true,
    component: WorkMenu,
  },
  {
    path: '/admin',
    exact: false,
    component: SubRouteComp,
    routes: [
      {
        path: '/admin/sign-in',
        exact: true,
        component: AdminLoginMenu,
      },
      {
        path: '/admin',
        exact: false,
        component: AdminMenu,
      },
    ],
  },
  {
    path: '/agent',
    exact: false,
    component: SubRouteComp,
    routes: [
      {
        path: '/agent/sign-in',
        exact: true,
        component: AgentLoginMenu,
      },
      {
        path: '/agent/reset-password',
        exact: true,
        component: null,
      },
      {
        path: '/agent/unsubscribe',
        exact: true,
        component: null,
      },
      {
        path: '/agent',
        exact: false,
        component: AgentMenu,
      },
    ],
  },

];

export const conRouters = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/about-accubrain',
    exact: true,
    component: AboutAccubrain,
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPasswordCard,
  },
  {
    path: '/unsubscribe',
    exact: true,
    component: UnsubscribeCard,
  },
  {
    path: '/sign-in',
    exact: true,
    component: SignInCard,
  },
  {
    path: '/profile',
    exact: true,
    component: Profile,
  },
  {
    path: '/agreement',
    exact: true,
    component: Agreement,
  },
  {
    path: '/upload',
    exact: true,
    component: UploadCard,
  },
  {
    path: '/task',
    exact: true,
    component: TaskCard,
  },
  {
    path: '/report',
    exact: true,
    component: ReportCard,
  },
  {
    path: '/preview',
    exact: true,
    component: Preview,
  },
  {
    path: '/news',
    exact: true,
    component: News,
  },
  {
    path: '/news-content',
    exact: true,
    component: NewsContent,
  },
  {
    path: '/admin',
    exact: false,
    component: SubRouteComp,
    routes: [
      {
        path: '/admin/sign-in',
        exact: true,
        component: AdminSignInCard,
      },
      {
        path: '/admin/userlist',
        exact: true,
        component: AdminUserlistCard,
      },
      {
        path: '/admin/agentlist',
        exact: true,
        component: AdminAgentlistCard,
      },
      {
        path: '/admin/tasklist',
        exact: true,
        component: AdminTasklistCard,
      },
      {
        path: '/admin/profile',
        exact: true,
        component: AdminProfileCard,
      },
      {
        path: '/admin/add-user',
        exact: true,
        component: AddUserCard,
      },
      {
        path: '/admin/send-email',
        exact: true,
        component: AdminSendEmailPage,
      },
      {
        path: '/admin',
        exact: false,
        component: Page404,
      },
    ],
  },
  {
    path: '/agent',
    exact: false,
    component: SubRouteComp,
    routes: [
      {
        path: '/agent/sign-in',
        exact: true,
        component: AgentSignInCard,
      },
      {
        path: '/agent/userlist',
        exact: true,
        component: AgentUserlistCard,
      },
      {
        path: '/agent/profile',
        exact: true,
        component: AgentProfile,
      },
      {
        path: '/agent/unsubscribe',
        exact: true,
        component: AgentUnsubscribeCard,
      },
      {
        path: '/agent/reset-password',
        exact: true,
        component: AgentResetPasswordCard,
      },
      {
        path: '/agent',
        exact: false,
        component: Page404,
      },
    ],
  },
  {
    path: '/',
    exact: false,
    component: Page404,
  },
];
