import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Input, Button, message, Select } from 'antd';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';
import { getUrlParams } from '../../helpers/tool';
import template from './template';

const { TextArea } = Input;

const BtnDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

class AddSummaryForm extends React.Component {
  formRef = React.createRef();

  state = {
    status: '6',
  }

  componentDidMount = async () => {
    const { lan } = this.props;
    try {
      const { search } = this.props.history.location;
      const number = getUrlParams(search, 'number');
      const status = getUrlParams(search, 'status');
      const res = await axios.get(`/auth/task-summarys?number=${number}`);
      const { data } = res;
      this.formRef.current.setFieldsValue({ ...data });
      this.setState({ status });
    } catch (error) {
      showError(error, lan);
    }
  }

  handleSubmit = async (values) => {   
    const { number, lan, history } = this.props;
    const LAN = LANG[lan];    

    try {
      await axios.patch('/auth/task-summarys', {
        number,
        ...values,
      });
      message.success(LAN.submitSuccess);
      history.push('/report');
    } catch (error) {
      showError(error, lan);
    }
  }

  handleReset = () => {
    this.formRef.current.resetFields();
  }

  handleSave = async () => {
    const values = this.formRef.current.getFieldsValue(['imageview', 'diagnosis', 'viewer', 'verifier']);
    const { number, lan, history } = this.props;
    const LAN = LANG[lan];
    try {
      await axios.patch('/auth/temp-task-summarys', {
        number,
        ...values,
      });
      message.success(LAN.saveSuccess);
      history.push('/report');
    } catch (error) {
      showError(error, lan);
    }
  }

  handleImportTemplate = (value) => {
    this.formRef.current.setFieldsValue({ diagnosis: template[value].diagnosis, imageview: template[value].imageview });
  }

  render() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    
    return (
      <Form
        layout="vertical"
        name="add-summary"
        ref={this.formRef} 
        onFinish={this.handleSubmit}
      >
        <Form.Item
          label={LAN.template}
        >
          <Select
            options={template.map((tp, index) => ({
              value: index.toString(),
              label: tp.title,
            }))}
            onChange={this.handleImportTemplate}
          />
        </Form.Item>
        <Form.Item
          label={LAN.imageview}
          name="imageview"
          rules={[{ required: true }]}
        >
          <TextArea rows={12} />
        </Form.Item>
        <Form.Item
          label={LAN.diagnosis}
          name="diagnosis"
          rules={[{ required: true }]}
        >
          <TextArea rows={12} />
        </Form.Item>
        <Form.Item
          label={LAN.viewer}
          name="viewer"
          rules={[{ required: true }]}
        >
          <Select
            options={[
              { value: 'hesen', label: '贺森' },
              { value: 'mayuanjiao', label: '马媛娇' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={LAN.verifier}
          name="verifier"
          rules={[{ required: true }]}
        >
          <Select
            options={[
              { value: 'qiuxiaoyan', label: '仇晓岩' },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <BtnDiv>
            <span>
              <Button type="default" onClick={this.handleSave}>{LAN.save}</Button>          
            &emsp;
              <Button type="default" htmlType="reset" onClick={this.handleReset}>{LAN.reset}</Button>  
            </span>
            <Button type="primary" htmlType="submit" disabled={this.state.status !== '8'}>{LAN.submit}</Button>
          </BtnDiv>
        </Form.Item>
      </Form>
    );
  }
}

AddSummaryForm.propTypes = {
  number: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default withRouter(connect(mapStateToProps)(AddSummaryForm));

const LANG = {
  zh: {
    imageview: '检查所见',
    diagnosis: '影像诊断', 
    viewer: '阅片医师',
    verifier: '审核医师',  
    submit: '审核并生成报告',
    reset: '重置',
    save: '保存',
    submitSuccess: '总结提交成功',
    saveSuccess: '已保存',
    template: '导入模板',
  },
  en: {
    imageview: 'Image View',
    diagnosis: 'Diagnosis', 
    viewer: 'Viewer',
    verifier: 'Verifier',  
    submit: 'Confirm and Submit',
    reset: 'Reset',
    save: 'Save',
    submitSuccess: 'Submit Successfully',
    saveSuccess: 'Save Successfully',
    template: 'Import Template',
  },
};
