import React from 'react';
import PropTypes from 'prop-types';
import UploadFormItem from './UploadFormItem';

class MsFormItems extends React.Component {
  render() {
    return (
      <UploadFormItem
        labelIndex="fileT23dLabel"
        isRequired
        field="fileT23d"
        filename="3dt2.zip"
        key="t23d"
        disabled={this.props.disabled}
      />
    );
  }
}


MsFormItems.propTypes = {
  disabled: PropTypes.bool,
};


export default MsFormItems;
