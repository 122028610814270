import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InputNumber, Switch } from 'antd';
import { admin as LANG } from '../../helpers/lan';

class EditFormItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      quota: null,
    };
  }

  triggerChange = (changedValue) => {
    const { checked, quota } = this.state;
    const { value, onChange } = this.props;
    if (onChange) {
      onChange({
        checked,
        quota,
        ...value,
        ...changedValue,
      });
    }
  };

  handleCheckChange = (checked) => {
    this.setState({ checked });
    this.triggerChange({ checked });
  }

  handleInputChange = (value) => {
    let num = value || parseInt(value, 10);
    if (Number.isNaN(num)) { num = null; }
    this.setState({ quota: num });
    this.triggerChange({ quota: num });
  }

  render() {
    const { value, lan } = this.props;
    const LAN = LANG[lan];
    const checked = value.checked || this.state.checked;
    const quota = value.quota === null ? this.state.quota : value.quota;
    return (
      <span>
        <Switch checked={checked} onChange={this.handleCheckChange} />
        &emsp;
        <span>
          {LAN.quota}
          :
        </span>
        &emsp;
        <InputNumber
          value={quota}
          disabled={!checked}
          min={0}
          onChange={this.handleInputChange}
        />
      </span>
    );
  }
}

EditFormItem.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

EditFormItem.defaultProps = {
  value: {},
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(EditFormItem);
