const getUrlParams = (search, key) => {
  if (search.length > 0 && search[0] === '?') {
    search = search.substr(1);
    const list = search.split('&');
    for (const str of list) {
      const ls = str.split('=');
      if (ls.length === 2 && ls[0] === key) {
        return ls[1];
      }
    }
  }
  return null;
};

export { getUrlParams };
