import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Card } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import AddAgentModal from './AddAgentModal';
import axios from '../../helpers/adminAxios';
import showError from '../../helpers/errorlist';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 60em;
  max-width: 100vw;
`;

class Agentlist extends React.Component {
  state = {
    agentlist: [],
    modalVisible: false,
  }

  componentDidMount() {
    this.loadUserList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modalVisible && this.state.modalVisible === false) {
      this.loadUserList();
    }
  }

  loadUserList = async () => {
    try {
      const res = await axios.get('/admin-auth/agents');
      const { agentlist } = res.data;
      this.setState({ agentlist });
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  handleShowModal = () => {
    this.setState({ modalVisible: true });
  }

  handleCloseModal = () => {
    this.setState({ modalVisible: false });
  }

  render() {
    const LAN = LANG[this.props.lan];
    const { agentlist, modalVisible } = this.state;
    const columns = [
      {
        title: LAN.agentname,
        dataIndex: 'agentname',
        key: 'agentname',
        sorter: (a, b) => (a.agentname >= b.agentname ? 1 : -1),
      },
      {
        title: LAN.phone,
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: LAN.email,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: LAN.organization,
        dataIndex: 'organization',
        key: 'organization',
        sorter: (a, b) => (a.organization.toUpperCase() >= b.organization.toUpperCase() ? 1 : -1),
      },
      {
        title: LAN.regTime,
        dataIndex: 'regTime',
        key: 'regTime',
        sorter: (a, b) => (a.regTime > b.regTime ? 1 : -1),
      },
    ];
    return (
      <SCard
        title={LAN.cardTitle}
        extra={(
          <Button
            icon={<UserAddOutlined />}
            title={LAN.addBtnTitle}
            onClick={() => { this.handleShowModal(); }}
          />
        )}
      >
        <Table
          columns={columns}
          size="middle"
          dataSource={agentlist}
          pagination={{ pageSize: 30 }}
        />
        <AddAgentModal
          visible={modalVisible}
          onClose={this.handleCloseModal}
        />
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(Agentlist);

const LANG = {
  zh: {
    cardTitle: '代理列表',
    addBtnTitle: '增加代理',
    agentname: '代理用户名',
    phone: '电话',
    email: '电子邮箱',
    organization: '机构',
    regTime: '注册时间',
  },
  en: {
    cardTitle: 'Agent List',
    addBtnTitle: 'Add new agent',
    agentname: 'Agentname',
    phone: 'Telephone',
    email: 'Email',
    organization: 'Organization',
    regTime: 'Regtime',
  },
};
