import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Button } from 'antd';
import styled from 'styled-components';
import { actions } from '../redux/index';
import { agreement as LANG } from '../helpers/lan';
import axios from '../helpers/axios';
import showError from '../helpers/errorlist';
import { setItem } from '../helpers/storage';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 90em;
  max-width: 100vw;
`;

const ButtonArea = styled.div`
  margin-top: 2em;
  text-align: center;
`;

const StrongHead = styled.h3`
  font-weight: bold;
`;

const SmallHead = styled.h4`
  font-weight: bold;
`;

const ChnAgreement = () => (
  <>
    <p><strong>重要须知：在使用AccuBrain® 软件之前，请仔细阅读本用户协议（以下简称“协议”），特别是免责条款、用户须知及违约条款。</strong></p>
    <p>本协议是您与AccuBrain® 软件（以下简称“本软件”）的权利人Shenzhen BrainNow Medical Technology Limited（以下简称“BrainNow”）之间的法律协议。BrainNow在符合本协议条款的前提下许可您使用本软件。</p>
    <p>您的下载、安装和使用等行为，表示您同意接受本协议各项条款的约束，同意遵守任何BrainNow发布的相关使用说明和手册。如果您不愿遵守上述规定，请勿使用本软件。</p>
    <StrongHead>1.	软件使用许可</StrongHead>
    <p>您已获得使用许可，但本软件并未出售，本软件的所有权和运营权均归BrainNow所有。您的使用权利是可撤销、非独占、不可转让、不可共享的，使用用途仅限于从大脑的磁共振（MR）图像中提取信息。</p>
    <StrongHead>2. 知识产权声明</StrongHead>
    <p>本软件的一切版权、商标权、专利权、商业秘密等知识产权，以及与软件相关的所有信息内容，包括但不限于：界面设计、有关数据、电子文档等均受中华人民共和国知识产权法律法规的保护，BrainNow享有上述知识产权，本软件其他一切未明确授予您的权利仍属于BrainNow。本协议未授予“AccuBrain”、“BrainNow”或任何其他BrainNow商标相关名称或服务标志的相关权利。</p>
    <StrongHead>3. 使用须知</StrongHead>
    <p>在使用本软件时，您不得：</p>
    <p>（a）	反编译、逆向工程、反汇编等解构、试图推导软件源代码或解密软件的行为;</p>
    <p>（b）	对本软件进行任何修改、改编、改进/增强、翻译或创作任何衍生作品;</p>
    <p>（c）	使用软件从事违反任何现行有效的适用法律法规、规则或条例的行为;</p>
    <p>（d）	删除、更改或隐藏本软件或BrainNow提供的任何服务的任何通知（包括任何版权或商标通知）;</p>
    <p>（e）	将本软件用于任何商业用途，或用于说明手册说明、本软件开发用途之外的其他目的;</p>
    <p>（f）	分享账号给第三方，通过允许多个用户同时访问或使用的网络或其他环境使软件可用;</p>
    <p>（g）	使用本软件创建与BrainNow具有直接或间接竞争关系的产品及/或服务。</p>
    <StrongHead> 4. 转让和分享限制</StrongHead>
    <p> 在使用本软件时，您不得：</p>
    <p>  （a）	通过将软件许可、转让、质押、出租等方式提供给任何第三方使用;</p>
    <p>  （b）	根据本协议分享您的权利;</p>
    <p>（c）	代表第三方从事软件的操作;</p>
    <p>（d）您有权将本软件分析并处理的PDF格式报告转发给第三方。在没有BrainNow的书面许可的情况下，从事软件操作以分析源自注册医院外成像中心或MRI扫描仪的MR数据集。</p>
    <StrongHead>5. 用户信息/数据的使用</StrongHead>
    <p>您信息的合法权益将得到尊重及保护，您的用户数据可能被用于软件和服务的开发与优化，BrainNow将会采取合理的措施保护用户信息，不向第三方公开、透露您的信息，法律法规规定的除外。因您违反本协议条款使用软件致使用户信息透露给第三方，BrainNow不承担由此产生的风险。您提供的数据因缺失某些用户信息(如年龄)导致软件部分功能无法使用，BrainNow不承担责任。</p>
    <p>您有义务获得患者/受试者的知情同意授权，再将其数据上传至BrainNow的线上分析工具AccuBrain进行分析。因知情同意授权的不完善而产生的纠纷，BrainNow不承担责任。</p>
    <StrongHead>6. 协议的终止</StrongHead>
    <p>本协议应持续到账户被注销或使用期限到期，您可以随时销毁本软件处理的所有文档副本/或停用您的帐户，终止本协议。</p>
    <p>BrainNow可能会在您违反本协议任何条款时立即终止本协议。终止后，您应立即停止使用本软件。如用户违反本协议任意一款规定，BrainNow有权在不事先通知的情况下完全或部分中止、限制用户账号的使用功能或停止用户使用本软件，由此而造成的损失，由用户自行承担。如造成BrainNow损失（包括但不限于BrainNow因此被国家机关予以行政处罚、被第三方通过诉讼或其他的方式索赔等），BrainNow有权要求用户给予相应的赔偿。</p>

    <StrongHead>7. 免责声明和责任限制</StrongHead>
    <p>（a）	隐私信息安全风险</p>
    <p>患者/受试者的隐私信息须在使用本软件前进行妥善的脱敏和加密处理，若未进行相应处理而产生的信息安全风险，由您自行承担。若对脱敏或加密方法有疑问，您可寻求BrainNow的建议和支持。</p>
    <p>（b）	上传数据的任何损失或损害</p>
    <p>上传至本软件的数据将在30天后过期并从您的帐户中删除。</p>
    <p>对于上传至本软件的数据，如果因BrainNow之外的原因发生数据泄露，您应对账户中敏感机密数据的丢失和暴露承担全部责任，前述情形出现时，您可寻求BrainNow的建议和支持。</p>
    <p>（c）	生成的报告</p>
    <p>本软件及软件生成的所有报告仅供临床参考或科研用途，您在使用过程中可寻求BrainNow的建议和支持，但BrainNow对基于报告结果做出的任何决策、判断、结论和解决方案不承担任何责任。</p>
    <p>（d）	不可抗力和互联网的不稳定性</p>
    <p>本软件可能会受到互联网不稳定因素的影响，对于因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、互联网络、通信线路等造成的服务中断、运行迟缓、数据传输失败等不能满足您使用要求的风险，BrainNow不作担保。</p>
    <StrongHead>8. 其他</StrongHead>
    <SmallHead>8.1 通知</SmallHead>
    <p>如您在使用本软件过程中有任何问题，相关问题咨询、通知、要求或请求请发送至：info@brainnow.cn。</p>
    <SmallHead>8.2 全部协议</SmallHead>
    <p>本协议是双方就软件的使用作出的全部约定，取代任何之前就本软件可能存在的约定。本协议标题只供参考，不用于解释协议。</p>
    <SmallHead>8.3 协议的修改</SmallHead>
    <p>BrainNow可能谨慎改变或撤销服务内容，包括更新系统的可用性、设备和软件需求，限制或限制使用的产品。</p>
    <p>BrainNow可以修改本协议。BrainNow将在官网公布修改后的协议条款并提醒您查阅，更新后的协议条款自公布后即代替原来的协议条款。建议您定期访问BrainNow的网站，以关注本协议的任何变化。在本协议修改后如您继续使用本软件将被视为您接受修改后的协议内容，如您不接受修改后的条款，请立即停止使用本软件。</p>
    <p>BrainNow保留在通过您提供的电子邮件向您提供30天通知的任何时候修改本协议的权利。 BrainNow可能会要求您接受修改后的协议，以继续使用您之前获得的软件。如果不这样做，BrainNow可能会终止您使用本软件，您的数据可能会被删除或丢失。</p>
    <SmallHead>8.4 弃权</SmallHead>
    <p>一方未能执行本协议的规定不代表其放弃之后执行该规定的权利。</p>
    <SmallHead>8.5 争议管辖及适用法律</SmallHead>
    <p>由本协议引起的所有纠纷适用法律为中华人民共和国法律、解释。</p>
    <SmallHead>8.6 可分割性</SmallHead>
    <p>当本协议的任何条款被认定为无效或因与法律相抵触应重新解释，该条款之无效或重新解释不影响其他条款之法律效力。</p>
    <SmallHead>8.7 存续</SmallHead>
    <p>本协议项下知识产权声明、用户须知、转让限制、免责条款、争议解决等规定在协议终止后仍然有效。</p>
    <SmallHead>8.8 第三方的权利</SmallHead>
    <p>本协议没有第三方受益人。</p>

    <p>BrainNow具有对本协议各项条款内容的最终解释权。</p>
  </>
);

const EngAgreement = () => (
  <>
    <p><strong>Important: Please read this User Agreement (hereinafter referred to as the &quot;Agreement&quot;) carefully before using the AccuBrain® software, especially the Disclaimer, User Notice and Breach of Contract Clauses.</strong></p>
    <p>This Agreement is a legal agreement between you and Shenzhen BrainNow Medical Technology Limited (hereinafter referred to as &quot;BrainNow&quot;), the owner of the AccuBrain® software (hereinafter referred to as the &quot;Software&quot;). BrainNow licenses you to use the Software subject to the terms of this Agreement.</p>
    <p>Your downloading, installation and use of the Software constitutes your agreement to be bound by the terms of this Agreement and to comply with any instructions and manuals published by BrainNow. If you do not wish to comply with the above provisions, please do not use the Software.</p>
    <StrongHead>1. Software License</StrongHead>
    <p>You have been licensed to use the Software, but the Software is not sold. The ownership and operation of the Software belong to BrainNow. Your right to use the Software is revocable, non-exclusive, non-transferable, and non-shareable, and the purpose of use is limited to extracting information from magnetic resonance (MR) images of the brain.</p>
    <StrongHead>2. Intellectual Property Statement</StrongHead>
    <p>All copyrights, trademarks, patents, trade secrets and other intellectual property rights of the Software, as well as all information content related to the Software, including but not limited to: interface design, relevant data, electronic documents, etc., are protected by the intellectual property laws and regulations of the People&apos;s Republic of China. BrainNow enjoys the above intellectual property rights. All other rights of the Software not expressly granted to you still belong to BrainNow. This Agreement does not grant any rights to &quot;AccuBrain&quot;, &quot;BrainNow&quot; or any other BrainNow trademark-related names or service marks.</p>
    <StrongHead>3. Instructions for use</StrongHead>
    <p>When using the Software, you may not:</p>
    <p>(a) Decompiling, reverse engineering, disassembling or other deconstruction, attempting to derive the source code of the software or decrypt the software;</p>
    <p>(b) make any modification, adaptation, improvement/enhancement, translation or create any derivative works from the Software;</p>
    <p>(c) use the Software to violate any applicable laws, regulations, rules or regulations in force;</p>
    <p>(d) remove, alter or obscure any notices (including any copyright or trademark notices) on the Software or any services provided by BrainNow;</p>
    <p>(e) use the Software for any commercial purpose or for any purpose other than that described in this manual or for which the Software was developed;</p>
    <p>(f) share your account with a third party and make the Software available over a network or other environment that allows multiple users to access or use the Software simultaneously;</p>
    <p>(g) use the Software to create products and/or services that compete directly or indirectly with BrainNow.</p>
    <StrongHead> 4. Restrictions on Transfer and Sharing</StrongHead>
    <p> When using the Software, you may not:</p>
    <p>(a) provide the software to any third party by licensing, transferring, pledging, leasing, etc.;</p>
    <p>(b) share your rights under this Agreement;</p>
    <p>(c) operate the Software on behalf of a third party;</p>
    <p>(d) You are entitled to forward the PDF format reports analyzed and processed by the Software to third parties. You are not entitled to operate the Software to analyze MR datasets originating from imaging centers or MRI scanners outside the Registered Hospitals without BrainNow&apos;s written permission.</p>
    <StrongHead>5. Use of User Information/Data</StrongHead>
    <p>The legitimate rights and interests of your information will be respected and protected. Your user data may be used for the development and optimization of software and services. BrainNow will take reasonable measures to protect user information and will not disclose or disclose your information to third parties, except as required by laws and regulations. BrainNow does not bear any risks arising from the disclosure of user information to third parties due to your use of the software in violation of the terms of this Agreement. BrainNow does not bear any responsibility for the inability to use some functions of the software due to the lack of certain user information (such as age) in the data you provided.</p>
    <p>You are obliged to obtain the informed consent authorization of the patient/subject before uploading their data to BrainNow&apos;s online analysis tool AccuBrain for analysis. BrainNow is not responsible for any disputes arising from incomplete informed consent authorization.</p>
    <StrongHead>6. Termination of the Agreement</StrongHead>
    <p>This Agreement shall continue until the Account is cancelled or the term of use expires. You may terminate this Agreement at any time by destroying all copies of documents processed by the Software and/or deactivating your Account.</p>
    <p>BrainNow may terminate this Agreement immediately if you violate any of the terms of this Agreement. Upon termination, you shall immediately stop using the Software. If the User violates any of the provisions of this Agreement, BrainNow has the right to completely or partially suspend, restrict the use of the User&apos;s account, or stop the User from using the Software without prior notice, and the User shall bear the losses caused thereby. If BrainNow suffers losses (including but not limited to BrainNow being subject to administrative penalties by state agencies, or being sued by a third party through litigation or other means, etc.), BrainNow has the right to demand corresponding compensation from the User.</p>

    <StrongHead>7. Disclaimer and Limitation of Liability</StrongHead>
    <p>(a) Privacy information security risks</p>
    <p>The privacy information of patients/subjects must be properly desensitized and encrypted before using this software. If the corresponding processing is not carried out, the information security risks arising will be borne by you. If you have any questions about the desensitization or encryption methods, you can seek advice and support from BrainNow.</p>
    <p>(b) any loss or damage to uploaded data</p>
    <p>Data uploaded to the Software will expire and be deleted from your account after 30 days.</p>
    <p>If the data uploaded to the Software is leaked due to reasons other than BrainNow, you shall bear full responsibility for the loss and exposure of sensitive and confidential data in your account. If the above situation occurs, you may seek advice and support from BrainNow.</p>
    <p>(c) Generated reports</p>
    <p>This software and all reports generated by the software are for clinical reference or scientific research purposes only. You may seek advice and support from BrainNow during use, but BrainNow does not assume any responsibility for any decisions, judgments, conclusions and solutions made based on the report results.</p>
    <p>(d) Force majeure and Internet instability</p>
    <p>This software may be affected by unstable factors on the Internet. BrainNow does not guarantee that the software will not meet your requirements due to service interruptions, slow operation, data transmission failure, etc. caused by force majeure, computer viruses or hacker attacks, system instability, user location, user shutdown, Internet, communication lines, etc.</p>
    <StrongHead>8. Others</StrongHead>
    <SmallHead>8.1 Notification</SmallHead>
    <p>If you have any questions while using this software, please send any related inquiries, notifications, requirements or requests to: info@brainnow.cn.</p>
    <SmallHead>8.2 Entire Agreement</SmallHead>
    <p>This Agreement is the entire agreement between the parties regarding the use of the Software, and supersedes any previous agreement that may have existed regarding the Software. The title of this Agreement is for reference only and is not used to interpret the Agreement.</p>
    <SmallHead>8.3 Modification of the Agreement</SmallHead>
    <p>BrainNow may change or withdraw the Services at its sole discretion, including the availability of updated systems, device and software requirements, or restrict or limit the use of products.</p>
    <p>BrainNow may modify this Agreement. BrainNow will publish the modified Agreement terms on its official website and remind you to review them. The updated Agreement terms will replace the original Agreement terms after publication. It is recommended that you visit BrainNow&apos;s website regularly to pay attention to any changes to this Agreement. If you continue to use this software after this Agreement is modified, it will be deemed that you accept the modified Agreement content. If you do not accept the modified terms, please stop using this software immediately.</p>
    <p>BrainNow reserves the right to modify this Agreement at any time by providing you with 30 days notice via the email address you provided. BrainNow may require you to accept the modified Agreement in order to continue to use the Software you previously acquired. If you do not do so, BrainNow may terminate your use of the Software and your data may be deleted or lost.</p>
    <SmallHead>8.4 Waiver</SmallHead>
    <p>The failure of a party to enforce a provision of this Agreement does not constitute a waiver of its right to later enforce such provision.</p>
    <SmallHead>8.5 Jurisdiction over Disputes and Applicable Law</SmallHead>
    <p>All disputes arising from this Agreement shall be governed by the laws of the People&apos;s Republic of China and interpreted accordingly.</p>
    <SmallHead>8.6 Severability</SmallHead>
    <p>When any provision of this Agreement is deemed invalid or should be reinterpreted due to its conflict with the law, the invalidity or reinterpretation of such provision shall not affect the legal validity of other provisions.</p>
    <SmallHead>8.7 Continuation</SmallHead>
    <p>The intellectual property statement, user instructions, transfer restrictions, disclaimers, dispute resolution and other provisions under this Agreement shall remain valid after the termination of this Agreement.</p>
    <SmallHead>8.8 Rights of Third Parties</SmallHead>
    <p>There are no third-party beneficiaries to this Agreement.</p>

    <p>BrainNow reserves the final right to interpret the terms and conditions of this Agreement.</p>
  </>
);
class Agreement extends React.Component {
  handleAgree = async () => {
    try {
      await axios.patch(`/auth/user-status/${this.props.username}`, {});
      // setItem('userStatus', 1);
      this.props.onAgree(1);
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  render() {
    const { lan, userStatus } = this.props;
    const LAN = LANG[lan];
    return (
      <SCard title={LAN.cardTitle}>
        { lan === 'zh' ? <ChnAgreement /> : <EngAgreement />}
        <ButtonArea>
          {userStatus === 1
            ? <Button type="default" disabled>{LAN.agreedBtn}</Button>
            : <Button type="primary" onClick={this.handleAgree}>{LAN.agreeBtn}</Button>}
        </ButtonArea>
      </SCard>
    );
  }
}

Agreement.propTypes = {
  onAgree: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
  userStatus: state.userStatus,
  username: state.username,
});

const mapDispatchToProps = (dispatch) => ({
  onAgree: (obj) => { dispatch(actions.setUserStatus(obj)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Agreement);
