import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'antd';
import ObsUploader from './ObsUploader';
import { upload as LANG } from '../../helpers/lan';

class UploadFormItem extends React.Component {
  render() {
    const { labelIndex, field, isRequired, lan, filename, disabled, obsTail } = this.props;
    const LAN = LANG[lan];
    return (
      <Form.Item
        label={LAN[labelIndex]}
        name={field}
        valuePropName="fileList"
        getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
        rules={[{ required: isRequired, message: LAN.fileRequiredMsg }]}
      >
        <ObsUploader filename={filename} disabled={disabled} key={`${field}-${obsTail}`} onChangeUploadStatus={this.handleChangeUploadStatus} />
      </Form.Item>
    );
  }
}

UploadFormItem.propTypes = {
  labelIndex: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  obsTail: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

UploadFormItem.defaultProps = {
  isRequired: false,
  disabled: false,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
  obsTail: state.obsTail,
});

export default connect(mapStateToProps)(UploadFormItem);
