import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Button, message } from 'antd';
import { unsubscribe as LANG } from '../helpers/lan';
import showError from '../helpers/errorlist';
import axios from '../helpers/axios';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 40em;
  max-width: 100vw;
`;

class UnsubscribeCard extends Component {
  handleSubmit = async () => {
    try {
      const { lan, history } = this.props;
      const searchParams = new URLSearchParams(history.location.search.substr(1));
      const username = searchParams.get('username');
      const subCode = searchParams.get('code');

      await axios.patch(`/user-subscribes/${username}`, { subCode });
      message.success(LANG[lan].successMsg);
      history.push('/sign-in');
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  render() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    return (
      <SCard title={LAN.cardTitle}>
        <p>{LAN.content}</p>
        <Button type="primary" onClick={this.handleSubmit}>{LAN.buttonTitle}</Button>
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default withRouter(connect(mapStateToProps)(UnsubscribeCard));
