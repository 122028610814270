import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card, Tabs } from 'antd';
import { addUser as LANG } from '../../helpers/lan';
import AddNormalUserForm from './AddNormalUserForm';
import AddCFDAUserForm from './AddCFDAUserForm';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 45em;
  min-width: 40em;
  max-width: 100vw;
`;

class AddUserCard extends React.Component {
  render() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    return (
      <SCard
        title={LAN.cardTitle}
      >
        <Tabs defaultActiveKey="normal">
          <Tabs.TabPane tab={LAN.normalTab} key="normal">
            <AddNormalUserForm />
          </Tabs.TabPane>
          <Tabs.TabPane tab={LAN.cfdaTab} key="cfda">
            <AddCFDAUserForm />
          </Tabs.TabPane>     
        </Tabs>
        
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(AddUserCard);
