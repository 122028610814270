import axios from 'axios';
import { Modal } from 'antd';
import { API_DOMAIN } from '../config';
import { removeItem, setItem, getItem } from './storage';

const myaxios = axios.create({
  baseURL: API_DOMAIN,
  timeout: 5000,
  headers: {
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
  },
});

myaxios.interceptors.request.use((config) => {
  try {
    const token = getItem('act');
    if (token) {
      Object.assign(config.headers, { authorization: `Bearer ${token}` });
    }
    return config;
  } catch (error) {
    return config;
  }
}, (error) => {
  // eslint-disable-next-line no-console
  console.error(error);
  Promise.reject(error);
});

myaxios.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    Modal.error({
      content: '身份认证失败或登录超时(Authorization Failed or Timeout)',
      onOk: () => {
        // store.dispatch(actions.signOut());
        removeItem('username');
        removeItem('act');
        setItem('loginType', 'logout');
        window.location = '/admin';
      },
    });
  }
  return Promise.reject(error.response.data);
});

export default myaxios;
