import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Result, Button } from 'antd';

const Result404 = ({ lan, history }) => (
  <Result
    status="404"
    title="404"
    subTitle={LANG[lan].subTitle}
    extra={<Button type="primary" onClick={() => { history.push('/'); }}>{LANG[lan].btn}</Button>}
  />
);

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default withRouter(connect(mapStateToProps)(Result404));

const LANG = {
  zh: {
    subTitle: '对不起，您访问的页面不存在!',
    btn: '返回主页',
  },
  en: {
    subTitle: 'Sorry, the page you visited does not exist.',
    btn: 'Back Home',
  },
};
