import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import styled from 'styled-components';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';
import { getUrlParams } from '../../helpers/tool';
import AddSummaryForm from './AddSummaryForm';

const PdfViewer = React.lazy(() => import('../UI/PdfViewer'));

const SCard = styled(Card)`
  margin: 6em auto;
  width: 110em;
  min-width: 110em;
  max-width: 100vw;
`;

const FormTitle = styled.h3`
  font-weight: bold;
`;

class Preview extends React.Component {
  state = {
    previewUrl: '',
    number: '',
  }

  componentDidMount = async () => {
    const { lan } = this.props;
    try {
      const { search } = this.props.history.location;
      const number = getUrlParams(search, 'number');
      const reportName = getUrlParams(search, 'reportName');
      const res = await axios.get(`/auth/preview-pdf-urls?number=${number}&reportName=${reportName}`);
      const { downloadUrl } = res.data;
      this.setState({ previewUrl: downloadUrl, number });
    } catch (error) {
      showError(error, lan);
    }
  }

  render() {
    const { lan } = this.props;
    const LAN = LANG[lan];
    const { previewUrl, number } = this.state;
    return (
      <SCard title={LAN.previewCardTitle}>
        <Card.Grid style={{ width: '70%' }}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <PdfViewer url={previewUrl} />
          </React.Suspense>
        </Card.Grid>
        <Card.Grid style={{ width: '30%' }} hoverable={false}>
          <FormTitle>{LAN.addSummary}</FormTitle>
          <AddSummaryForm number={number} />
        </Card.Grid>
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
  username: state.username,
});

export default connect(mapStateToProps)(Preview);

const LANG = {
  zh: {
    previewCardTitle: '报告预览',
    addSummary: '添加总结',
  },
  en: {
    previewCardTitle: 'Report Preview',
    addSummary: 'Add Summary',
  },
};
