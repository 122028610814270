import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import UserlistSider from './UserlistSider';
import MailBox from './MailBox';

const { Sider, Content } = Layout;
const SLayout = styled(Layout)`
  margin: 5em auto;
  background: #fff;
  width: 100em;
  height: 800px;
`;

const SSider = styled(Sider)`
  background: #fff;
`;

const SContent = styled(Content)`
  padding: 0 3em;
`;


class AdminSendEmailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailList: [],
    };
  }

  handleChangeEmailList = (emailList) => {
    this.setState({ emailList });
  }

  render() {
    return (
      <SLayout>
        <SSider width={400}>
          <UserlistSider
            emailList={this.state.emailList}
            onChangeEmailList={this.handleChangeEmailList}
          />
        </SSider>
        <SContent>
          <MailBox 
            emailList={this.state.emailList}
            onChangeEmailList={this.handleChangeEmailList}
          />
        </SContent>
      </SLayout>
    );
  }
}

export default AdminSendEmailPage;
