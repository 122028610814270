import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card, Tabs } from 'antd';
import UploadForm from './UploadForm';
import { upload as LANG } from '../../helpers/lan';
import { categoryList } from '../../helpers/modules';

const SCard = styled(Card)`
  margin: 10em auto;
  width: 55em;
  max-width: 100vw;  
`;
const { TabPane } = Tabs;
class UploadCard extends React.Component {
  render() {
    const { lan, modules, defaultModuleConfig } = this.props;
    const LAN = LANG[lan];
    const categories = Object.keys(modules || {});
    if (categories.length > 1) {
      const defaultKey = defaultModuleConfig && defaultModuleConfig.module
      && `${Math.floor(defaultModuleConfig.module / 100) * 100}`;
      return (
        <SCard title={LAN.cardTitle}>
          <Tabs defaultActiveKey={defaultKey}>
            {Object.keys(modules || {}).map((md) => (
              <TabPane tab={categoryList[md][lan]} key={md}>
                <UploadForm moduleArray={modules[md]} />
              </TabPane>
            ))}
          </Tabs>
        </SCard>
      );
    }
    if (categories[0]) {
      return (
        <SCard title={LAN.cardTitle}>
          <UploadForm moduleArray={modules[categories[0]]} />
        </SCard>
      );
    } 
    return null;
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
  modules: state.modules,
  defaultModuleConfig: state.defaultModuleConfig,
});

export default connect(mapStateToProps)(UploadCard);
