export const moduleList = {
  // CFDA
  101: {
    group: 100,
    zh: 'CFDA',
    en: 'CFDA',
    version: ['1.0', '1.2'],
  },
  // DG
  201: {
    group: 200,
    zh: '阿尔茨海默',
    en: 'Alzheimer\'s Disease',
    version: ['1.2', '2.0', '3.0'],
  },
  202: {
    group: 200,
    zh: '海马硬化',
    en: 'Hippocampal Sclerosis',
    version: ['1.2', '2.0', '3.0'],
  },
  204: {
    group: 200,
    zh: '脑小血管病',
    en: 'Small Vessel Disease',
    version: ['1.2', '2.0', '3.0'],
  },
  205: {
    group: 200,
    zh: '帕金森综合征',
    en: 'Parkinsonism',
    version: ['1.2', '2.0', '3.0'],
  },
  206: {
    group: 200,
    zh: '认知障碍',
    en: 'Cognitive Impairment',
    version: ['1.2', '2.0', '3.0'],
  },
  207: {
    group: 200,
    zh: '脑积水',
    en: 'Normal Pressure Hydrocephalus',
    version: ['3.0'],
  },

  // MC
  301: {
    group: 300,
    zh: '体检中心版',
    en: 'Medical Center',
    version: ['1.2', '2.0'],
  },

  // IC
  401: {
    group: 400,
    zh: '筛查基础版',
    en: 'Screening Basic',
    version: ['1.2', '2.0'],
  },
  402: {
    group: 400,
    zh: '筛查全面版',
    en: 'Screening Pro',
    version: ['1.2', '2.0'],
  },
  403: {
    group: 400,
    zh: '脑健康管理',
    en: 'Brain Health',
    version: ['1.2', '2.0'],
  },
  404: {
    group: 400,
    zh: 'GP篩查版',
    en: 'GP screening',
    version: ['1.2', '2.0'],
  },
  405: {
    group: 400,
    zh: '脑中风风险评估',
    en: 'Stroke Risk Evaluation',
    version: ['2.0'],
  },
  406: {
    group: 400,
    zh: 'KPM',
    en: 'KPM',
    version: ['2.0'],
  },

  // MS
  501: {
    group: 500,
    zh: '多发性硬化症',
    en: 'Multiple Scelosis',
    version: ['1.2', '2.0', '3.0'],
  },
  502: {
    group: 500,
    zh: '多发性硬化症（纵向研究）',
    en: 'Multiple Scelosis (Longitudinal Study)',
    version: ['1.2', '2.0', '3.0'],
  },

  // GE
  601: {
    group: 600,
    zh: '生命影像数字脑健康评估',
    en: 'Brain Health Assessment',
    version: ['2.0'],
  },
  602: {
    group: 600,
    zh: '阿尔茨海默病风险评估',
    en: 'AD screen',
    version: ['2.0'],
  },
  603: {
    group: 600,
    zh: '脑退行疾病全检',
    en: 'Neurodegenerative Disorder',
    version: ['2.0'],
  },
  604: {
    group: 600,
    zh: 'HP-AD筛查',
    en: 'HP-AD Screening',
    version: ['2.0'],
  },
  605: {
    group: 600,
    zh: 'HP-PD筛查',
    en: 'HP-PD Screening',
    version: ['2.0'],
  },
  606: {
    group: 600,
    zh: '脑部磁共振影像量化分析',
    en: 'Brain MRI quantification',
    version: ['2.0'],
  },
  607: {
    group: 600,
    zh: '新冠相关脑容积定量报告（试用版）',
    en: 'Brain volume quantification related to COVID-19(Beta)',
    version: ['2.0'],
  },
  608: {
    group: 600,
    zh: '生命影像数字脑健康评估（升级版）',
    en: 'Brain Health Assessment (upgrade) ',
    version: ['2.0'],
  },

  // sanbo
  701: {
    group: 700,
    zh: '阿尔茨海默（三博）',
    en: 'Alzheimer\'s Disease (Sanbo)',
    version: ['2.0'],
  },
  702: {
    group: 700,
    zh: '认知障碍（三博）',
    en: 'Cognitive Impairment (Sanbo)',
    version: ['2.0'],
  },
  703: {
    group: 700,
    zh: '海马硬化（三博）',
    en: 'Hippocampal Sclerosis (Sanbo)',
    version: ['2.0'],
  },
  704: {
    group: 700,
    zh: '帕金森综合征（三博）',
    en: 'Parkinsonism (Sanbo)',
    version: ['2.0'],
  },
  705: {
    group: 700,
    zh: '脑小血管病（三博）',
    en: 'Small Vessel Disease (Sanbo)',
    version: ['2.0'],
  },
  706: {
    group: 700,
    zh: '脑积水（三博）',
    en: 'Normal Pressure Hydrocephalus (Sanbo)',
    version: ['2.0'],
  },
  707: {
    group: 700,
    zh: '生命影像数字脑健康评估（三博）',
    en: 'Brain Health Assessment (Sanbo)',
    version: ['2.0'],
  },
  708: {
    group: 700,
    zh: '多发性硬化症（三博）',
    en: 'Multiple Scelosis (Sanbo)',
    version: ['2.0'],
  },
  709: {
    group: 700,
    zh: '多发性硬化症（纵向研究三博版）',
    en: 'Multiple Scelosis (Longitudinal Study - Sanbo)',
    version: ['2.0'],
  },
};

export const categoryList = {
  100: {
    zh: 'CFDA',
    en: 'CFDA',
    modules: [101],
  },
  200: {
    zh: '诊断版',
    en: 'Diagnosis',
    modules: [201, 202, 204, 205, 206, 207],
  },
  300: {
    zh: '体检中心版',
    en: 'Medical Center',
    modules: [301],
  },
  400: {
    zh: '筛查版',
    en: 'Screening',
    modules: [401, 402, 403, 404, 405, 406],
  },
  500: {
    zh: '多发性硬化症版',
    en: 'Multiple Scelosis',
    modules: [501, 502],
  },
  600: {
    zh: 'GE',
    en: 'GE',
    modules: [601, 602, 603, 604, 605, 606, 607, 608],
  },
  700: {
    zh: '三博版',
    en: 'Sanbo',
    modules: [701, 702, 703, 704, 705, 706, 707, 708, 709],
  },
};

export const moduleEnum = {
  // ASL: 1,
  // FCD: 2,
  CFDA: 101,
  AD: 201,
  HS: 202,
  SVD: 204,
  PD: 205,
  FTDAD: 206,
  NPH: 207,

  MC: 301,

  ICB: 401,
  ICP: 402,
  BH: 403,
  GP: 404,
  SRE: 405,
  KPM: 406,

  MS: 501,
  MSL: 502,

  BHA: 601,
  GEICB: 602,
  GEICP: 603,
  HP_AD: 604,
  HP_PD: 605,
  GEYL: 606,
  GECOV: 607,
  BHAM: 608,

  SBAD: 701,
  SBFTD: 702,
  SBHS: 703,
  SBPD: 704,
  SBSVD: 705,
  SBNPH: 706,
  SBMUL: 707,
  SBMS: 708,
  SBMSL: 709,
};

export const categoryEnum = {
  CFDA: 100,
  DG: 200,
  MC: 300,
  IC: 400,
  MS: 500,
  GE: 600,
  SB: 700,
};
