import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import styled from 'styled-components';
import { navRouters, conRouters } from './routers';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer';

const SLayout = styled(Layout)`
  height: 100vh;
  background-color: whitesmoke;
  padding: 0;
`;

const SContent = styled(Layout.Content)`
  min-height: 600px;  
  background-color:whitesmoke;
  flex: 1 0 auto;
`;

export default class Root extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <SLayout>
          <NavBar>
            <Switch>
              {navRouters.map((value) => (
                <Route
                  path={value.path}
                  exact={value.exact}
                  key={value.path}
                  render={(props) => (
                    <value.component {...props} routes={value.routes} />
                  )} />
              ))}
            </Switch>
          </NavBar>
          <SContent>
            <Switch>
              {conRouters.map((value) => (
                <Route
                  path={value.path}
                  exact={value.exact}
                  key={value.path}
                  render={(props) => (
                    <value.component {...props} routes={value.routes} />
                  )} />
              ))}
            </Switch>
          </SContent>
          <Footer />
        </SLayout>
      </BrowserRouter>
    );
  }
}
