import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel, Row, Col } from 'antd';
import { PhoneOutlined, MailOutlined, GlobalOutlined, EnvironmentOutlined } from '@ant-design/icons';
import imgSlider1 from '../../../static/slider1.jpg';
import imgSlider2 from '../../../static/slider2.jpg';
import imgSlider3 from '../../../static/slider3.jpg';
import imgSlider11 from '../../../static/slider1_1.jpg';
import imgSlider21 from '../../../static/slider2_1.jpg';
import imgSlider31 from '../../../static/slider3_1.jpg';
import imgCompany from '../../../static/company.jpg';
import imgProduct from '../../../static/product.jpg';
import imgGroup from '../../../static/group.jpg';
import imgQR from '../../../static/qr.jpg';
import imgLinkedIn from '../../../static/linkedin.png';
import imgFacebook from '../../../static/facebook.png';
import serviceImg1 from '../../../static/service_1.png';
import serviceImg2 from '../../../static/service_2.png';
import { homeContent as LANG } from '../../helpers/lan';
import './homeContent.css';
import FunctionSection from './FunctionSection';
import AdvantageSection from './AdvantageSection';

const HomeContent = (props) => {
  const LAN = LANG[props.lan];
  return (
    <article className="homeArticle">
      <section className="carouselSection">
        <Carousel autoplay className="homeCarousel">
          <div>
            <picture>
              <source media="(min-width: 1050px)" srcSet={imgSlider1} />
              <img src={imgSlider11} alt="slider1" />
            </picture>
            <div className="carouselCaptionDiv">
              <h1 className="dark">{LAN.slider1H1}</h1>
              <h4 className="dark">{LAN.slider1H4}</h4>
            </div>
          </div>
          <div>
            <picture>
              <source media="(min-width: 1050px)" srcSet={imgSlider2} />
              <img src={imgSlider21} alt="slider2" />
            </picture>
            <div className="carouselCaptionDiv carouselCaptionDarkDiv">
              <h1>{LAN.slider2H1}</h1>
              <h4>{LAN.slider2H4}</h4>
            </div>
          </div>
          <div>
            <picture>
              <source media="(min-width: 1050px)" srcSet={imgSlider3} />
              <img src={imgSlider31} alt="slider3" />
            </picture>
            <div className="carouselCaptionDiv">
              <h1>{LAN.slider3H1}</h1>
              <h4>{LAN.slider3H4}</h4>
            </div>
          </div>
        </Carousel>

      </section>
      <section className="aboutSection">
        <button id="aboutUs" className="anchorLink linkLikeButton" type="button"><h1 className="aboutH1">{LAN.aboutH1}</h1></button>
        <Row>
          <Col xl={7} lg={7} md={7}>
            <img src={imgCompany} alt={LAN.companyH4} className="aboutImage" />
            <h4 className="aboutH4">{LAN.companyH4}</h4>
            <p className="aboutParagraph">{LAN.companyPara}
            </p>
          </Col>
          <Col xl={7} lg={7} md={7} offset={1}>
            <img src={imgProduct} alt={LAN.productH4} className="aboutImage" />
            <h4 className="aboutH4">{LAN.productH4}</h4>
            <p className="aboutParagraph">{LAN.productPara}
            </p>
          </Col>
          <Col xl={7} lg={7} md={7} offset={1}>
            <img src={imgGroup} alt={LAN.groupH4} className="aboutImage" />
            <h4 className="aboutH4">{LAN.groupH4}</h4>
            <p className="aboutParagraph">{LAN.groupPara}
            </p>
          </Col>
        </Row>
      </section>
      <section className="serviceSection">
        <button id="service" className="anchorLink linkLikeButton" type="button">
          <h1>{LAN.serviceH1}</h1>
        </button>
        <h4>{LAN.serviceH4_1}</h4>
        <h4>{LAN.serviceH4}</h4>
        <div className="imgDiv">
          <img src={serviceImg1} alt={LAN.serviceH1} className="serviceImg" />
          <img src={serviceImg2} alt={LAN.serviceH1} className="serviceImg" />
        </div>
      </section>
      <FunctionSection />
      <AdvantageSection />
      <section className="contactSection">
        <button id="contactUs" className="anchorLink linkLikeButton" type="button"><h1>{LAN.contactH1}</h1></button>
        <Row>
          <Col xl={16} lg={16} md={16}>
            <p><PhoneOutlined />{LAN.phonePara}</p>
            <p><MailOutlined /> info@brainnow.cn</p>
            <p><GlobalOutlined /> <a href="https://www.brainnow.net">https://www.brainnow.net</a></p>
            <p><EnvironmentOutlined /> {LAN.szPara}</p>
            <p><EnvironmentOutlined /> {LAN.hkPara}</p>
            <p>
              <a href=" https://www.facebook.com/BrainNow-Medical-Technology-Limited-370595140070475/">
                <img src={imgFacebook} alt="关注我们的Facebook主页" />
              </a>
              &emsp;
              <a href=" https://www.linkedin.com/company/brainnow/">
                <img src={imgLinkedIn} alt="关注我们的领英主页" />
              </a>
            </p>
          </Col>
          <Col xl={5} lg={5} md={5} offset={1}>
            <p>{LAN.wechatPara}</p>
            <img src={imgQR} alt={LAN.wechatPara} className="contactImg" />
          </Col>
        </Row>
      </section>
    </article>
  );
};

HomeContent.defaultProps = {};

HomeContent.propTypes = {
  lan: PropTypes.string,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(HomeContent);
