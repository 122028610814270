import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { admin as LANG } from '../../helpers/lan';
import axios from '../../helpers/adminAxios';
import showError from '../../helpers/errorlist';

class UserlistSider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userlist: [],
    };
  }

  async componentDidMount() {
    try {
      const res = await axios.get('/admin-auth/simple-users');
      const { userlist } = res.data;
      this.setState({ userlist });
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  handleSelectChange = (_selectedRowKeys, selectedRows) => {
    const emailList = selectedRows.map((v) => ({ username: v.username, email: v.email, subCode: v.subCode }));
    this.props.onChangeEmailList(emailList);
  }

  render() {
    const { emailList, lan } = this.props;
    const LAN = LANG[lan];
    const selectedKeys = emailList.map((v) => `${v.username}-key`);
    const columns = [{
      title: LAN.selectUser,
      dataIndex: 'username',
      render: (text, record) => (<span><strong>{text}</strong> ({record.email})<br />{record.organization}</span>),
    }];
    return (
      <Table
        size="small"
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeys,
          onChange: this.handleSelectChange,
        }}
        columns={columns}
        dataSource={this.state.userlist.map((v) => ({ ...v, key: `${v.username}-key` }))}
        pagination={false}
        scroll={{ y: 760 }}
      />
    );
  }
}

UserlistSider.propTypes = {
  emailList: PropTypes.array,
  onChangeEmailList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(UserlistSider);
