const publicationList = [
  {
    title: '1. Abrigo et al. 2018',
    content: 'Standardization of hippocampus volumetry using automated brain structure volumetry tool for an initial alzheimer‘s disease imaging biomarker. Acta Radiol. 2018',
    url: 'https://doi.org/10.1177/0284185118795327',
  },
  {
    title: '2. Wang et al. 2019',
    content: 'Structural covariance in subcortical stroke patients measured by automated MRI-based volumetry. Neuroimage: clinical. 2019',
    url: 'https://doi.org/10.1016/j.nicl.2019.101682',
  },
  {
    title: '3. Zheng et al. 2019',
    content: 'Machine Learning-Based Framework for Differential Diagnosis Between Vascular Dementia and Alzheimer\'s Disease Using Structural MRI Feature. Frontiers in neurology. 2019',
    url: 'https://doi.org/10.3389/fneur.2019.01097',
  },
  {
    title: '4. Zhao et al. 2019',
    content: 'Automated Brain MRI Volumetry Differentiates Early Stages of Alzheimer\'s Disease From Normal Aging. J Geriatr Psychiatry Neurol. 2019',
    url: 'https://doi.org/10.1177%2F0891988719862637',
  },
  {
    title: '5. Zhao et al. 2019',
    content: 'Risk estimation before progression to mild cognitive impairment and Alzheimer\'s disease: an AD resemblance atrophy index. Aging (Albany NY). 2019',
    url: 'https://doi.org/10.18632/aging.102184',
  },
  {
    title: '6. Yu et al. 2021',
    content: 'Yu et al. An MRI-based strategy for differentiation of frontotemporal dementia and Alzheimer\'s disease. Alz Res Therapy. 2021',
    url: 'https://doi.org/10.1186/s13195-020-00757-5',
  },
  {
    title: '7. Mai et al. 2021',
    content: 'Mai et al. AD Resemblance Atrophy Index as a Diagnostic Biomarker for Alzheimer\'s Disease: A Retrospective Clinical and Biological Validation. JAD. 2021',
    url: 'https://doi.org/10.3233/jad-201033',
  },
  {
    title: '8. Liu et al. 2020',
    content: 'Liu et al. MRI‐based automated volumetric segmentation tool in the detection of early Alzheimer’s disease. Alzheimer\'s Dement. 2020',
    url: 'https://doi.org/10.1002/alz.042340',
  },
  {
    title: '9. Zhang et al. 2020',
    content: 'Zhang et al. Differential diagnosis of parkinsonian degenerative disorders in combination manual measurements with automated volumetry of the brain. Research Square. 2020',
    url: 'https://doi.org/10.21203/rs.3.rs-45661/v1',
  },
  {
    title: '10. Liu et al. 2020',
    content: 'Liu et al. Inter-scanner reproducibility of brain volumetry: influence of automated brain segmentation software. BMC Neurosci. 2020',
    url: 'https://doi.org/10.1186/s12868-020-00585-1',
  },
  {
    title: '11. Guo et al. 2019 ',
    content: 'Guo et al. Intra-Scanner and Inter-Scanner Reproducibility of Automatic White Matter Hyperintensities Quantification. Front. Neurosci. 2019',
    url: 'https://doi.org/10.3389/fnins.2019.00679',
  },
];

export default publicationList;
