import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, PageHeader } from 'antd';
import PersonalDescriptions from './PersonalDescriptions';
import header from '../../../static/avatar.jpeg';

class ProfileDrawer extends React.Component {
  render() {
    const { visible, username, onClose } = this.props;
    return (
      <Drawer
        width={700}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        <PageHeader
          title={username}
          avatar={{ src: header }}
          onBack={onClose}
        >
          <PersonalDescriptions username={username} key={`pd-${username}`} />
        </PageHeader>
      </Drawer>
    );
  }
}

ProfileDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProfileDrawer;
