import React from 'react';
import { connect } from 'react-redux';
import { Card, Tabs } from 'antd';
import styled from 'styled-components';
import PasswordForm from './PasswordForm';
import { profile as LANG } from '../../../helpers/lan';

const BasicForm = React.lazy(() => import('./BasicForm'));
const SCard = styled(Card)`
  margin: 10em auto;
  width: 40em;
  max-width: 100vw;
`;

class ProfileCard extends React.Component {
  render() {
    const LAN = LANG[this.props.lan];
    return (
      <SCard
        title={LAN.cardTitle}
        className="profileCard"
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={LAN.basicTab} key="1">
            <React.Suspense fallback={<div>Loading...</div>}>
              <BasicForm />
            </React.Suspense>
          </Tabs.TabPane>
          <Tabs.TabPane tab={LAN.changePasswordTab} key="2">
            <PasswordForm />
          </Tabs.TabPane>
        </Tabs>
      </SCard>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(ProfileCard);
