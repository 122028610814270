import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, ReloadOutlined } from '@ant-design/icons';
import { pbkdf2Sync } from 'crypto';
import showError from '../../../helpers/errorlist';
import axios from '../../../helpers/agentAxios';
import { profile as LANG } from '../../../helpers/lan';

class PasswordForm extends React.Component {
  formRef = React.createRef();
  handleSubmit = async (values) => {
    try {
      const { currentPassword, newPassword } = values;
      const { lan } = this.props;
      const salt = 'brainnowyes';
      const data = {
        currentPassword: pbkdf2Sync(currentPassword, salt, 1000, 32, 'sha512').toString('hex'),
        newPassword: pbkdf2Sync(newPassword, salt, 1000, 32, 'sha512').toString('hex'),
      };
      await axios.patch(`/agent-auth/passwords/${this.props.agentname}`, data);
      message.success(LANG[lan].successMsg);
    } catch (error) {
      showError(error, this.props.lan);
    }
    this.handleReset();
  }

  handleReset = () => {
    this.formRef.current.resetFields();
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 8 },
        sm: { span: 12 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0,
        },
        sm: {
          span: 13,
          offset: 8,
        },
      },
    };
    const LAN = LANG[this.props.lan];
    return (
      <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef} name="agent-password-form">
        <Form.Item
          label={LAN.currentPasswordLabel}
          name="currentPassword"
          rules={[
            { required: true, message: LAN.currentPasswordRequiredMsg },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={LAN.newPasswordLabel}
          name="newPassword"
          rules={[
            { required: true, message: LAN.newPasswordRequiredMsg },
            { min: 6, message: LAN.newPasswordMinMsg },
            { pattern: /(\d+.*[a-zA-Z]+|[a-zA-Z]+.*\d+)/, message: LAN.passwordMatchMsg },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={LAN.checkPasswordLabel}
          name="rePassword"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: LAN.checkPasswordRequiredMsg },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(LAN.checkPasswordValidatorMsg);
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" icon={<UserOutlined />}>
            {LAN.submitBtn}
          </Button>
          &ensp;
          <Button type="default" htmlType="reset" icon={<ReloadOutlined />} onClick={this.handleReset}>
            {LAN.resetBtn}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
const mapStateToProps = (state) => ({
  agentname: state.agentname,
  lan: state.lan,
});

export default connect(mapStateToProps)(PasswordForm);
