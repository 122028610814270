import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, TreeSelect, Radio, Modal, Select } from 'antd';
import { UserOutlined, ReloadOutlined } from '@ant-design/icons';
import DatePicker from '../UI/DatePicker';
import axios from '../../helpers/adminAxios';
import showError from '../../helpers/errorlist';
import { addUser as LANG } from '../../helpers/lan';

const { Option } = Select;

class AddCFDAUserForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  handleSubmit = async (values) => {
    try {
      this.setState({ isSubmitting: true });
      values.email = values.email.toLowerCase();
      if (values.expiryDate) {
        values.expiryDate = values.expiryDate.endOf('day').format();
      }
      await axios.post('/admin-auth/cfda-users', values);
      const LAN = LANG[this.props.lan];
      Modal.success({
        content: LAN.successMsg,
        onOk: () => {
          this.props.history.push('/admin/userlist');
        },
      });
    } catch (error) {
      showError(error, this.props.lan);
      this.setState({ isSubmitting: false });
    }
  }

  handleReset = () => {
    this.formRef.current.resetFields();
  }

  render() {
    const { lan } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 8 },
        sm: { span: 12 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0,
        },
        sm: {
          span: 13,
          offset: 8,
        },
      },
    };
    const LAN = LANG[lan];
    const env = process.env.REACT_APP_ENV;
    let initialRegion = 'southchina';
    if (env === 'development') { initialRegion = 'dev'; }
    if (env === 'beta') { initialRegion = 'beta'; }
    return (
      <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef} name="add-user" initialValues={{ level: '1', region: initialRegion }}>
        <Form.Item
          label={LAN.username}
          name="username"
          rules={[
              { required: true, message: LAN.usernameRequiredMsg },
              { min: 4, message: LAN.usernameMinMsg },
              { pattern: /^[\w]+$/, message: LAN.usernameMatchMsg },
            ]}
          >
          <Input />
        </Form.Item>
        <Form.Item
          label={LAN.phone}
          name="phone"
          rules={[
              { pattern: /^[\d]{7,}$/, message: LAN.phoneMatchMsg },
            ]}
          >
          <Input />
        </Form.Item>
        <Form.Item
          label={LAN.email}
          name="email"
          rules={[
              { required: true, message: LAN.emailRequiredMsg },
              { type: 'email', message: LAN.emailMatchMsg },
            ]}
          >
          <Input />
        </Form.Item>
        <Form.Item
          label={LAN.organization}
          name="organization"
          rules={[
              { required: true, message: LAN.organizationRequiredMsg },
            ]}
          >
          <Input />
        </Form.Item>
        <Form.Item
          label={LAN.region}
          name="region"
          tooltip="华南地区包括广东、广西、福建、海南、云南、四川以及港澳地区"
          >
          <Select>
            <Option value="southchina" key="southchina" disabled={env === 'development' || env === 'beta'}>{LAN.southchina}</Option>
            <Option value="northchina" key="northchina" disabled={env === 'development' || env === 'beta'}>{LAN.northchina}</Option>
            <Option value="overseas" key="overseas" disabled={env === 'development' || env === 'beta'}>{LAN.overseas}</Option>
            <Option value="dev" key="dev" disabled={env !== 'development'}>{LAN.dev}</Option>
            <Option value="beta" key="beta" disabled={env !== 'beta'}>{LAN.beta}</Option>
          </Select>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" icon={<UserOutlined />} loading={this.state.isSubmitting}>
            {LAN.signUpBtn}
          </Button>
            &ensp;
          <Button type="default" htmlType="reset" icon={<ReloadOutlined />} onClick={this.handleReset}>
            {LAN.resetBtn}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default withRouter(connect(mapStateToProps)(AddCFDAUserForm));
