import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageHeader, Form, Button, Input, message } from 'antd';
import { MailOutlined, SendOutlined } from '@ant-design/icons';
import TagInput from './TagInput';
import { admin as LANG } from '../../helpers/lan';
import axios from '../../helpers/adminAxios';
import showError from '../../helpers/errorlist';

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 4 },
};


class MailBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSending: false,
    };
  }
  handleChange = (changedFields) => {
    if (changedFields[0] && changedFields[0].name[0] === 'emailList') {
      this.props.onChangeEmailList(changedFields[0].value);
    }
  }

  handleSubmit = async (values) => {
    try {
      this.setState({ isSending: true });
      const { content } = values;
      const line = content.replace(/\n/g, '<br/>').replace(/\s\s/g, '&ensp;');
      await axios.post('/admin-auth/emails', { ...values, content: line });
      message.success(LANG[this.props.lan].sendSuccessMsg);
      this.setState({ isSending: false });
    } catch (error) {
      showError(error, this.props.lan);
      this.setState({ isSending: false });
    }
  }

  render() {
    const fields = [{
      name: ['emailList'],
      value: this.props.emailList,
    }];
    const LAN = LANG[this.props.lan];
    return (
      <div>
        <PageHeader title={LAN.mailBoxTitle} avatar={{ icon: <MailOutlined /> }} />
        <Form {...layout} fields={fields} onFieldsChange={this.handleChange} onFinish={this.handleSubmit}>
          <Form.Item
            label={LAN.toLabel}
            name="emailList"
          >
            <TagInput />
          </Form.Item>
          <Form.Item
            label={LAN.subjectLabel}
            name="subject"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={LAN.contentLabel}
            name="content"
          >
            <Input.TextArea autoSize={{ minRows: 20 }} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SendOutlined />}
              loading={this.state.isSending}
            >
              {LAN.sendBtn}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

MailBox.propTypes = {
  emailList: PropTypes.array,
  onChangeEmailList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(MailBox);
