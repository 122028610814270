import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import styled from 'styled-components';
import publicationList from './publicationList';

const SPubDiv = styled.div`
  max-width: 70em;
`;

const SDiv = styled.div`
  max-width: 20em;
`;

const SPopover = styled(Popover)`
  margin-right: 1em;
  color: #3C658B;
  font-size: 0.8em;
`;

const PubContent = ({ content }) => <SDiv>{content}</SDiv>;

PubContent.propTypes = {
  content: PropTypes.string,
};

const Publications = () => (
  <SPubDiv>
    {publicationList.map(({ content, title, url }) => (
      <SPopover
        title={title}
        content={<PubContent content={content} />}
        placement="topRight"
        key={title}
      >
        <a href={url}>{title}</a>
      </SPopover>
    ))}

  </SPubDiv>
);

export default Publications;
