import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Drawer, Form, Input, Button, message, Select } from 'antd';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';

const { TextArea } = Input;

class EditSummaryDrawer extends React.Component {
  formRef = React.createRef();
  handleSubmit = async (values) => {
    const { lan, editRecord } = this.props;
    const LAN = LANG[lan];  
    try {
      await axios.patch('/auth/task-summarys', {
        number: editRecord.number,
        ...values,
      });
      message.success(LAN.submitSuccess);
      this.handleClose();
    } catch (error) {
      showError(error, lan);
    }
  }

  handleReset = () => {
    this.formRef.current.resetFields();
  }

  handleClose = () => {
    this.handleReset();
    this.props.onClose();
  }

  render() {
    const { visible, editRecord, lan } = this.props;
    const LAN = LANG[lan];
    return (
      <Drawer
        title="重新审核并生成报告"
        visible={visible}
        onClose={this.handleClose}
        width={500}
      >
        <Form
          layout="vertical"
          name="edit-summary"
          ref={this.formRef} 
          onFinish={this.handleSubmit}
        >
          <Form.Item label={LAN.number}>
            {editRecord.number}
          </Form.Item>
          <Form.Item label={LAN.patientName}>
            {editRecord.patientName}
          </Form.Item>
          <Form.Item
            label={LAN.imageview}
            name="imageview"
            rules={[{ required: true }]}
          >
            <TextArea rows={12} />
          </Form.Item>
          <Form.Item
            label={LAN.diagnosis}
            name="diagnosis"
            rules={[{ required: true }]}
          >
            <TextArea rows={12} />
          </Form.Item>
          <Form.Item
            label={LAN.viewer}
            name="viewer"
            rules={[{ required: true }]}
          >
            <Select
              options={[
              { value: 'hesen', label: '贺森' },
              { value: 'mayuanjiao', label: '马媛娇' },
            ]}
          />
          </Form.Item>
          <Form.Item
            label={LAN.verifier}
            name="verifier"
            rules={[{ required: true }]}
          >
            <Select
              options={[
              { value: 'qiuxiaoyan', label: '仇晓岩' },
            ]}
          />
          </Form.Item>
          <Form.Item>            
            <Button type="default" htmlType="reset" onClick={this.handleReset}>{LAN.reset}</Button>          
            &emsp;
            <Button type="primary" htmlType="submit">{LAN.submit}</Button> 
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

EditSummaryDrawer.propTypes = {
  visible: PropTypes.bool,  
  onClose: PropTypes.func.isRequired,
  editRecord: PropTypes.object,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
});

export default connect(mapStateToProps)(EditSummaryDrawer);

const LANG = {
  zh: {
    imageview: '检查所见',
    diagnosis: '影像诊断', 
    viewer: '阅片医师',
    verifier: '审核医师',  
    submit: '重新审核并生成报告',
    reset: '重置',
    save: '保存',
    submitSuccess: '总结提交成功',
    saveSuccess: '已保存',
    number: '任务编号',
    patientName: '病人姓名',
  },
  en: {
    imageview: 'Image View',
    diagnosis: 'Diagnosis', 
    viewer: 'Viewer',
    verifier: 'Verifier',  
    submit: 'Confirm and Submit',
    reset: 'Reset',
    save: 'Save',
    submitSuccess: 'Submit Successfully',
    saveSuccess: 'Save Successfully',
    number: 'Number',
    patientName: 'Patient Name',
  },
};
