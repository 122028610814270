import { Modal } from 'antd';

export const ERROR_LIST = {
  0: {
    zh: '未知错误',
    en: 'Unknown Error',
  },
  // script
  11: {
    zh: '参数错误(Developer)',
    en: 'Wrong Parameter (Developer)',
  },
  12: {
    zh: '压缩包内包含多个含有DICOM的子文件夹',
    en: 'Zip file contains multiple subfolders containing DICOM',
  },
  13: {
    zh: '压缩包内的影像序列数目不符合要求',
    en: 'Zip file contains incorrect number of image sequences',
  },
  14: {
    zh: 'DICOM文件丢失，导致序列不连续',
    en: 'DICOM files are missing and discontinuous',
  },
  15: {
    zh: '年龄超过范围(40-90岁)',
    en: 'Age exceeds range (40-90 years)',
  },
  16: {
    zh: 'DICOM序列名不符合要求',
    en: 'Problem in Protocol Name',
  },
  17: {
    zh: '图像分辨率不符合要求',
    en: 'Low image resolution',
  },
  18: {
    zh: 'DICOM文件不属于同一个人或同时期',
    en: 'DICOM files are not from the same person or same scanning time',
  },
  19: {
    zh: '年龄超出范围(20-90岁)',
    en: 'Age exceeds range (20-90 years)',
  },
  20: {
    zh: '必须包含T1W T2FLAIR 序列',
    en: 'T1W T2FLAIR are compulsorysequences',
  },
  134: {
    zh: '数据分析出错，请联系客服',
    en: 'Data analysis error. Please contact the customer service.',
  },
  255: {
    zh: '图像质量问题，请查看图像质量要求文档',
    en: 'Problem in image quality, please check "Image Quality Requirement" guideline',
  },
  // user
  10000: {
    zh: '身份验证失败或登录超时，请重新登录',
    en: 'Authentication failed or session expired! Please sign in again.',
  },
  10001: {
    zh: '用户名不存在或密码错误',
    en: 'The username and password that you provided are invalid!',
  },
  10002: {
    zh: '用户名已被注册',
    en: 'The username has been registered!',
  },
  10003: {
    zh: '电子邮箱已被注册',
    en: 'The email address has been registered!',
  },
  10004: {
    zh: '当前密码错误',
    en: 'Invalid password!',
  },
  10005: {
    zh: '用户尚未获得管理员授权',
    en: 'The user has not been certificated by the administrator',
  },
  10006: {
    zh: '不存在使用此邮箱的用户',
    en: 'This email address does not belong to any user',
  },
  10007: {
    zh: '用户不存在或链接已过期',
    en: 'The username does not exist or the url expired',
  },
  10008: {
    zh: '请先阅读并同意用户协议',
    en: 'Please read and agree the user agreement first!',
  },
  10009: {
    zh: '任务数超过限额',
    en: 'The amount of tasks has been over limit!',
  },
  10010: {
    zh: '连接超时，请手动刷新页面',
    en: 'Connection timeout. Please refresh this page manually',
  },
  10011: {
    zh: '管理员邮箱未正确设置',
    en: 'The email address of admin has not been set up correctly',
  },
  10012: {
    zh: '用户邮箱未正确设置',
    en: 'The email address of user has not been set up correctly',
  },
  10013: {
    zh: '机构名称已被注册',
    en: 'Organization name has been registered!',
  },
  10014: {
    zh: '用户已过期',
    en: 'Account expired',
  },

  // file
  20001: {
    zh: '文件授权失败',
    en: 'File authentication failed',
  },
  20002: {
    zh: '批处理文件格式不正确',
    en: 'The format of batch file is illegal',
  },
  20003: {
    zh: 'DICOM文件格式不正确',
    en: 'The format of DICOM file is illegal',
  },
  20004: {
    zh: 'T1W文件格式不正确',
    en: 'The format of T1W file is illegal',
  },
  20005: {
    zh: 'T2 FLAIR文件格式不正确',
    en: 'The format of T2 FLAIR file is illegal',
  },
  20006: {
    zh: 'DICOM文件解压失败',
    en: 'Failed to unzip DICOM file',
  },

  // Redis
  30001: {
    zh: 'Redis连接失败',
    en: 'Failed to connect redis server',
  },

  // Email
  40001: {
    zh: '电子邮件发送失败, 请联系客服处理',
    en: 'Failed to send the email. Please contact the customer service.',
  },

  // Task
  50001: {
    zh: '上次扫描任务编号不存在',
    en: 'Task Number of Baseline Scan does not exist',
  },
  50002: {
    zh: '文件下载失败',
    en: 'Failed to download files',
  },
  50003: {
    zh: '文件上传异常，请重试',
    en: 'Upload exception detected, please try again later',
  },
  50004: {
    zh: '结果文件不完整',
    en: 'Result files are incomplete',
  },
  50005: {
    zh: 'Obs异常',
    en: 'Obs exception',
  },

  // Server
  60001: {
    zh: '服务器磁盘容量不足，请联系管理员',
    en: 'The server disk is full. Please contact the administator',
  },

  // OSS
  80001: {
    zh: 'Oss异常',
    en: 'Oss exception',
  },
  80002: {
    zh: 'Oss上传失败',
    en: 'Oss upload failed',
  },
  80003: {
    zh: 'Oss下载失败',
    en: 'Oss download failed',
  },
  80004: {
    zh: 'Oss复制失败',
    en: 'Oss copy failed',
  },
  80005: {
    zh: 'Oss删除失败',
    en: 'Oss remove failed',
  },
  80006: {
    zh: 'Oss生成加密链接失败',
    en: 'Oss generate presigned url failed',
  },
};

const showError = (error, lan = 'zh') => {
  if (error.message === 'Authentication Error') return;
  const errorInfo = ERROR_LIST[error.message] || ERROR_LIST['0'];
  Modal.error({
    content: errorInfo[lan],
  });
};

export default showError;
